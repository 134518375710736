@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

.swot-jumbo {
    min-height: 70px !important;
    background-color: #eee !important;
    height: 160px;
    background: linear-gradient(170deg, #01e4f8 0%, #1d3ede 100%);
    color: #fff;
}

.tabs-app-bar {
    background-color: #eee !important;
    border-radius: 20px !important;
}

.tabs-items-swot {
    font-weight: 800 !important;
    font-size: 16px !important;
    font-family: "Roboto", sans-serif !important;
}

.what_is_swot {
    position: relative;
    top: 20px;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

.swot-name-container h4 {
    color: #000;
    font-family: "Roboto", sans-serif;
    position: relative;
    top: 20px;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.underline {
    margin-top: 15px;
    background: linear-gradient(170deg, #01e4f8 0%, #1d3ede 100%);
    height: 5px;
    width: 150px;
    border-radius: 20px;
}

.more-details-button {
    display: flex;
    justify-content: right;
}

.more-details-button .fa {
    align-self: flex-start;
    font-size: 38px;
    position: relative;
    cursor: pointer;
    font-weight: 900;
    top: -30px;
}

.more-details-button span {
    cursor: pointer;
    font-weight: 900;
    top: -22px;
    position: relative;
    margin-right: 15px;
}

.create-new-swort {
    display: flex;
    flex-wrap: nowrap;
}

.create-new-swort .learn-button {
    flex: 1;
    color: #000;
    font-family: "Roboto", sans-serif;
    position: relative;
    top: 10px;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.create-new-swort .fa {
    font-size: 38px;
    cursor: pointer;
    font-weight: 900;
    position: relative;
    top: 5px;
    margin-left: 10px;
}

.not-found-statement {
    display: flex;
    justify-content: center;
    align-items: center;
}

.not-found-statement .alert {
    min-height: 150px;
    max-height: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #000;
}

.not-found-statement .alert h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
}

.not-found-statement .alert i {
    font-size: 45px;
    font-weight: 900;
    margin-right: 20px;
}

.loader-items-sort {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 70px;
}

.loader-items-sort .spinner-grow-sm {
    width: 8rem !important;
    height: 8rem !important;
    color: green !important;
}

.back-btn-createNew {
    display: flex;
}

.back-arrow-btn .fa {
    color: #309897;
    font-weight: 900;
    font-size: 40px;
    cursor: pointer;
}

.title-container {
    flex: 1;
}

.title-name-tt {
    color: #309897;
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    text-transform: uppercase;
}

.list-card {
    display: flex;
    align-items: center;
}

.list-card-container {
    margin-top: 10px;
    margin-bottom: 20px;
    min-height: 70px;
    display: flex;
}

.list-number {
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;

    background: #309897;
    border-radius: 4px;
    color: #fff;
    padding: 5px 15px 5px 15px;
    margin-right: 15px;
}

.list-item-stength {
    font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
    font-size: 20px;
    margin-top: 6px;
    flex: 2
}

.swot-row-cont {
    padding-left: 20px;
    padding-right: 20px;
}


.edit-button-list .fa {
    font-size: 38px;
    color: #000;
    font-weight: 900;
}

.title-anaysis-tabs {
    color: #fff !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 900 !important;
}

.swot-info-container {
    background-color: #eee;
    padding: 15px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    border-radius: 3px;
    margin-top: 20px;
}

.swot-info-container p {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 300;

}

.action_items_container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.action_swot_item {
    padding-right: 40px !important;
}

.what-is-swot {
    position: relative;
    color: #1d3ede !important;
}

@media (max-width: 600px) {
    .swot-main-container {
        position: relative;
        top: 40px;
    }

    .more-details-button {
        justify-content: right;
        margin-top: 50px;
        position: relative;
    }

    .swot-name-container h4 {
        justify-content: right;
        margin-top: 50px;
        position: relative;
    }

    .underline {
        width: 50px;
    }

    .card-swot-display {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 25px;
    }

    .card-swot-display h1 {
        font-family: "Roboto", sans-serif;
        font-weight: 100;
    }

    .swot-row-cont {
        padding-bottom: 65px;
    }

    .action_items_container {
        justify-content: space-around !important;
    }

    .action_swot_item {
        padding-right: unset !important;
    }
}