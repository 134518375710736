.button_card_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
    background-color: #eee;
    box-shadow: -1px 0px 16px 0px rgba(0, 0, 0, 0.37);
    -webkit-box-shadow: -1px 0px 16px 0px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: -1px 0px 16px 0px rgba(0, 0, 0, 0.37);
    align-items: center;
    justify-content: center;
}

.image_list_item {
    box-shadow: -5px 1px 19px -2px rgba(148, 135, 135, 0.75);
    -webkit-box-shadow: -5px 1px 19px -2px rgba(148, 135, 135, 0.75);
    -moz-box-shadow: -5px 1px 19px -2px rgba(148, 135, 135, 0.75);
    object-fit: cover;
    padding: 8px;
    border-radius: 10px;
}


.input {
    display: none;
}