@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

.bmc-view-container {
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.blocks-row {
    margin-top: -40px;
    margin-bottom: 40px
}

.what-is-bmc {
    color: #000 !important;
    font-weight: 900 !important;
}

.tabs-items-bmc {
    font-weight: 800 !important;
    font-size: 16px !important;
    font-family: "Roboto", sans-serif !important;
}

.back-btn-bmc {
    position: absolute;
    margin-top: -50px;
    cursor: pointer;
    z-index: 1;
}

.back-btn-bmc i {
    font-size: 40px;
    margin-left: 15px;
    font-weight: 800;
}

.back-btn-bmc span {
    font-size: 20px;
    margin-left: 10px;
    top: -7px;
    position: relative;
    text-transform: capitalize;
    color: green;
}

.loader-bmc-list {
    left: 50%;
}

.block-title-container {
    flex: 1;
}

.block-title-container h3 {
    align-items: center;
    align-content: center;
    justify-content: center;
    font-family: "Alfa Slab One", cursive;
    margin-top: 10px;
    text-transform: uppercase;
    left: 50%;
    position: relative;
}

.bmc-ne-l-butn {
    z-index: 1;
    font-size: 20px;
    cursor: pointer;
}

.bmc-ne-l-butn i {
    font-size: 28px;
}

.bcm-title-name {
    margin-top: -40px;
}

.bcm-title-name span {
    font-size: 1.5rem;
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    color: green;
}

.bcm-description-t {
    margin-top: 12px;

}

.bcm-description-t span {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 300;
}

.bmc-crd-igo {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    min-height: 116px;
}

.bmc-crd-igo h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
}

.question-bmc {
    font-size: 50px !important;
    margin-top: 15px;
    margin-left: 50px;
    cursor: pointer;
}

.question-bmc-active {
    font-size: 50px !important;
    margin-top: 15px;
    margin-left: 50px;
    cursor: pointer;
    color: purple !important;
}

@media (min-width: 600px) {
    .blocks-row {
        margin-top: unset;
        margin-bottom: unset;
    }
}

@media (min-width: 801px) {
    .bmc-swot-tab-box {
        border-radius: 0px !important;
    }

}



@media (max-width: 600px) {
    .bmc-container {
        position: relative;
        top: 90px;
    }

    .bcm-title-name {
        margin-top: unset;
    }

    .blocks-row {
        margin-top: 90px;
    }

    .bmc-ne-l-butn {
        position: relative;
        top: 90px;
    }

    .bcm-title-name {
        position: relative;
        top: 5px;
    }

    .back-btn-bmc {
        margin-top: unset;
    }

    .bmc-add-btn {
        margin-top: -80px !important;
        position: absolute;
        right: 0px;
    }

    .block-title-container h3 {
        left: unset;
        text-align: center;
        font-size: 1.25rem;
    }

    .not-found-statement {
        margin-top: 50px;
        margin-bottom: 50px;
    }

}