.mobile_display {
    display: none;
}

.main_container {
    width: 100%;
    overflow-x: hidden;
}


.search_container {
    display: flex;
    margin-top: 50px;
    align-items: center;
}

.desktop_display {
    margin-top: 10px;
}

.card_container_sec {
    margin-top: 20px;
    margin-bottom: 20px;

}

.show_page_container {
    margin-top: 15px;
}

.details_card_container {
    width: 100%;
    min-width: 400px;
    background-color: #eee;
    min-height: 250px;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    border-radius: 3px;

}

.details_card_collaborators_container {
    width: 100%;
    min-width: 300px;
    background-color: #eee;
    min-height: 400px;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    border-radius: 3px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.details_card_body_collaborators {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 300;
    padding: 8px;
    max-height: 250px;
}

.details_card_header {
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.details_card_body {
    min-height: 200px;
    overflow-y: scroll;
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 300;
    padding: 8px;
    max-height: 250px;
}

.card_header_title {
    color: purple;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    margin-left: 5px;
    position: relative;
    top: 3px;
    text-transform: uppercase;
}

.show_page_serch_bar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.display_project_cards {
    margin-top: 10px;
    margin-bottom: 20px;
}

.network_info_container {
    background: #fff;
    width: 100%;
    min-height: 595px;
    height: 595px;
    max-inline-size: 80vh;
    margin-top: 20px;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    border-radius: 8px;
    max-width: 500px;
    min-width: 400px;
    top: 0;
    z-index: 0;
    padding: 13px;
}

.image_container_section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 5px;
}

.creator_name_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 10px;
}

.roles_container {
    text-align: center;
    margin-top: 5px;
}

.roles_container>p {
    font-family: 'Fjalla One', sans-serif;
    font-weight: 400;
    color: grey;

}

.profile_details_info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}

.sticky-sec {
    position: sticky;
    top: 0;
    z-index: 0;
    margin-bottom: 40px;
}

.creator_name_container>h5 {
    font-weight: 800;
}

.creator_name_container>h6 {
    color: grey;
    text-transform: uppercase;
    font-weight: 600;
}

.team_display {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 20px;
}

.title_search_bar_container {
    display: flex;
    flex-direction: row;
}

.search_bar_area {
    position: relative;
    top: 25px;
}

.sort_buttons_projects {
    position: relative;
    top: 35px;
}

.actual_project_container {
    position: relative;
    top: 50px;
}



/* === MOBILE AND RESPONSIVENESS === */

@media (max-width: 801px) {
    .mobile_display {
        display: inline;
    }

    .desktop_display {
        display: none;
    }

}