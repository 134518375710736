@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

.invitation_container {
    padding: 20px;
}

.header_text {
    font-weight: 800;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
}

.header_small_text {
    color: grey;
    font-family: "Roboto", sans-serif;
}

.form_container {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    left: 200px;
    width: 100%;
}

.main_container {
    overflow-x: hidden;
}

.use_invitation_container {}