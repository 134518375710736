.suggestion_container {
    display: flex;
    height: 100vh;

    min-height: 100%;
}



.suggestion_categories {
    width: 15%;
    position: sticky;
    top: 0;
    z-index: 0;
    margin-bottom: 40px;

}

.suggestions_data {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 5px;

}