.library-card {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.library-card-header {
  background-color: #efe8e8;
  display: flex;
  justify-content: space-between;
  color: #309897;
  width: 100%;
  height: 50px;
  border: 1px solid rgb(201, 194, 194);
  border-radius: 8px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

.library-card-header i {
  color: #4b77e7;
}

.library-card-header button {
  color: #309897;
  border: unset;
  background: unset;
  border-radius: 50%;
}

.library-card-header button i {
  color: #309897;
}

.library-card-body {
  position: relative;
  bottom: 5px;
  width: 100%;
}

.library-card-body-details {
  width: 90%;
  margin-top: 10px;
  margin-left: 50px;
  padding-bottom: 10px;
}

.library-card-body-details h6,
.library-card-body-details h5 {
  color: #309897;
}

.library-card-body-info {
  width: 100%;
  height: 180px;
  background-color: #f3eeee;
  border-radius: 6px;
}

.library-card-body-info-top {
  width: 100%;
  height: 75%;
  padding: 6px;
  overflow-y: scroll;
}

.library-card-body-info-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  border-top: 1px solid #c2bdbd;
  width: 100%;
  height: 25%;
}

.library-card-body-info-bottom button,
.library-item-status button {
  border: 1px solid #309897;
  color: #309897;
  background: unset;
  margin-right: 6px;
  border-radius: 6px;
}

.library-card-body-info-bottom button:hover,
.library-item-status button:hover {
  background: #309897;
  color: #fff;
}

.library-item-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.library-modal-content {
  margin: 10% auto;
  border-radius: 6px;
  width: 80%;
}

.library-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  color: #fff;
  width: 100;
  height: 40px;
  padding: 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #309897;
}

.library-modal-header button {
  color: rgb(249, 249, 249);
  font-size: 18px;
  border: unset;
  background: unset;
}

.library-modal-header button:hover,
.library-modal-header button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.library-modal-body {
  width: 100%;
  height: 600px;
  padding: 8px;
  background-color: #fefefe;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow-y: scroll;
}

.library-item {
  width: 100%;
  margin-top: 10px;
}

.library-item-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  height: 50px;
  border: 1px solid rgb(212, 208, 208);
  border-radius: 6px;
  padding: 6px;
}

.library-item-header-title {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

.upload-img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.library-item-header button {
  border: unset;
  background: unset;
  color: #309897;
}

.library-item-body {
  position: relative;
  bottom: 3px;
  width: 100%;
  border: 1px solid rgb(212, 208, 208);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.library-item-body-details {
  width: 90%;
  margin-top: 10px;
  margin-left: 50px;
  padding-bottom: 10px;
}

.library-item-body-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 180px;
  padding: 6px;
  background-color: #f3eeee;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.library-item-body-info-img {
  width: 25%;
  border-right: 1px solid #d0d4d4;
  object-fit: contain;
}

.library-item-body-info-details {
  width: 75%;
  padding: 6px;
  overflow-y: scroll;
}

.library-item-status {
  margin-top: 10px;
  color: #309897;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}
