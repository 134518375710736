.tag-header {
  padding: 16px;
  color: #047C7C;;
}

.tag-container {
  padding: 16px;
}

.tag-btn {
  background-color: #047C7C;
  color: #ffffff;
}

.tag-btn:hover {
  color: #047C7C;
  border: 1px solid #047C7C;
  background-color: #ffffff;
}

.tags-nil {
  margin-top: 100px;
  color: #c8bbbb;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}