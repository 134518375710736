@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap);
/* New auth features*/

/* ==to be removed == */
/* === React Notifications === */

*:focus {
  outline: none;
}

.nav-bar-color-fix .navbar-toggler {
  background-color: #000;
}


.top-ten-cont {
  margin-top: 94px;
}

.nav-bar-color-fix a {
  padding: 10px;
  color: #2e2e2e !important;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.top-ten-image img {
  width: 100%;
  height: 400px;
  object-fit: contain;
  margin-top: -30px;
}

.top-ten-image .btn-view-tt {
  margin-top: -20px;
}

/* ==to be removed == */

.e-margin {
  margin-top: 250px;
}

.text-orange {
  color: #ff9b00;
}

.logo-one {
  margin: 16px;
  height: 58px;
  width: 120px;
}

textarea {
  min-height: 160px !important;
  font-style: normal !important;
}

.auth-col {
  background-color: #ff9b00;
}

.auth-col,
.auth-col-II {
  font-family: "Ubuntu", sans-serif;
}

.auth-col-height {
  height: 100vh;
}

.auth-btn-one {

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  background-color: #ff9b00;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: unset;
  color: #ffffff;
}

.app-auth-form-error {
  color: red;
  font-weight: bold;
  font-family: 'Nunito Sans';
  position: absolute;
  font-size: 13px;
}

.register-screen {
  width: 100%;
  background-repeat: no-repeat;
  background-image: url(/static/media/together.d777d5b4.jpg);
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.tuc-logo-signup {
  background: #D99334;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 0px;
  left: 0;
  border-radius: 35px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.register-screen h1 {
  text-align: center;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;

  color: #FFFFFF;
}

.account-quiz {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #828282;
}

.account-signin-register a {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  -webkit-text-decoration-line: underline;

          text-decoration-line: underline;

  color: #FF9B00;
}

.app-label {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #828282;
}

.sign-in-register {
  margin-bottom: 20px;
}

.register-content-form h1 {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  text-align: center;

  color: #525252;
}


.sign-in-register {
  text-align: center;
}

/* New auth End */

.fc .fc-toolbar-title {
  font-weight: 900 !important;
  color: purple !important;
  text-transform: uppercase !important;
  font-size: 1.4em !important;
}

.edit_button_class {
  position: relative;
  text-align: right;
}

.loading-sp {
  color: #ff9b00;
}

.no-deco {
  text-decoration: none;
}

/* === New css by peter === */

.sidenav {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #000;
  overflow-x: hidden;
  padding-top: 60px;
  min-width: 230px;
  max-width: inherit;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 16px;
  color: #ddd;
  display: block;
  line-height: 28px;
  font-family: "Ubuntu", sans-serif;
  transition: 0.3s;
}

.transform-div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  width: 100%;
  background: linear-gradient(to right bottom, #ff9b00 48%, #000000f2 49%);
}

.categories-link-nav {
  font-size: 18px;
  position: relative;
  top: -10px;
  left: -50px;
}

#profile-image-nav {
  position: relative !important;
  left: unset !important;
  top: unset !important;
  bottom: 30px;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  border: solid;
  text-transform: capitalize;
}

.main {
  padding-left: unset !important;
  padding-right: unset !important;
}

.nav-div {
  padding-right: unset !important;
  padding-left: unset !important;
}

#dropdown-icon {
  position: relative;
  color: #fff;
  top: 8px;
  left: 6px;
}

.view {
  color: #6a6a6a;
  cursor: pointer;
  margin-top: 20px;
}

#user-name-navbar {
  display: contents;
  flex-wrap: nowrap;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
}

.view:before,
.view:after {
  content: " ";
  width: 64px;
  height: 1px;
  margin: 0 10px;
  vertical-align: super;
  background-color: #80808087;
  display: inline-block;
}

.v-btn {
  background-color: #ff9b00;
  font-size: 12px;
  align-items: center;
  font-family: "Ubuntu", sans-serif;
  font-weight: 600;
  border-radius: 30px;
  height: 25px;
  padding: 8px;
  color: #1b191991;
}

.v-btn:hover {
  text-decoration: none;
  color: #151515;
}

/* ==TOP TEN LIST == */

.top-ten-list {
  overflow: hidden;
}

.top-ten-list-body {
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.top-ten-title h4 {
  font-size: 2rem;
  font-family: "Fjalla One", sans-serif;
  padding: 50px;
}

.top-card {
  width: 420px;
  margin: 6px;
  padding: 20px;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
  background: #ffffff;
  color: #000000;
  box-shadow: 0px 4px 11px 3px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.filter {
  width: 100%;
}

.card-entry-container .card-entry {
  text-align: left;
  line-height: 2;
  white-space: nowrap;
  overflow-x: scroll;
  font-size: 1.12rem;
  font-family: "Roboto", sans-serif;
  padding-top: 5px;
  padding-left: 25px;
  color: #000000;
}

.card-entry::-webkit-scrollbar {
  display: none;
}

/* ==TOP TEN LIST == */

/* === End css by peter === */

/* ==== Css by El ==== */

/*Connecting Project*/

.project-link-div {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
}

.project-link-div span {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
}

.project-link-div button {
  height: 30px;
  padding: 0 8px 0 8px !important;
  border: unset;
  background: #309897;
  color: #fff;
  font-family: "Roboto", sans-serif;
  border-radius: 6px;
}

/*Connecting Project End*/

.add-btn {
  background-color: #047C7C;
  color: rgb(48, 46, 46);
  text-align: center;
  height: 45px;
  width: 120px;
  padding-top: 2px;
  margin-bottom: 9px;
  padding-left: 9px;
  font-family: "Ubuntu", sans-serif;
  border: none;
}

.add-btn-plus {
  display: flex;
  font-size: 40px;
  justify-content: center;
  position: relative;
  top: -13px;

}

.space-line {
  width: 95%;
  margin-top: 60px;
}

.entry-container {
  width: 95%;
}

.entry-list-cont-header {
  background-color: #ddf5fe;
  color: rgb(48, 46, 46);
  width: 100%;
  height: 15%;
}

.all-entries-container {
  background-color: #f3fbfe;
  width: 100%;
  height: 85%;
  overflow-y: scroll;
  overflow-x: scroll;
}

.add-entry-btn {
  background-color: #2bbcf345;
  color: rgb(48, 46, 46);
  text-align: center;
  height: 39px;
  width: 130px;
  padding-top: 5px;
  margin-bottom: 9px;
  padding-left: 9px;
  font-family: "Ubuntu", sans-serif;
  border: none;
}

.num {
  background-color: #2bbcf345;
  padding: 10px;
}

/* ==side nav bar logout btn == */

.btn-logout {
  font-size: 16px;
  margin-left: 26px;
  color: #ddd;
  display: block;
  line-height: 28px;
  font-family: "Ubuntu", sans-serif;
  background: unset;
  border: unset;
  margin-top: 8px;
}

#close-btn {
  background: unset;
  position: relative;
  top: -50px;
  left: 190px;
  font-size: 25px;
  border: unset;
  color: #fff;
}

#open-btn {
  position: absolute;
  top: -6px;
  font-weight: bolder;
  left: -32px;
  background: unset;
  border: unset;
  font-size: 25px;
}

#logout-btn {
  position: absolute;
  right: -30px;
  background: unset;
  border: unset;
  font-size: 25px;
  top: -5px;
  display: none;
}

/* === Voting === */

.voting-container {
  position: absolute;
  border: unset;
}

.voting-btn {
  text-align: right;
  right: 5px;
  position: absolute;
  display: flex;
  border: unset;
  border-radius: 20px;
  background: unset;
  font-size: 18px;
  color: #6d6969;
}

.voting-hrt-white {
  color: #fff;
}

.voting-hrt-red {
  color: rgb(221, 43, 43);
}

/* === End css by El === */

@media (max-width: 768px) {
  .home-wallpaper {
    height: 350px;
    width: 400px;
  }

  .top-ten-card {
    margin-left: auto;
    margin-right: auto;
  }

  .auth-col-height {
    margin-bottom: 150px;
  }
}

@media (max-width: 1400px) {
  .sidenav {
    display: none;
  }

  #open-btn {
    display: inline-block;
  }

  #logout-btn {
    display: inline-block;
  }
}

/* == Peter css For card Entries === */

.entry-container button,
input {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

.entry-container a {
  color: #f96332;
}

.entry-container a:hover,
a:focus {
  color: #f96332;
}

.entry-container p {
  line-height: 1.61em;
  font-weight: 500;
  font-size: 1.35em;
  color: #000;
}

.entry-container .category {
  text-transform: capitalize;
  font-weight: 700;
  color: #9a9a9a;
}

.entry-container {
  color: #2c2c2c;
  font-size: 14px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-y: hidden;
}

.entry-container .nav-item .nav-link,
.nav-tabs .nav-link {
  transition: all 300ms ease 0s;
  cursor: pointer;
}

.entry-container .card a {
  transition: all 150ms ease 0s;
}

[data-toggle="collapse"][data-parent="#accordion"] i {
  transition: -webkit-transform 150ms ease 0s;
  transition: transform 150ms ease 0s;
  transition: transform 150ms ease 0s, -webkit-transform 150ms ease 0s;
}

[data-toggle="collapse"][data-parent="#accordion"][aria-expanded="true"] i {
  filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.now-ui-icons {
  display: inline-block;
  font: normal normal normal 14px/1 "Nucleo Outline";
  font-size: inherit;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@-webkit-keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.now-ui-icons.objects_umbrella-13:before {
  content: "\ea5f";
}

.now-ui-icons.shopping_cart-simple:before {
  content: "\ea1d";
}

.now-ui-icons.shopping_shop:before {
  content: "\ea50";
}

.now-ui-icons.ui-2_settings-90:before {
  content: "\ea4b";
}

.entry-container .nav-tabs {
  border: 0;
  padding: 15px 0.7rem;
}

.entry-container .nav-tabs:not(.nav-tabs-neutral)>.nav-item>.nav-link.active {
  box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
}

.entry-container .card .nav-tabs {
  border-top-right-radius: 0.1875rem;
  border-top-left-radius: 0.1875rem;
}

.entry-container .nav-tabs>.nav-item>.nav-link {
  color: #888888;
  margin: 0;
  margin-right: 5px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 30px;
  font-size: 14px;
  padding: 11px 23px;
  line-height: 1.5;
}

.entry-container .nav-tabs>.nav-item>.nav-link:hover {
  background-color: transparent;
}

.entry-container .nav-tabs>.nav-item>.nav-link.active {
  background-color: #ff9b00;
  border-radius: 30px;
  color: #212529;
}

.entry-container .nav-tabs>.nav-item>.nav-link i.now-ui-icons {
  font-size: 14px;
  position: relative;
  top: 1px;
  margin-right: 3px;
}

.entry-container .nav-tabs.nav-tabs-neutral>.nav-item>.nav-link {
  color: #ffffff;
}

.entry-container .nav-tabs.nav-tabs-neutral>.nav-item>.nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}

.entry-container .card {
  min-height: 250px;
  border: 0;
  border-radius: 0.1875rem;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

.entry-container .card .card-header {
  background-color: #212529;
  border-bottom: 0;
  border-radius: 0;
  padding: 0;
}

.entry-container .card[data-background-color="yellow"] {
  background-color: #ff9b00;
}

.card[data-background-color="blue"] {
  background-color: #2ca8ff;
}

.card[data-background-color="green"] {
  background-color: #15b60d;
}

[data-background-color="orange"] {
  background-color: #e95e38;
}

[data-background-color="black"] {
  background-color: #2c2c2c;
}

[data-background-color]:not([data-background-color="gray"]) {
  color: #ffffff;
}

[data-background-color]:not([data-background-color="gray"]) p {
  color: #ffffff;
}

[data-background-color]:not([data-background-color="gray"]) a:not(.btn):not(.dropdown-item) {
  color: #ffffff;
}

[data-background-color]:not([data-background-color="gray"]) .nav-tabs>.nav-item>.nav-link i.now-ui-icons {
  color: #ffffff;
}

@font-face {
  font-family: "Nucleo Outline";
  src: url("https://github.com/creativetimofficial/now-ui-kit/blob/master/assets/fonts/nucleo-outline.eot");
  src: url("https://github.com/creativetimofficial/now-ui-kit/blob/master/assets/fonts/nucleo-outline.eot") format("embedded-opentype");
  src: url("https://raw.githack.com/creativetimofficial/now-ui-kit/master/assets/fonts/nucleo-outline.woff2");
  font-weight: normal;
  font-style: normal;
}

.now-ui-icons {
  display: inline-block;
  font: normal normal normal 14px/1 "Nucleo Outline";
  font-size: inherit;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 768px) {
  .entry-container .nav-tabs {
    display: inline-block;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    text-align: center;
  }

  .entry-container .nav-tabs .nav-item>.nav-link {
    margin-bottom: 5px;
  }
}

/* ===peter css fixing loging notification === */

.notification_log {
  font-weight: 600;
  font-family: "Fjalla One", sans-serif;
  margin-top: 100px;
}

.temp_sign_in a {
  margin-right: 30px;
}

/* ====peter css fixing end */

/* == Peter css for card Entries End === */

/* == Carlos css changes for fixing top navbar and bottom navbar == */

/* Bottom NavBAr */

.bottomnav {
  display: none;
}

/* fix navbar */

#mainCol {
  flex-basis: 100%;
  max-width: 100%;
  height: 100vh;
}

/*bottom navbar buttons*/

#btm-open-btn {
  display: none;
}

#btm-logout-btn {
  display: none;
}

/* TOP NAVABAR */

#mainCol {
  flex-basis: 100%;
  max-width: 100%;
  height: 100vh;
}

#container_cat {
  height: 100vh;
}

#btm-dash-btn {
  display: none;
}

@media (max-width: 1400px) {
  .categories-body {
    padding-bottom: 90px;
  }

  .top-ten-list {
    padding-bottom: 90px;
  }

  .entry-container {
    padding-bottom: 90px;
  }

  #btm-dash-btn {
    display: none;
    position: absolute;
    top: -6px;
    font-weight: bolder;
    left: -32px;
    background: unset;
    border: unset;
    font-size: 25px;
  }

  #btm-logout-btn {
    display: inline-block;
    position: absolute;
    top: -6px;
    font-weight: bolder;
    right: -7px;
    background: unset;
    border: unset;
    font-size: 25px;
  }

  .bottomnav-logo {
    position: absolute;
    margin-top: 35px;
    margin-left: 50px;
    font-size: 23px;
    color: #121010;
    font-weight: 400;
    font-family: "Ubuntu", sans-serif;
    text-transform: uppercase;
    text-align: center;
  }

  .home-wallpaper {
    height: 350px;
    width: 400px;
  }

  .top-ten-card {
    margin-left: auto;
    margin-right: auto;
  }

  #open-btn {
    display: none;
  }

  #logout-btn {
    display: none;
  }

  .back-orange {
    background-color: #ff9b00 !important;
  }
}

/* ==== CSS PETER ==== */

/* ==== entry tables peter */

.numberline {
  background-color: #2f2c2c;
  border-radius: 20px;
  width: 30px;
  color: #fff;
  padding-right: 8px;
  margin-right: 6px;
  padding-left: 10px;
  margin-left: -15px;
}

.displayVotes {
  min-width: 70px;
}

.voting_class {
  /* position: relative;
 text-align: right;
 font-family: 'Ubuntu';
    font-weight: 200;
    color: #4d4f52;
    font-size: 15px;
    margin-bottom: -30px; */
  width: 33.333%;
  text-align: right;
}

.table-card {
  overflow-x: scroll;
  overflow-y: scroll;
}

.card-header th {
  background-color: #fff !important;
}

.styled-table {
  min-height: 500px;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #dee2e6;
  min-width: 1450px;
  overflow-x: scroll;
  overflow-y: scroll;
}

th,
td {
  border: 1px solid #dee2e6;
}

.styled-table thead th {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  font-size: 14px;
  color: #8898aa;
  font-family: "Ubuntu", sans-serif;
  background-color: #f6f9fc;
  vertical-align: middle;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-bottom: 1px solid #e9ecef;
}

.styled-table td {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  font-family: "Open Sans Condensed", sans-serif;
  letter-spacing: 0.25px;
}

.list tr {
  min-height: 200px;
  height: 110px;
  overflow-y: scroll;
  font-family: "Ubuntu", sans-serif;
  background-color: #38adbc0a;
}

.avatar img {
  z-index: 2;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  height: 39px;
  border: 2px solid #afaeae;
}

.rounded-circle {
  height: 57px;
  width: 54px;
}

.rounded-circle {
  border-radius: 50% !important;
}

.avatar-group .avatar {
  position: relative;
  z-index: 2;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 50%;
  background-color: #adb5bd;
  align-items: center;
  justify-content: center;
  float: left;
}

.edit-btn {
  display: flex;
  border: unset;
  position: relative;
  border-radius: 20px;
  z-index: 2;
  text-align: right;
  background: unset;
  color: #6d6969;
  font-size: 18px;
  width: 100%;
}

.edit_button_class {
  width: 33.333%;
  text-align: center;
  /* position: relative;
  top: 30px;
  right: 80px;
  text-align: right; */
  display: flex;
}

.modal-title {
  text-transform: uppercase !important;
  font-family: "Open Sans Condensed", sans-serif !important;
  font-weight: bolder !important;
}

.blk-btn {
  display: flex;
  border-radius: 20px;
  background: unset;
  font-size: 18px;
  color: #6d6969;
  z-index: 2;
  border-radius: 20px;
  border: unset;
}

.remixed_item {
  position: relative;
  color: #ff9b00;
  top: -70px;
  right: 25px;
  text-align: end;
}

.remixed_item i {
  font-size: 20px;
}

.jumbotron {
  text-align: center;
  width: 100%;
  font-size: 15px !important;
  color: #000;
  font-family: "Alfa Slab One", cursive;
  text-transform: uppercase;
  font-weight: 400
}

.display-4 {
  font-size: 1.5rem !important;
}

.categoryDisplay {
  font-weight: 400;
  top: -58px;
  text-transform: uppercase;
  letter-spacing: 0px;
  position: relative;
  font-size: 18px;
  color: #000;
  font-family: "Montserrat";
  color: #fff;
}

.top10NameDisplay {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  position: relative;
  top: 14px;
  color: #2E2E2E;
  text-transform: uppercase;
}

.topPurposeDisplay {
  margin-top: -25px;
  font-family: "Montserrat";
  font-size: 25px;
}

.tt-titleDisp {
  font-family: "Alfa Slab One", cursive;
}

.tt-class-disp {
  font-size: 25px;
  letter-spacing: 0px;
  font-weight: 700;
  padding-left: 10px;
}

.tt-statement-disp {
  text-transform: capitalize;
  font-size: 20px;
}

.tt-cat-disp {
  margin-right: 25px;
}

.tt-creator-signature {
  text-align: left;
  position: relative;
  top: 60px;
}

.tt-sig-display {
  font-size: 20px;
}

.creator-name {
  font-family: "Allura", cursive;
  text-transform: lowercase;
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 2px;
  position: relative;
  top: -7px;
}

@media (max-width: 750px) {
  .jumbotron {
    font-size: 20px;
  }

  .cont-jumbotron {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .tt-btn-1 {
    position: absolute !important;
    top: 115px;
    left: 5px;
  }

  .tt-btn-2 {
    position: absolute !important;
    top: 115px;
    right: 5px;
  }

  .categoryDisplay {
    top: 0px;
    padding-bottom: 60px;
  }

  .tt-btn-1-tc {
    position: absolute !important;
    top: 74px;
    left: -35px;
  }

  .tc-jumbo {
    margin-top: 60px;
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .top-ten-list .d-flex {
    display: unset !important;
  }

  .top-ten-list-body {
    margin-top: -80px;
  }
}

@media (max-width: 450px) {
  .tt-creator-signature {
    margin-top: 25px;
    text-align: center;
    position: unset;
    top: unset;
  }
}

/* === COMMENTS STYLING MOVE TO ITS ON FILE WHEN COMPLETE ===== */

.comment-btn {
  display: flex;
  border: unset;
  position: relative;
  border-radius: 20px;
  color: #6d6969;
  z-index: 2;
  text-align: right;
  background: unset;
  font-size: 18px;
}

.comment_button_class {
  width: 33.3333%;
  /* position: relative;
  border: unset;
  height: 30px;
  width: 30px;
  z-index: 2;
  top: 60px;
  border-radius: 20px; */
}

.Comments_filter button {
  text-transform: capitalize;
  font-size: 17px;
  font-family: "Roboto", sans-serif !important;
}

.comment_input_field textarea {
  position: relative;
  width: 100%;
  min-height: 125px;
  border-radius: 5px;
  border: 1px solid #000;
  font-family: "Roboto", sans-serif !important;
}

.comment_input_field input {
  width: 100%;
  height: 45px;
  outline-color: #C4C4C4 !important;
  box-shadow: 0px 4px 5px -2px rgb(0 0 0 / 15%);
  border-radius: 6px;
  color: #000;
}

.submit-tt-comment {
  background: #FF9B00 !important;
  color: #fff !important;
}

.comment_user_profile {
  background-color: rgb(232, 224, 224);
  border-radius: 20px;
  height: 30px;
  width: 30px;
}

.comment_display_elemets {
  padding-top: 25px;
  padding-bottom: 25px;
}

.comment_user_profile {
  background-color: rgb(232, 224, 224);
  border-radius: 25px;
  height: 50px;
  width: 50px;
  text-align: center;
}

.comment_user_profile span {
  position: relative;
  top: 5px;
  font-size: 25px;
  font-weight: bolder;
  text-transform: uppercase;
}

.comment_display_elemets .comment-user-name {
  text-transform: uppercase !important;
  font-weight: bolder !important;
  padding-bottom: 10px;
}

.comment-user-statement {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
}

.comments-actions ul {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
}

.comments-actions ul li {
  font-family: "Roboto", sans-serif;
  padding-left: 10px;
  font-size: 16px;
  padding-right: 10px;
  text-transform: capitalize;
  color: #737171;
}

.comment_input_field input {
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 10px;
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 8px;
}

.comment-reply {
  padding-left: 65px !important;
  border: 1px solid #000;
}

.comment-reply .comment_user_profile {
  height: 33px !important;
  width: 33px !important;
}

.comment-reply .comment_user_profile span {
  font-size: 16px;
}

.comment-reply .comment-user-name {
  font-size: 12px !important;
  text-transform: uppercase !important;
}

.comment-reply .comment-user-statement {
  font-family: "PT Sans Narrow", sans-serif !important;
  color: #000 !important;
  font-size: 17px;
}

.comment-reply .comments-actions li {
  font-family: "PT Sans Narrow", sans-serif !important;
}

.comment-reply-form {
  width: 100%;
}

.reply-input-field {
  width: 100%;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 7px;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #c4c4c4;
  font-weight: 400;
  padding-left: 20px;
  padding-top: 10px;
  font-family: "Roboto", sans-serif;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c4c4c4;
  font-weight: 400;
  padding-left: 20px;
  padding-top: 10px;
  font-family: "Roboto", sans-serif;
}

::placeholder {
  color: #c4c4c4;
  font-weight: 400;
  padding-left: 20px;
  padding-top: 10px;
  font-family: "Roboto", sans-serif;
}

.reply-submit-btn {
  width: 20%;
  border-radius: 5px;
  border: 2px solid #000;
  outline-color: #000;
  font-family: "PT Sans Narrow", sans-serif !important;
}

.comment-reply-button {
  cursor: pointer;
}

.idea-container {
  padding-top: 10px !important;
  padding-bottom: 5px !important;
}

.purpose-container {
  padding-top: 10px !important;
  padding-bottom: 15px !important;
}

.tt-dis-entries .numberLine {
  font-family: "Montserrat";
  font-weight: bolder;
  padding-bottom: 5px;
  border-radius: 15px;
}

.tt-dis-entries .card {
  min-height: unset !important;
  border-bottom: solid 2px #ff9b00;
  border-radius: 2px;
}

.tt-dis-entries .card-header {
  background-color: unset !important;
  padding: 10px !important;
}

.tt-dis-entries .card-header .btn-link {
  color: #000 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  text-transform: inherit;
}

.tt-dis-entries ul {
  list-style-type: none;
}

.tt-dis-entries .last_entries {
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  bottom: 0px;
  left: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.first-element {
  font-family: "Ubuntu", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.name_comment {
  font-size: 12px;
  padding-right: 5px;
  font-family: "Fjalla One", sans-serif;
}

.tt-dis-entries .badge {
  text-transform: uppercase;
  background: #309897;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  color: #FFFFFF;
}

.tt-entries-container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.tt-entries-container li {
  padding-top: 5px;
  padding-bottom: 20px;
}

.tt-entries-container .avatar-group {
  position: absolute;
  top: 13px;
  right: 10px;
}

.tt-more-users {
  height: 40px;
  width: 40px;
  border: 1px dotted black;
  border-radius: 30px;
  font-size: 15px;
  padding: 10px;
  position: relative;
  top: 10px;
  color: #000;
  z-index: 10;
  cursor: pointer;
}

.info-display {
  font-size: 19px !important;
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: 600;
}

.display-comments-division {
  padding-bottom: 14px;
}

.info-class ul {
  display: flex;
  flex-wrap: nowrap;
}

.info-class ul li {
  padding: 5px;
  font-family: "Ubuntu", sans-serif;
  flex: 1 1;
}

.vt-rx-bg {
  font-size: 15px;
  padding: 4px;
  border-radius: 20px;
  color: #fff;
}

.vt-names {
  padding-left: 5px;
}

.disp-numberline {
  position: relative;
  left: -18px;
}

.tt-dis-entries .btn-link:hover {
  text-decoration: unset !important;
}

.tt-dis-entries .btn-link:active {
  outline: none !important;
}

.tt-dis-entries .btn-link:focus {
  outline: none !important;
  box-shadow: unset !important;
}

.tt-dis-entries .card-body {
  min-height: 150px;
  width: 100%;
  border-top: 2px solid #eee;
}

.tt-footer-section {
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background: #eee;
}


.info-class ul {
  margin-top: 15px;

}

.tt-vote-remix {
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-wrap: nowrap;
}

.tt-vote-remix .name_comment {
  text-transform: capitalize;
}

.disp-engaged-users {
  position: absolute;
  right: 12px;
  margin-top: -40px;
  display: flex;
  flex-wrap: nowrap;
}

.other-elements-container {
  position: relative;
  top: 40px;
  margin-bottom: 50px;
}

.elements-contaimer {
  width: 100%;
  min-height: 100px;
  background-color: #eee;
  padding-top: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.view-details {
  min-height: 80px;
  height: 100%;
  border-left: solid 2px #ffc107;
  max-height: 100%;
  display: grid;
}

.view-details .badge {
  font-size: 20px;
  position: relative;
  top: 22px;
  border-radius: 40px;
  padding: 8px;
  height: 35px;
  width: 35px;
  text-align: center;
}

.el-title {
  margin-left: 50px;
  font-size: 16px;
  position: relative;
  top: -8px;
  font-weight: 800;
  font-family: "Ubuntu", sans-serif;
}

.el-details {
  font-size: 18px;
  position: relative;
  left: unset;
  margin-left: 50px;
  margin-top: 0px;
  margin-bottom: 20px;
  color: #695f5f;
  font-weight: 500;
}

.entry-created-by {
  margin-top: 65px;
  color: #000 !important;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.created_by_footer {
  margin-top: 22px;
  position: relative;
  margin-bottom: -15px;
  text-transform: capitalize;
  color: #595959;
}

.accordion {
  margin-bottom: -30px;
}

.disabled-class-tt {
  display: none !important;
}

/* <=============== Reusable componets css ============> */

/* ==== Sorting Component ==== */

.reusable-sorting-component {
  display: flex;
  flex-wrap: nowrap;
}

.reusable-sorting-component .reusable-sorting-view {
  flex: 1 1;
}

.reusable-sorting-component .reusable-sorting-view i {
  color: #047c7c;
}

.reusable-sorting-component .reusable-sorting-view span {
  color: #2e2e2e;
  opacity: 0.75;
  font-family: "Roboto", sans-serif;
}

.reusable-sorting-component .reusable-sorting-show {
  flex: 1 1;
  display: flex;
  flex-wrap: nowrap;
  text-align: left;
}

.reusable-sorting-component .reusable-sorting-sort-by {
  flex: 1 1;
  text-align: right;
}

.reusable-sorting-show span {
  color: #2e2e2e;
  opacity: 0.75;
  font-family: "Roboto", sans-serif;
  padding-left: 2px;
}

.reusable-sorting-show .dropdown-toggle {
  font-size: 14px;
  line-height: 25px;
  color: #047c7c;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.reusable-sorting-sort-by span {
  color: #2e2e2e;
  opacity: 0.75;
  font-family: "Roboto", sans-serif;
  padding-left: 2px;
}

.reusable-sorting-sort-by .dropdown-toggle {
  font-size: 14px;
  line-height: 25px;
  color: #047c7c;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.reusable-pagination nav .page-item {
  font-size: 15px;
  padding-left: 4px;
}

.reusable-pagination nav .page-link {
  border-radius: 3px;
  font-family: "Roboto", sans-serif;
  color: #000;
}

/* === Top10 Design === */

.category-top-10 span {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 18px;
  font-family: "Roboto", sans-serif;
  color: #309897;
  padding: 2px;
}

.category-top-10 .header-name {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  font-family: "Roboto", sans-serif;
  color: #000000;
}

.add-new-button {
  background: #FF9B00 !important;
  box-shadow: 0px 4px 14px -5px rgba(0, 0, 0, 0.31);
  border-radius: 6px;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF !important;
  font-family: "Roboto", sans-serif !important;
  text-transform: capitalize !important;
}

.list-cards {
  margin-top: 20px;
}

.list-cards .card-header {
  background: #2A2E43 !important;
  box-shadow: 0px 4px 11px 3px rgba(0, 0, 0, 0.05) !important;
  border-radius: 8px 8px 0px 0px !important;
  color: #ffffff;
  min-height: 85px;
  text-align: center;
}

.tt-list-title-banner {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-top: 18px;
}

.divison-color {
  background: rgb(102, 94, 255);
  background: linear-gradient(90deg, rgba(102, 94, 255, 1) 0%, rgba(102, 94, 255, 1) 35%, rgba(58, 204, 225, 1) 100%);
  width: 100%;
  height: 6px;
  margin-bottom: 15px;
}

.btn-view-tt {
  display: flex;
  flex-wrap: nowrap;
}

.tt-explore-btn {
  flex: 1 1;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  font-family: "Roboto", sans-serif !important;
  color: #047C7C !important;
  border: 1px solid #047C7C !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  box-shadow: unset !important;
  text-transform: capitalize !important;
}

.tt-entry-bb-btn {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 28px;
  color: #626262 !important;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif !important;
  word-spacing: 5px;
}

.tt-display-card-upvotes {
  display: flex;
  flex-wrap: nowrap;
}

.tt-display-card-upvotes .mb-0 {
  flex: 1 1;
  padding-left: 15px;
}

.toggle-upvote-button {
  display: flex;
}

.upvote-statement {
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #309897;
  padding: 16px;
  margin-right: 20px;
  margin-left: 10px;
}

.toggle-upvote-button i {
  background: #000000;
  color: #EDEDED;
  border: none;
  font-size: 28px;
  border-radius: 100%;
}

.likes-display-count {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  font-family: "Roboto", sans-serif;
  color: #309897;
  padding-left: 10px;
  position: relative;
  top: 4px;
}

.show-display-tt {
  margin-top: 150px !important;
}

.show-display-tt .suggest-idea span {
  padding-left: 0px !important;
}


.top-ten-banner {
  height: 100px;
  height: 400px;
  background: #eee;
}

.back-button-show {
  position: absolute;
  top: 90px;
  left: 16px;
  z-index: 1;
  color: #fff !important;
  font-size: 12px !important;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.show-display-tt {
  margin-bottom: 20px;
}

.timeline-title {
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  color: #2E2E2E;
  text-transform: uppercase;
}

.timeline-time {
  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  color: #2E2E2E !important;
}

/* ===== Media queries ==== */
@media (max-width: 700px) {
  .top10NameDisplay {
    display: flex;
    position: relative;
    font-size: 20px;
    text-align: center !important;
    justify-content: center;
  }

  #btn-added-tt {
    width: 100% !important;
    margin-bottom: 50px;
    margin-top: 20px;

  }
}

@media only screen and (max-width: 800px) {
  .top-nav-icon-cont {
    display: none !important;
  }
}
.marker {
  width: 15px;
  height: 15px;
}

.user-badge-container {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
}

.user-badge {
  margin: 10px;
  border: 1px solid #047C7C;
  border-radius: 50%;
}

.user-badge-top {
  height: 119px;
  border-radius: 50%;
}

.user-badge-top img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}


.profile-avatar-thumb {
  height: 64px;
  width: 64px;
  border-radius: 50%;
}

.profile-avatar-thumb-sm {
  height: 44px;
  width: 44px;
  border-radius: 50%;
}

.profile-avatar-thumb-xs {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.profile-avatar-badge {
  height: 30px;
  width: 30px;
  background-color: #047C7C;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 50%;
  padding: 5px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}

.mv {
  position: relative !important;
  bottom: 100px;
}

.mv-sg {
  position: relative !important;
  bottom: 7px;
}

.app-add-btn {
  background-color: #fff;
  color: #047C7C;
  border: 1px solid #047C7C;
  width: 150px;
  height: 40px;
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  cursor: pointer;
}

.app-add-btn-2 {
  background-color: #fff;
  color: #047C7C;
  border: 1px solid #047C7C;
  width: 150px;
  height: 30px;
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.app-add-btn:hover {
  background-color: #047C7C;
  color: #E0E0E0;
}

.no-dec-link {
  text-decoration: none !important;
}

.no-btn {
  border: unset;
  background-color: unset;
  color: #ffffff;
}

.app-head-title {
  color: #047C7C;
  font-family: "Roboto", sans-serif;
}

@media only screen and (max-width: 825px) {
  .profile-avatar-thumb {
    height: 44px;
    width: 44px;
  }

  .profile-avatar-thumb-sm {
    height: 34px;
    width: 34px;
  }
}

@media only screen and (max-width: 500px) {

  .tag-badge {
    height: 20px;
    width: 100px;
    padding: 2px;
  }

  .tag-badge span {
    font-size: 12px;
    bottom: 6px;
  }

}
.classic-green {
    background-color: rgb(73, 137, 8);
}

.classic-orange {
    background-color: rgb(241, 109, 61);
}

.classic-red {
    background-color: rgb(222, 31, 69);
}

.classic-grey {
    background-color: rgb(138, 143, 143);
}

.view-pass-btn {
  border:unset;
  background: none;
  position: relative;
  bottom: 30px;
  cursor:pointer;
}
body {
  background-color: #EFF0F1;
}

.category-header {
  padding: 16px;
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-weight: 600;
}

.category-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 8px;
}

.category-crd {
  width: 260px;
  height: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin: 8px;
  padding: 8px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.category-img-cont {
  width: 245px;
  height: 135px;
  border-radius: 5px;
  border: 1px solid #EFF0F1;
}

.category-img-cont img {
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
}

.category-crd-title {
  font-size: 18px;
}

.category-crd-btn {
  border: 1px solid #047C7C;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  color: #047C7C!important;
}

.category-crd-btn:hover {
  background-color: #047C7C;
  color: #ffffff !important;
}
 
.top-nav {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 94px;
  background-color:  #272F3A;
  border-bottom: 1px solid #e2e2e2;
  z-index: 1 !important;
}

.favorite-nav-btn {
  font-size: 25px;
}

.top-nav-ham {
  position: relative;
  top: 18px;
  margin-right: 10px;
  border: unset;
  background: unset;
  color: #fff;
}

.top-nav-link-menu {
  width: 33%;
  padding-top: 10px;
  padding-left: 24px;
}

.top-nav-search {
  padding-top: 24px;
  width: 33%;
}

.top-nav-search input {
  width: 90%;
  padding: 6px;
  border-radius: 16px;
  border: unset;
  background-color: #e2e2e2;
}

.top-nav-icons {
  display: flex;
  flex: row 1;
  justify-content: space-evenly;
  padding-top: 26px;
  justify-items: center;
  width: 33%;
}

.top-nav-icon-cont {
  display: flex;
  flex-direction: column !important;
  justify-items: center;
  align-items: center;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.top-nav-icon-cont-profile {
  display: flex;
    flex-direction: column !important;
    justify-items: center;
    align-items: center;
    color: #fff;
    font-family: "Roboto", sans-serif;
    position: relative;
    right: 25px;
    top: 20px;
    cursor: pointer;
}

.top-nav-icons i:hover {
  cursor: pointer;
  color: #FF9B00 !important;
}

.tuc-logo{
  position: relative;
  top: 8px;
  left: 20px;
}

.toggle-nav {
  width: 340px;
  height: 0;
  position: absolute;
  top: 90px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: #fff;
  border-radius: 8px;
  ;
  border: 1px solid rgb(205, 199, 199);
  padding: 30px;
  -webkit-animation-name: toggle-nav-height;
          animation-name: toggle-nav-height;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  z-index: 100;
}

.user-toggle-nav {
  width: 340px;
  height: 0;
  position: absolute;
  right: 0;
  top: 90px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: #fff;
  border-radius: 8px;
  ;
  border: 1px solid rgb(205, 199, 199);
  padding: 30px;
  -webkit-animation-name: user-toggle-nav-height;
          animation-name: user-toggle-nav-height;
  -webkit-animation-duration: 200ms;
          animation-duration: 200ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  z-index: 100;
}

.toggle-nav a, .user-toggle-nav a, .bagdes-div {
  width: 100%;
  font-size: 20px !important;
  padding: 8px;
  font-weight: 400;
  color: #535151 !important;
  line-height: 50px;
  border-radius: 8px;
}

.toggle-nav a:hover, .user-toggle-nav a:hover {
  background-color: #FF9B00;
  color: #fff !important;
}

.nav-btn-logout {
  border: unset;
  background-color: rgb(237, 62, 62);
  color: #fff;
  border-radius: 8px;
  padding: 6px;
}

.btn-wrapper {
  border: unset;
  background: unset;
  text-align: left;
}

@-webkit-keyframes toggle-nav-height {
  100% {
    height: 800px;
  }
}

@keyframes toggle-nav-height {
  100% {
    height: 800px;
  }
}

@-webkit-keyframes user-toggle-nav-height {
  100% {
    height: 400px;
  }
}

@keyframes user-toggle-nav-height {
  100% {
    height: 400px;
  }
}

@media only screen and (max-width: 770px) {
  .top-nav-link-menu {
    width: 50%;
  }

  .top-nav-icons {
    width: 50%;
  }

  .top-nav-search {
    display: none;
    width: 0;
  }
}

@media only screen and (max-width:580px) {
  .tuc-logo {
    display: none;
  }

  .top-nav-ham {
    top: 16px;
  }

  .toggle-nav {
    width: 92%;
  }

  .category-header {
    font-size: 30px !important;
    text-align: center;
  }

  .fa-award, .fa-envelope, .fa-bell {
    display: none !important;
  }

  .top-nav-icons {
    justify-content: end;
    padding-right: 16px !important;
  }
}

@media only screen and (min-width: 800px) {
  .top-nav-icons {
    padding-right: 20px;
    width: unset;
    padding-top: unset;
  }

  .top-nav-icon-cont {
    padding: 20px;
  }
}
.notifications-container {
  position: absolute;
  background: #f8fafc;
  top: 100px;
  right: 10px;
  width: 380px;
  height: 600px;
  border-radius: 10px;
  overflow-y: scroll;
  z-index: 2;
}

.notifications-container-header {
  padding: 8px;
  border-bottom: 1px solid #ccced2;
}

.notifications-container-header button {
  margin: 6px;
  border: unset;
  border-radius: 6px;
  background: #ccced2;
}

.notifications-list {
  width: 100%;
  padding: 5px;
}

.notifications-list-item {
  display: flex;
  list-style-type: none;
  border-bottom: 1px solid #ccced2;
}

.notifications-list-item-left {
  display: flex;
  width: 18%;
  justify-items: center;
  align-items: center;
}

.notifications-list-item-center {
  width: 73%;
  color: #595b5e
}

.notifications-list-item-center:hover {
  text-decoration: none;
  color: #f3ac08;
}

.notifications-list-item-right {
  display: flex;
  width: 10%;
  justify-items: center;
  align-items: center;
}

.notifications-list-item-right button {
  border: unset;
  background: unset;
}

   .ribbon {
    font-size: 20px!important;
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    width: 75%;
    position: relative;
    background: #ff9b00;
    color: #fff;
    text-align: center;
    padding: 1em 1.2em;
    border-radius: 10px;
    margin: 0 auto 1.5em;
    border-bottom-left-radius:20%;
    border-bottom-right-radius:20%;
}
   .ribbon:before, .ribbon:after {
    content: "";
    position: absolute;
    margin-bottom: 10px;
    display: block;
    bottom: -1em;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 1.5em solid #e68b04;
    z-index: -1;
   }
   .ribbon:before {
    left: -2em;
    border-right-width: 1.5em;
    border-left-color: transparent;
   }
   .ribbon:after {
    right: -2em;
    border-left-width: 1.5em;
    border-right-color: transparent;
   }
   .ribbon .ribbon-content:before, .ribbon .ribbon-content:after {
    content: "";
    position: absolute;
    display: block;
    border-style: solid;
    border-color: #804f7c transparent transparent transparent;
    bottom: -1em;
   }
   .ribbon .ribbon-content:before {
    left: 0;
    border-width: 1em 0 0 1em;
   }
   .ribbon .ribbon-content:after {
    right: 0;
    border-width: 1em 1em 0 0;
   }

   .non-semantic-protector { position: relative; z-index: 1; }
.card {
  cursor: pointer;
}

.recent-search-desktop {
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
}

.fa-forward {
  color: black !important;
}

.fa-backward {
  color: orange !important;
}

.jumbo-suggest {
  margin: 0 auto;
}

.suggest-btn-group {
  text-align: center;
}

.tablet-view {
  display: none;
}

.computer-view {
  display: none;
}

.show-dektop-nav {
  display: none;
}

.tt-filter-bar-desktop {
  display: none;
}

.suggetion-plus-button-container {
  display: inline;
  position: fixed;
  bottom: 50px;
  right: 30px;
}

.suggest-btn {
  background: #ec9528;
  box-shadow: 0px 4px 14px -5px rgba(0, 0, 0, 0.31);
  border-radius: 6px;
  padding: 5px;
  border: unset;
  color: #ffff;
  position: relative;
  top: 8px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 8px;
}

.suggestion-creator-avatar {
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.suggestion-creator-avatar h6 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #2E2E2E;
  text-transform: capitalize;
}

.share-display-container-sub {
  display: flex;
  margin-top: 15px;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.share-display-container-sub span {
  color: #2E2E2E;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
}

.sb-shared-icons-avatars {
  box-shadow: -1px -1px 36px -1px rgba(74, 70, 70, 1);
  -webkit-box-shadow: -1px -1px 36px -1px rgba(74, 70, 70, 1);
  -moz-box-shadow: -1px -1px 36px -1px rgba(74, 70, 70, 1);
}

.sb-reusable-card-img {
  border: 1px solid #dee2e6;
}

.date-of-suggestion-creation {
  margin-left: 60px;
  margin-top: -18px;
}

.suggestion-idea-title {
  display: flex;
  background-color: #dedad3;
  border-radius: 4px;
  min-height: 40px;
  max-height: 40px;
  align-items: center;
  margin-top: 20px;
  box-shadow: -1px -1px 11px -1px rgba(130, 123, 123, 0.88);
  -webkit-box-shadow: -1px -1px 11px -1px rgba(130, 123, 123, 0.88);
  -moz-box-shadow: -1px -1px 11px -1px rgba(130, 123, 123, 0.88);
}

.reusable-card .suggestion-idea-title {
  display: flex;
  background-color: #dedad3;
  border-radius: 4px;
  min-height: 40px;
  max-height: 40px;
  align-items: center;
  margin-top: 0px;
}

.suggestions-shared-profiles {
  display: flex;
  flex-wrap: nowrap;
}

.suggestions-shared-profiles .share-button-image-sug {
  margin-top: 8px;
  padding-right: 22px;
}

.wrapper {
  display: flex;
  width: 100%;
}

#sidebar {
  width: 250px;
  position: fixed;
  padding-top: 50px;
  top: 0;
  left: 0;
  height: 100vh;
  background: #7386d5;
  color: #fff;
  transition: all 0.3s;
}

.reply-btn-sb {
  border: 2px solid #000;
  border-radius: 10px;
  width: 100px;
  margin-top: -4px;
}

.main-sb-comment {
  color: #2e2e2e;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.7px;
  width: 100%;
  background: #eee;
  min-height: 100px;
  padding: 10px;
  border-radius: 10px;
}

.main-sb-comment-profile-names {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #2E2E2E;
}

/* ==== New design CSS ==== */

.suggestion-header h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  margin-top: 33px;
  text-transform: capitalize;
  font-size: 26px;
}

.tt-filter-bar {
  margin-top: 50px;
}

.drop-title {
  color: #2e2e2e;
  font-size: 18px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.drop-title-category {
  font-weight: 600;
  font-size: 18px;
  color: #309897;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
}

.suggestion-design-change i {
  margin-top: 2px;
  font-size: 25px;
  color: #a4a4a4;
  cursor: pointer;
}

.tt-suggestion-card {
  border: none;
  box-shadow: 0px 5px 14px 6px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  background: #ffffff;
  margin-top: 10px;
  margin-bottom: -40px;
  min-width: 450px;
  max-width: 450px;
  min-height: 730px;
  margin-bottom: 30px;
}

.tt-suggestion-card-title {
  display: flex;
  flex-wrap: nowrap;
}

.suggest-idea span {
  color: #1d1d1d;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 18px;
  padding-left: 8px;
}

.suggestion-purpose {
  margin-top: 15px;
  margin-left: 25px;
  color: #2e2e2e;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.7px;
}

.tt-tags-display {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 16px !important;
}

.tt-tags-display .badge {
  border: 1px solid #eee;
  padding: 8px;
  border-radius: 20px;
  margin-left: 5px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-size: 14px;
}

.tt-suggestion-details {
  font-family: "Roboto", sans-serif;
  color: #000000;
  opacity: 0.6;
  font-size: 16px;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 42px;
}

.suggestion_images {
  display: flex;
  flex-wrap: nowrap;
}

.suggestion_images img {
  width: 100px !important;
  height: 100px !important;
  flex: 1 1;
  margin-left: 10px;
  margin-right: 10px;
}

.sb-like-comment-btn {
  display: flex;
  flex-wrap: nowrap;
}

.show-like-share-container {
  margin-top: 35px;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
}

.show-like-share-container .show-like-share-container {
  flex: 1 1;
}

.show-like-share-container .liked-by-container {
  flex: 1 1;
}

.shared-with-chip {
  margin-top: 8px;
  margin-right: 8px;
}

.sb-like-comment-btn {
  margin-top: 15px;
  margin-bottom: 10px;
}

.sb-like-comment-btn img {
  height: 30px;
}

.sb-like-comment-btn button {
  border: unset;
  background: unset;
  flex: 1 1;
}

.sb-like-comment-btn span {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.tt-suggestion-card cite {
  padding-top: 12px;
  padding-right: 10px;
  font-style: italic;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  padding-bottom: 12px;
}

.tt-suggestion-card-title {
  margin-top: -18px;
}

.suggestion-comments-container {
  margin-top: 52px;
  display: inherit;
}

.suggestion-comments-container .card {
  border: none;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 5px 14px 6px rgb(0 0 0 / 11%);
}

.suggestion-comments-counter {
  display: flex;
  flex-wrap: nowrap;
}

.suggestion-comments-counter h3 {
  flex: 1 1;
  text-align: left;
  font-weight: 500;
  font-size: 19px;
  line-height: 25px;
  color: #000000;
  opacity: 0.8;
  font-family: "Roboto", sans-serif;
}

.suggestion-comments-counter .suggetion-comments-sort {
  flex: 1 1;
  text-align: right;
  margin-top: -5px;
}

.suggetion-comments-sort .dropdown-toggle {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #309897;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}

.suggestion-comment-field {
  margin-top: 30px;
}

.suggestions-comments-body {
  margin-top: 40px;
}

.comment-container-tt-v {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}

.comment-container-tt-v img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.comment-itself {
  flex: 1 1;
  padding-left: 20px;
  font-weight: normal;
  font-size: 15px;
  line-height: 29px;
  color: #000000;
  opacity: 0.6;
  font-family: "Roboto", sans-serif;
  flex-wrap: wrap;
}

.suggest-comment-createdat {
  font-weight: 400;
  font-size: 11px;
  line-height: 25px;
  text-align: right;
  color: #000000;
  opacity: 0.6;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  letter-spacing: 0px;
  margin-top: -20px;
}

.suggestion-comments-icons-v {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 25px;
  margin-top: 15px;
}

.suggestion-comments-icons-v .sugget-like {
  flex: 1 1;
  text-align: center;
}

.suggestion-comments-icons-v .sugget-like span {
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #309897;
  font-family: "Roboto", sans-serif;
}

.suggestion-comments-icons-v .comments-edit {
  flex: 1 1;
  text-align: center;
}

.suggestion-comments-icons-v .comments-edit span {
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  opacity: 0.6;
  font-family: "Roboto", sans-serif;
}

.suggestion-comments-icons-v .comments-reply {
  flex: 1 1;
  text-align: right;
}

.search-linking-projects-cont {
  padding: 2px;
}

.suggestion-comments-icons-v .comments-reply span {
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  opacity: 0.6;
  font-family: "Roboto", sans-serif;
}

.linked-project-close {
  right: -40px;
  position: absolute;
}

.project-linked-list {
  cursor: pointer;
}

.vl {
  margin-bottom: 20px;
  opacity: 0.15;
  border: 1px solid #000000;
  height: 95px;
  position: absolute;
  left: 0;
  margin-left: 45px;
  top: 402px;
}

.suggestion-purpose span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #FF9B00;
  padding-right: 10px;
}

.category-sb-display {
  display: contents;
}

.category-sb-display img {
  height: 15px;
  margin-left: -5px;
  margin-right: 8px;
  margin-top: 5px;
}

.media-reply-container {
  border-radius: 4px;
}

.creator-of-comment {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300 !important;
}

.vertical-bar-comment-box-sub {
  height: 98%;
  background: #047c7c;
  width: 2px;
  position: relative;
  top: 10px;
  left: 26px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

}

.text {
  display: inline !important;
  width: 100% !important;
}

.read-or-hide {
  color: #047c7c !important;
  cursor: pointer !important;
  position: relative;
}

/* === Media queries === */

@media (min-width: 768px) {

  /* === showpage === */
  .showpage-computer-view {
    display: inline-block !important;
    margin-top: 20px;
  }

  .suggestion-box-banner img {
    -webkit-filter: brightness(0.49);
            filter: brightness(0.49);
    min-height: 300px;
  }

  .suggestion-box-banner .card {
    border: none;
  }

  .suggestion-box-banner .card-title {
    font-weight: bold;
    font-size: 30px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    line-height: 35px;
    margin-top: 25px;
  }

  .suggestion-box-banner .card-text {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
  }

  .suggestion-box-banner hr {
    background-color: #fff;
  }

  .suggestion-trending-container {
    margin-top: 40px;
  }

  .suggestion-trending-container .card {
    background: #FFFFFF;
    box-shadow: 0px 5px 14px -3px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    border: none;
  }

  .suggestion-trending-container .card-header {
    background: unset !important;
    border: unset !important;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #2E2E2E;
    font-weight: 700;
  }

  .suggestion-trending-details span {
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    color: #2E2E2E;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    margin-left: 8px;
  }

  .suggestion-trending-details cite {
    font-family: "Roboto", sans-serif;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    text-align: right;
    color: #000000;
    opacity: 0.6;
    margin-left: 5px;
  }

  .suggestion-trending-comments {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 30px;
  }

  .suggestion-trending-comments .tt-comments {
    flex: 1 1;
  }

  .suggestion-trending-comments .tt-like {
    flex: 1 1;
  }

  .suggestion-trending-comments img {
    height: 30px;
  }

  .suggestion-trending-comments span {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    color: #2E2E2E;
    opacity: 0.6;
  }

  /* === showPage=== */
  .tablet-view {
    display: inline-block;
  }

  .sticky-sec {
    position: sticky;
    top: 0;
    z-index: 0;
    margin-bottom: 40px;
  }

  .suggestion_images img {
    margin-left: 0px;
  }

  .sb-like-comment-btn img {
    height: 30px;
  }

  .sb-like-comment-btn span {
    font-size: 14px;
  }

  .category-sb-display img {
    margin-left: -18px;
  }

  .category-sb-display span {
    font-size: 15px;
  }

  .suggestion-navigation-container {
    background: #FFFFFF;
    box-shadow: 0px 5px 14px -3px rgba(0, 0, 0, 0.11);
  }

  .suggestion-navigation-container .card {
    border: none;
  }

  .suggestion-navigation-container .card-header {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: #2E2E2E;
    font-weight: 700;
    padding: 20px;
  }

  .suggestion-navigation-container .list-group-item {
    padding: 18px;
  }

  .suggestion-navigation-container .list-group-item span {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #2E2E2E;
    margin-left: 10px;
  }

  .suggestion-navigation-container .active {
    background: #d9af7a;
    border-left: 4px solid #ec9528;
    border-bottom: none;
  }

  .suggestion-navigation-container .list-group-item img {
    height: 28px;
  }

  .suggestion-navigation-container .list-group-item:hover {
    background: #d9af7a;
    border-left: 4px solid #ec9528;
    border-bottom: none;
    cursor: pointer;
  }

  .suggestion-categories-container {
    margin-top: 40px;
    background: #FFFFFF;
    box-shadow: 0px 5px 14px -3px rgba(0, 0, 0, 0.11);
  }

  .suggestion-categories-container .card {
    border: none;
    border-radius: 8px;
  }

  .suggestion-categories-container .list-group-item img {
    height: 40px;
    width: 40px;
  }

  .suggestion-categories-container .list-group-item span {
    font-size: 16px;
    color: #2E2E2E;
    font-family: "Roboto", sans-serif;
    text-transform: capitalize;
    margin-left: 5px;
  }

  .suggestion-categories-container .list-group-item .icon-image {
    height: 15px;
    width: 15px;
  }

  .suggestion-categories-container .cat-value-number {
    position: absolute;
    right: 5px;
    margin-top: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #2E2E2E;
  }

  .suggestion-categories-container .card-header {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: #2E2E2E;
    font-weight: 700;
    padding: 20px;
  }

  .suggestion-searches-container {
    margin-top: 40px;
    background: #FFFFFF;
    box-shadow: 0px 5px 14px -3px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    margin-bottom: 40px;
  }

  .suggestion-searches-container .card {
    border: none;
    border-radius: 8px;
  }

  .suggestion-searches-container .card-header {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: #2E2E2E;
    font-weight: 700;
    padding: 20px;
  }

  .suggestion-searches-container .card-body {
    display: contents;
    flex-wrap: wrap;
  }

  .suggestion-searches-container .card-body li {
    border: 1px solid #eee;
    min-width: 10px;
    padding: 10px;
    border-radius: 20px;
    margin-left: 5px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: capitalize;
    font-size: 14px;
  }

  .edit-reply-mobile-container {
    display: flex;
    flex-wrap: nowrap;
  }

}

.sugget-like img {
  height: 28px;
}

.sugget-like button {
  border: unset;
  background-color: unset;
}

.new-suggestion-form-tt h2 {
  color: #2E2E2E;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
}

.new-suggestion-form-tt .form-title {
  display: flex;
  flex-wrap: nowrap;
}

.new-suggestion-form-tt .form-title h2 {
  flex: 1 1;
  font-size: 24px;
}

.new-suggestion-form-tt .form-title button {
  flex: 1 1;
  text-align: right;
  background: none;
  border: none;
}

.new-suggestion-form-tt .form-title button i {
  font-size: 28px;
}

.new-suggestion-form-tt {
  border: none;
}

.new-suggestion-form-tt label {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 25px;
  color: #2E2E2E;
}

.new-suggestion-form-tt .form-group {
  margin-top: 10px;
}

.new-suggestion-form-tt .submit-btn {
  box-shadow: 0px 5px 12px rgb(0 0 0 / 25%);
  border-radius: 6px;
  padding-left: 20px;
  padding-right: 20px;
}

.vertical-bar-comment-box {
  height: 98%;
  background: #eabc83;
  width: 2px;
  position: relative;
  top: 10px;
  left: 26px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.main-comment-box-divider {
  margin: 30px 0px;
  width: 95%;
  position: relative;
  left: 30px;
}

.comment-date-posted {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
}

.showpage-mobile-navigation {
  display: none;
}


@media (min-width: 1200px) {
  .show-dektop-nav {
    display: inline-block;
    margin-top: 40px;
  }

  .computer-view {
    display: inline-block;
    margin-top: 40px;
  }

  .suggestion-box-banner .card {
    border: none;
  }

  .suggestion-box-banner img {
    -webkit-filter: brightness(0.49);
            filter: brightness(0.49);
    min-height: 300px;
  }

  .suggestion-box-banner .card-title {
    font-weight: bold;
    font-size: 30px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    line-height: 35px;
    margin-top: 25px;
  }

  .suggestion-box-banner .card-text {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
  }

  .suggestion-box-banner hr {
    background-color: #fff;
  }

  .suggestion-trending-container {
    margin-top: 40px;
  }

  .suggestion-trending-container .card {
    background: #FFFFFF;
    box-shadow: 0px 5px 14px -3px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    border: none;
  }

  .suggestion-trending-container .card-header {
    background: unset !important;
    border: unset !important;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #2E2E2E;
    font-weight: 700;
  }

  .suggestion-trending-details span {
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    color: #2E2E2E;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    margin-left: 8px;
  }

  .suggestion-trending-details cite {
    font-family: "Roboto", sans-serif;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    text-align: right;
    color: #000000;
    opacity: 0.6;
    margin-left: 5px;
  }

  .suggestion-trending-comments {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 30px;
  }

  .suggestion-trending-comments .tt-comments {
    flex: 1 1;
  }

  .suggestion-trending-comments .tt-like {
    flex: 1 1;
  }

  .suggestion-trending-comments img {
    height: 30px;
  }

  .suggestion-trending-comments span {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    color: #2E2E2E;
    opacity: 0.6;
  }

  .tt-filter-bar-mobile {
    display: none;
  }

  .tt-filter-bar-desktop {
    display: inline-block;
  }

  .filter-icons {
    position: relative;
    left: 100px;
    margin-left: -10 px;
  }

  .tt-filter-bar-desktop .nav-item {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #000;
    text-transform: capitalize;
  }

  .tt-filter-bar-desktop hr {
    position: relative;
    top: -30px;
  }

  .tt-filter-bar-desktop .active {
    border-bottom: 2px solid #F19F39;
  }

  .filter-icons i {
    font-size: 25px;
    font-weight: bolder;
  }

  .filter-icons span {
    font-size: 20px;
    padding-right: 15px;
    font-size: 18px;
    line-height: 21px;
    color: #2E2E2E;
    font-family: "Roboto", sans-serif;
  }

  .suggestion_images img {
    height: unset !important;
  }
}

.sb-paginate-bar {
  padding-top: 20px;
  padding-bottom: 20px;
}

.media-container {
  border: 1px solid #f1e6e6;
  border-radius: 4px;
  padding: 8px;
  margin-top: 10px;
  margin-bottom: 18px;
}

.suggestion-mobile-menu-container {
  display: none;
}

.suggestion-box-trending-mobile {
  display: none;
}

.profiles-likes-dis {
  cursor: pointer;
}

.profiles-likes-dis :hover {
  background: green;
  color: white;
  border-radius: 5px;
}

/* ==== Responsiveness issues fix ===== */

@media (max-width: 768px) {

  .tt-suggestion-card {
    margin-top: unset !important;
    margin-bottom: 50px !important;
  }

  .recent-search-mobile {
    position: relative;
    margin-bottom: 30px;
  }

  .suggestion-mobile-menu-container {
    display: inline;
  }

  .suggetion-plus-button-container {
    display: none;
  }

  .suggest-btn {
    display: none;
  }

  .suggestion-main-title {
    display: none;
  }

  .suggestion-search-container {
    display: none;
  }

  .suggestion-box-trending-mobile {
    display: inline;
    position: relative;
    top: -24px;
  }

  .sug-container {
    padding-left: unset !important;
    padding-right: unset !important;

  }

  .suggestion_images {
    flex-wrap: wrap;
  }

  .suggestion_images img {
    object-fit: contain !important;
    height: 90px !important;
  }

  .sb-like-comment-btn {
    display: grid;
    align-items: flex-start;

  }

  .sb-like-comment-btn button {

    padding-bottom: 35px;
  }

  .sb-like-comment-btn .suggestions-shared-profiles {
    align-items: center;
    justify-content: center;
  }

  .project-link-div {
    height: auto !important;
  }

  .MuiTab-iconWrapper {
    padding-left: 20px;
  }

  .sug-container {
    overflow-x: hidden !important;
  }

  .new-suggestion-form-tt {
    position: relative;
    top: -48px;
    width: 100%;
    height: 100vh;
    box-shadow: unset !important;
    border: unset !important;
  }

  .new-suggestion-form-tt .form-title h2 {
    display: none;
  }

  .new-suggestion-form-tt .card-body {
    box-shadow: unset !important;
  }

  .new-suggestion-form-tt .form-title button {
    display: none;
  }

  .new-suggestion-form-tt hr {
    display: none;
  }

  .new-suggestion-form-tt form {
    position: relative;
    top: -24px;
  }

  .new-suggestion-form-tt .submit-btn {
    width: 100%;
    text-align: center !important;
    margin-left: unset;
    padding-left: unset;
    padding-right: unset;
  }

  .suggestion-mobile-search-results {
    position: relative;
    padding-bottom: 40px;
    top: -40px;
  }

  .suggestion-comments-container {
    display: none;
    margin-top: unset !important;
  }

  .showpage-mobile-navigation {
    display: inline;
  }

  .number-of-comments-drawer {
    color: rgb(129, 124, 124);
    font-weight: 700;
  }

  .mobile-comments-editor {
    position: fixed;
    bottom: 15px;
    display: block;
    right: 8px;
  }

  .comments-mobile-version-container {
    overflow-x: hidden !important;
    margin-top: 15px;
  }

  .mobile-comment-button-container {
    margin-top: 8px;
    margin-bottom: 0px;
  }

  .main-sb-comment-mobile-version {
    color: #2e2e2e;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.7px;
    width: 100%;
    background: #eee;
    min-height: 100px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: -2px 3px 12px 4px rgba(0, 0, 0, 0.14);
    margin-bottom: 15px;

  }

  .vertical-bar-comment-box-mobile-version {
    height: 98%;
    background: #cabcbc;
    width: 1.5px;
    position: relative;
    top: 3px;
    left: 18px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .vertical-bar-comment-box-sub-mobile {
    height: 98%;
    background: purple;
    width: 2px;
    position: relative;
    top: 3px;
    left: 18px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }


  .upvote-button-mobile {
    position: relative;
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    top: -50px;
    right: 10px;
    margin-bottom: -25px;
  }

  .edit-reply-mobile-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin-top: 40px;
  }

  .No-comments-yet {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .close-reply-comment {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  .reply-of-reply-like-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    margin-top: 30px;
  }

  .cancel-btn-cont-display {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  .suggestions-shared-profiles {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .card-container-display {
    margin-bottom: -55px;
  }

  /* .shared-with-container {
    margin-bottom: 10px;
    margin-top: 30px;
  } */

}


@media (max-width: 580px) {
  .suggestion-header h3 {
    font-size: 22px;
    position: absolute;
    left: 0px;
  }

  .suggest-btn {
    margin-right: -35px;
  }

  .suggestion-design-change i {
    position: absolute;
    font-size: 22px;
  }

  .drop-title {
    font-size: 16px;
  }

  .suggestion_images img {
    margin-left: 0px;
    margin-right: 3px;
    border: unset;
    position: relative;
    margin-left: -5px;
    object-fit: cover;
  }

  .tt-suggestion-card cite {
    margin-bottom: 15px;
  }

  .category-sb-display {
    display: none;
  }

  .tt-suggestion-details {
    margin-left: 10px;
  }

  .suggestion-purpose {
    margin-left: 0px;
    font-size: 15px;
  }

  .suggestion-purpose span {
    font-size: 16px;
    font-weight: 700;
  }

  .tt-suggestion-details {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .suggest-idea span {
    padding-left: 0px;
  }

  .drop-title-category {
    font-size: 16px;
  }

  .sb-like-comment-btn img {
    height: 28px;
  }

  .sb-like-comment-btn span {
    font-size: 14px;
  }

  .new-suggestion-form-tt .form-title h2 {
    font-size: 20px;
  }

  .new-suggestion-form-tt .form-title button i {
    font-size: 25px;
  }

  .new-suggestion-form-tt label {
    font-size: 18px;
  }

  .new-suggestion-form-tt .form-group {
    margin-bottom: 10px;
    margin-top: 12px;
  }
}

@media (max-width: 801px) {
  .tt-suggestion-card {
    width: 100% !important;
  }

  .project-sug-body {
    margin-left: 5px !important;
    margin-right: 10px !important;
  }

}
* {
  scroll-behavior: smooth;
}



.main-body-area-single-project {
  width: 100% !important;
  display: flex;
  flex-wrap: nowrap;
}



.project-static-area-space {
  width: 96%;
  overflow-x: hidden;
}

.project_side_bar_space {
  width: 4%;
}

.project-link-container {
  display: none;
  position: relative;
  top: 100px;
  padding-left: 16px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
}

.project-link-container i {
  color: #19739a;
}


.project-head-container {
  padding: 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
}


.project-head button:hover {
  background-color: #047C7C;
  color: #fff;
}

.project-header {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #2E2E2E;
  margin-left: 6px;
  position: absolute;
  top: -20px;
  text-transform: capitalize;
}

.project-header-btn-bk {
  border: unset;
  background: unset;
  font-weight: 500;
  color: #309897;
  font-family: "Roboto", sans-serif;
}

.project-header a {
  color: #309897;
  text-decoration: none;
}

.project-header a:hover {
  color: #309897;
  text-decoration: underline;
}

.project-carousel-controls {
  position: relative;
  top: 64%;
  display: flex;
  justify-content: space-between;
  width: 71.5%;
  height: 30px;
  z-index: 1;
}

.project-carousel-controls button {
  color: #fff;
  border: unset;
  background: unset;
}

.project-modify-button {
  margin-top: 25px;
  width: 170px;
  height: 48px;
  background: #c9c3c3;
  border: none;
  margin-left: 6px;
  border-radius: 4px;
}

.statistics-container {
  padding: 0px;
  font-family: "Roboto", sans-serif;
}

.statistic-icon-container {
  height: 260px;

}

inline .project-invitation-button {
  background: #c9c3c3;
  top: -88px;
  border-radius: 4px;
  border: none;
  color: #2C2C2C;
  left: 25px;
  opacity: 1;
  position: absolute;
  text-transform: uppercase;
  width: 170px;
  height: 48px;
  font-weight: 400;
}

.timeline-title {

  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 17px !important;
  line-height: 2px;
  text-align: center;
  color: #2E2E2E;
  text-transform: uppercase;
  margin-bottom: 45px;
}


.project-upvote-button {
  box-shadow: 0px 4px 14px -5px rgb(0 0 0 / 31%);
  border-radius: 4px;
  border: none;
  color: #000;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  position: absolute;
  top: -80px;
  left: 62%;
  width: 149px;
  height: 48px;
  font-weight: 400;
}

.single-unfollow {
  background-color: green;
  color: white;
}

.statistics-card {
  display: inline-block;
  padding: 6px;
  color: #ffffff;
  width: 305px;
  height: 150px;
  font-weight: 200;
  border-radius: 8px;
  margin: 3px;
  position: relative;
}

.statistics-card h5 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
}

.statistics-card img {
  width: 290px;
  height: 20px;
}

.stc-planning {
  background: url(/static/media/planning-thb.20b7d3a9.png), linear-gradient(90deg, #091F3E 0%, #3F6BB6 100%);
  background-repeat: no-repeat;
}

.stc-design {
  background: url(/static/media/design-thb.50add2a4.png), linear-gradient(90deg, #084337 0%, #2DC56E 100%);
  background-repeat: no-repeat;
}

.stc-dev {
  background: url(/static/media/dev-thb.0413d329.png), linear-gradient(90deg, #AF680E 0%, #EFA947 100%);
  background-repeat: no-repeat;
}

.stc-testing {
  background: url(/static/media/test-thb.4ba034d6.png), linear-gradient(90deg, #A6374C 55.16%, #FD868C 136.26%);
  background-repeat: no-repeat;
}

.sdg-container {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}

.sdg-container h3 {
  flex: 1 1;
}

.sdg-container button {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  margin-left: 30px;
}

.sdg-container button i {
  padding-right: 10px;
  padding-left: 10px;
}

.statistic-duration-container {
  position: relative;
  top: 30px;
  width: 20%;
  height: 470px;
  display: flex;
  flex-direction: column;
  margin: 8px;
  padding: 16px;
  text-align: center;
  color: #595757;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 14px 20px rgb(58 76 95 / 25%) !important;
  border-radius: 14px !important;
  opacity: 0.9;
}

.search-Project-title-cont {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
}

.s-project-main {
  width: 100%;
}

.sdg-images-edit {
  display: flex;
  flex-wrap: wrap;
}

.s-project-nav {
  width: 100%;
  border-bottom: 1px solid #aba9a9;
  background-color: #ffffff;
  padding: 8px;
}

.remove-sdg-button {
  position: absolute;
  left: 100px;
  top: -14px;
  font-size: 30px;
}

.remove-sdg-button i {
  font-weight: 900;
  cursor: pointer;
  color: #000;
}

.s-project-nav ul {
  position: relative;
  top: 10px;
  text-align: center;
  overflow-x: scroll;
  white-space: nowrap;
  border: 1.5px solid green;
  padding: 4px;
  border-radius: 6px;
}

.s-project-nav ul li {
  display: inline-block;
  font-size: 20px;
  list-style-type: none;
  cursor: pointer;
  margin-right: 40px;
}

.s-project-nav ul li:hover {
  border-bottom: 1px solid #f5913e;
}

.s-project-summary {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 16px;
}

.s-project-summary-details {
  width: 70%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
}

.s-project-summary-feed {
  width: 30%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  flex-direction: column;
}

.project-quick-links-container {
  border: 1px solid #E5EAF2;
  border-radius: 3px;
  background: #eee;
  overflow-y: visible;
  box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  -moz-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  max-width: 1000px;
  width: 100%;
}

.s-project-ipm-card {
  height: 300px;
  overflow-y: scroll;
  border-bottom: 1px solid #e2e2e2;
  background-color: #eee;
  box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  margin-bottom: 8px;
  width: 100%;
}

.project-dec-title-bar-ss {
  box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
}

.project-dec-title-bar-ss {
  background: #fff;
  width: 100%;
  min-height: 50px;
  padding: 8px;
  margin-bottom: -12px;
  margin-top: 10px;
}

.project-description-project-title-tt {
  color: #000 !important;
  font-weight: 900 !important;
  text-decoration: underline;
  font-family: 'Anek Telugu', sans-serif !important;
  font-size: 1.4rem !important;
}

.project-dec-title-bar-ss span {
  color: purple;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  margin-left: 5px;
  position: relative;
  top: 3px;
  text-transform: uppercase;

}

.actual-description {
  color: #2e2e2e;
  font-size: 18px;
  font-weight: 300;
  padding: 8px;
}

.s-project-ipm-card-icon-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  background-color: #fff !important;
  font-weight: bold;
  text-transform: uppercase;
  color: purple;
  padding: 8px;
}

.project-description-container-ss {
  min-height: 310px !important;
  height: 310px !important;
  background: #eee;
  margin-top: 10px;
  box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  border-radius: 3px;
  overflow-y: scroll;
}

.s-project-ipm-card-icon-container h5 {
  margin: 5px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.s-project-ipm-card-icon-container img {
  height: 35px;
}

.s-project-ipm-card-details {
  width: 95%;
  padding: 24px;
}

.s-project-ipm-card-details h3 {
  color: #309897;
  font-size: 20px;
  font-weight: 500;
}

.s-project-ipm-card-details h4 {
  margin-top: 20px;
}

.s-project-ipm-card-details p, .s-project-ipm-card-details h4 {
  color: #2e2e2e;
  font-size: 18px;
  font-weight: 300;
}

.s-project-collaborations {
  position: relative;
  padding: 8px;
  width: 100%;
  height: 300px;
  border-bottom: 1px solid #c3c0c0;
  overflow-y: scroll;
  display: flex;
  margin-left: 16px;
  margin-right: 16px;
  flex-direction: row;
  flex-wrap: wrap;
}

.center-c {
  text-align: center !important;
}

.s-project-collaborations-header {
  padding: 16px;
  background-color: #fff;
  height: 45px;
  align-items: center;
  display: flex;
  padding-left: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.s-project-collaborations-header h3 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin: 5px;
  color: purple;
  font-size: 1rem !important;

}

.s-project-collaborations-header button {
  border: unset;
  background: unset;
  color: #000;
  cursor: pointer;
  position: relative;
  margin-left: 28px;
  font-size: 24px;
  font-weight: 800;
}

.s-project-collaborators {
  position: absolute;
  bottom: 0;
  left: 20px;
  color: #309897;
}

.s-project-extras {
  padding: 24px;
  color: #a6a1a1;
  border-bottom: 1px solid #c3c0c0;
}

.s-project-extras h6 {
  line-height: 40px;
}

.goal-coording-operator {
  display: flex;
  flex-direction: row;
}

.collab-badge {
  height: 40px;
  width: 40px;
  background-color: #047C7C;
  color: white;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  border-radius: 50%;
  margin: 3px;
  padding: 5px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}

.assumption-container {
  margin-bottom: 88px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
}

.assumption-card-cont {
  margin-right: 20px;

}

.assumptions-pagination-container {
  margin-top: -10px;
  margin-bottom: 50px;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}

.s-project-collaborations img {
  margin: 3px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.s-project-quicklinks-list {
  color: #047C7C;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.s-project-quicklinks-list ul li {
  list-style-type: square;
}

.s-project-description {
  font-size: 21px;
  padding: 16px;
  border: 1px solid #ded9d9;
  border-radius: 8px;
}

.project-goal {
  font-size: 20px;
}

.project-comment-form {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
}

.project-comment-form input, .project-comment-form textarea {
  width: 100%;
  margin: 8px;
  border: 1px solid #C4C4C4;
  border-radius: 8px;
  font-family: Roboto;
  font-size: 18px;
  font-style: italic;
  font-weight: 200;
}

.project-comment-card {
  width: 97%;
  display: flex;
  flex-direction: row;
  margin: 16px;
}

.project-comment-edge {
  width: 10%;
  height: 100%;
  color: #2e2e2e;
  padding: 8px;
}

.project-comment-details {
  width: 80%;
  height: 100%;
  color: #2e2e2e;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.edge-time {
  font-family: Roboto;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: right;
}

.project-comment-btns {
  width: 85%;
  padding: 8px;
}

.project-comment-btns ul {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.project-comment-btns-2 ul {
  display: flex;
  flex-direction: row;
}

.project-comment-btns ul li {
  color: #595757;
  list-style-type: none;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}

.project-comment-btns-2 ul li {
  color: #595757;
  list-style-type: none;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}


.reply-form {
  display: flex;
  flex-direction: row;
}

.reply-form-btn {
  position: relative;
  top: 9px;
  color: #C4C4C4;
  background-color: #047C7C;
  border-radius: 8px;
  width: 100px;
  height: 30px;
}

.comment-reply-card {
  display: flex;
  flex-direction: row;
}

.comment-reply-edge {
  width: 10%;
  height: 100%;
  color: #2e2e2e;
  padding: 8px;
}

.support-btn {
  margin-left: 10px;
}

.comment-reply-details {
  width: 90%;
  height: 100%;
  color: #2e2e2e;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.top-bar-sp {
  display: flex;
  flex-wrap: nowrap;
  padding-top: 10px;
}

.top-bar-sp .header-title {
  flex: 1 1;
}

.custom-select {
  font-size: 18px !important;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
}

.optional-field {
  color: #2DC56E !important;
}

.structure-well {
  margin-bottom: 22px;
  position: relative;
  top: -50px;
  margin-top: 8px;
}

.container-m-well {
  margin-top: 100px !important;
}

.s-project-networks {
  margin-top: 50px;
}

/* ===== Invitation card ===== */
@-webkit-keyframes float {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
    -webkit-transform: translatex(0px);
            transform: translatex(0px);
  }

  50% {
    -webkit-transform: translatey(-30px);
            transform: translatey(-30px);
    -webkit-transform: translatex(20px);
            transform: translatex(20px);
  }

  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
    -webkit-transform: translatex(0px);
            transform: translatex(0px);
  }
}

@keyframes float {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
    -webkit-transform: translatex(0px);
            transform: translatex(0px);
  }

  50% {
    -webkit-transform: translatey(-30px);
            transform: translatey(-30px);
    -webkit-transform: translatex(20px);
            transform: translatex(20px);
  }

  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
    -webkit-transform: translatex(0px);
            transform: translatex(0px);
  }
}

/* RESET
========================================== */
*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


/* BUTTON
========================================== */
.button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.button button {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: inherit;
  background-color: #ffffff;
  border: 0;
  padding: 15px 25px;
  color: #000000;
  text-transform: uppercase;
  font-size: 21px;
  letter-spacing: 1px;
  width: 200px;
  overflow: hidden;
  outline: 0;
  transition: all 0.4s;
  visibility: visible;
  opacity: 1;
  font-weight: bold;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.6);
}

.button button:hover {
  cursor: pointer;
  background-color: #8e6ac1;
  color: #ffffff;
}

.button button span {
  opacity: 1;
}

.button.clicked button {
  visibility: hidden;
  opacity: 0;
}

/* POP-UP
========================================== */
.pop-up {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.4);
  z-index: 10;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .pop-up {
    height: auto;
    max-width: 900px;
  }
}

.pop-up .content {
  width: 100%;
  max-width: 900px;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
}

@media (min-width: 768px) {
  .pop-up .content {
    min-height: inherit;
  }
}

.pop-up .content .container {
  padding: 100px 20px 140px;
}

@media (min-width: 568px) {
  .pop-up .content .container {
    padding: 50px 20px 80px;
  }
}

@media (min-width: 768px) {
  .pop-up .content .container {
    padding: 70px 0px 90px;
    max-width: 520px;
    margin: 0 auto;
  }
}

.pop-up .content .close {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
  color: #3e4146;
  transition: all 0.4s;
}

.pop-up .content .close:hover {
  cursor: pointer;
  color: #f66867;
}

.pop-up .content .dots .dot {
  position: absolute;
  border-radius: 100%;
  z-index: 11;
}

.pop-up .content .dots .dot:nth-of-type(1) {
  top: -80px;
  right: -80px;
  width: 160px;
  height: 160px;
  background-color: #689bf6;
  -webkit-animation: float 6s ease-in-out infinite;
  animation: float 6s ease-in-out infinite;
}

@media (min-width: 768px) {
  .pop-up .content .dots .dot:nth-of-type(1) {
    top: -190px;
    right: -190px;
    width: 330px;
    height: 380px;
  }
}

.pop-up .content .dots .dot:nth-of-type(2) {
  bottom: -120px;
  left: -120px;
  width: 240px;
  height: 240px;
  background-color: #f66867;
  -webkit-animation: float 8s ease-in-out infinite;
  animation: float 8s ease-in-out infinite;
}

.pop-up .content .dots .dot:nth-of-type(3) {
  bottom: -50px;
  right: -50px;
  width: 100px;
  height: 100px;
  background-color: #ffd84c;
  -webkit-animation: float 4s ease-in-out infinite;
  animation: float 4s ease-in-out infinite;
}

.pop-up .content .title h1 {
  text-align: center;
  color: #f66867;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 2.8rem;
  letter-spacing: 0.05rem;
}

.pop-up .content img {
  width: 100%;
  display: inline-block;
}

@media (min-width: 768px) {
  .pop-up .content img {
    max-width: 300px;
  }
}

.pop-up .content .subscribe h1 {
  font-size: 1.5rem;
  color: #3e4146;
  line-height: 130%;
  letter-spacing: 0.07rem;
  margin-bottom: 30px;
}

.pop-up .content .subscribe h1 span {
  color: #f66867;
}

.pop-up .content .subscribe form {
  overflow: hidden;
}

.pop-up .content .subscribe form input {
  width: 100%;
  float: left;
  padding: 15px 20px;
  text-align: center;
  font-family: inherit;
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
  outline: 0;
}

.pop-up .content .subscribe form input[type=email] {
  margin-bottom: 15px;
  border: 1px solid #bec1c5;
  transition: all 0.4s;
}

.subscribe label {
  font-size: 22px;
  font-weight: 800;
  color: #212529;
  font-family: "Open Sans Condensed", sans-serif !important;
}

.open-coording-button-desk {
  display: inline;
}

.open-coording-button-mobile {
  display: none !important;
}

/* ===== Invitation card end ===== */

/******************************/

/*=====MEDIA QUERIES=====*/

@media only screen and (max-width: 770px) {
  .statistic-duration-container h4, .statistic-duration-container h3 {
    font-size: 24px !important;
  }

  .statistic-icon-container h3 {
    font-size: 28px !important;
  }

  .s-project-summary-details {
    width: 60%;
  }

  .s-project-summary-feed {
    width: 40%;
  }

  .s-project-ipm-card-details-title {
    margin-left: 3px !important;
  }

  .s-project-ipm-card-icon img {
    position: relative;
    top: 13px;
    width: 30px !important;
  }
}


/* ==== Responsiveness ==== */
@media (min-width: 600px) {
  .container-m-well {
    margin-top: unset !important;
  }

  .description-name-container h1 {
    color: #000 !important;
    font-family: "Roboto", sans-serif !important;
    position: relative !important;
    top: 0px !important;
    font-weight: 300 !important;
    text-transform: uppercase !important;
    margin-bottom: 20px !important;
    text-align: left !important;
    font-size: 28px;
  }

  .description-name-container .underline {
    position: relative;
    top: -13px;
  }

  .swot-main-container {
    margin-bottom: 100px !important;
  }

  .statistic-duration-container {
    width: 25%;
  }

  .project-upvote-button {
    left: 55%;
  }

  .assumption-create-btn {
    position: absolute;
    right: 20px;
    margin-top: -60px;
  }
}

@media (max-width: 600px) {
  .s-project-nav {
    display: none;
  }

  .statistic-duration-container {
    position: relative;
    top: 550px;
  }


  .project-invitation-button {
    position: absolute;
    margin-top: -135px;
    height: 38px !important;
    width: 200px !important;
    left: 8px;
  }

  .project-upvote-button {
    position: absolute;
    margin-top: -90px;
    height: 38px !important;
    width: 160px !important;
    left: 8px;
  }

  .project-header {
    position: absolute;
    top: -118px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    left: 8px;
    font-size: 24px !important;
  }

  .statistics-card {
    top: 0px;
  }

  .project-modify-button {
    height: 38px !important;
    width: 160px !important;
    right: 4px;
    top: -195px;
    position: absolute;
  }

  .s-project-summary {
    margin-bottom: 100px;
  }

  .statistic-duration-container {
    width: 98%;
    margin-bottom: 200px;
    padding-bottom: 200px;
  }

  .s-project-description {
    margin-top: 30px;
    font-family: "Roboto", sans-serif;
  }

  .s-project-description p {
    font-size: 17px;
  }

  .profile-core-values {
    position: absolute;
  }

  .profile-core-values .s-project-description {
    font-family: "Roboto", sans-serif;
  }

  .description-name-container h1 {
    color: #000 !important;
    font-family: "Roboto", sans-serif !important;
    position: relative !important;
    top: 0px !important;
    font-weight: 300 !important;
    text-transform: uppercase !important;
    margin-bottom: 20px !important;
    text-align: left !important;
  }

  .description-name-container .underline {
    position: relative;
    top: -13px;
  }


  .profile-core-values .add-btn {
    top: 26px;
    position: relative;
    right: 0%;
  }

  .assumption-create-btn {
    margin-top: -39px !important;
  }

  .profile-core-values .add-button-need {
    position: absolute;
    right: 10px !important;
  }

  .goal-title {
    margin-top: -20px;
    padding-left: unset !important;
  }

  .add-discussion-btn-j {
    position: relative !important;
    top: unset !important;
  }

  .top-bar-sp {
    display: unset !important;
  }

  .project-in-project-btn {
    text-align: center;
  }

  .sbp-header {
    margin-top: 20px !important;
  }
}

.project-edit-button {
  display: inline !important;
}


/* ========= MOBILE DESIGN ===== */
@media (max-width: 801px) {
  .main-body-area-single-project {
    display: none !important;
  }

  .main-mobile-body-single-project-area {
    display: inline;
    width: 100% !important;
  }

  .project-static-area-space {
    width: 100% !important;
  }

  .project-edit-button {
    display: none !important;
  }

  .project-description-container-ss {
    margin-bottom: 20px;
  }

  .container-m-well {
    margin-top: -8px !important;
  }

  .s-project-summary-feed {
    width: 100% !important;
  }

  .s-project-summary-details {
    width: 100% !important;
  }

  .s-project-summary {
    display: block !important;
  }

  .s-project-summary-details {
    margin: 0px !important;
  }

  .add_item_button {
    margin-top: 10px !important;
  }

  .project-quick-links-container {
    margin-bottom: 70px !important;
    margin-top: 15px !important;
  }

  .assumption-card-cont {
    margin-right: unset !important;
    width: 100% !important;
  }

  .assumption-container {
    margin-left: unset !important;
  }

  .goal-coording-operator {
    display: unset !important;
  }

  .project-goal-display {
    display: contents !important;
  }

  .open-coording-button-desk {
    display: none !important;
  }

  .open-coording-button-mobile {
    display: inline !important;
  }

  .button_group_goal {
    display: flex !important;
    justify-content: space-around;
    margin-left: -50px;
  }

  .down-btn-goal {
    margin-top: 8px !important;
  }

  #goalAccordian .card p {
    line-height: 40px !important;
  }

  .button_group {
    position: relative !important;
    display: flex !important;
    justify-content: space-around;
    margin-top: 10px !important;
  }

  .timeline-kpi-goal-project {
    margin-left: -50px !important;
  }

  .timeline-content-kpi-goal {
    display: flex !important;
    flex-flow: wrap-reverse !important;
  }

  .edit-kpi {
    margin: -5px !important;
    display: contents !important;

  }

  .no-kpi-added-yet {
    margin-left: 30px;
  }

  /* <===== SWOT AND BMC ====> */
  .swot-info-container {
    margin-top: 10px !important;
    margin-bottom: 40px !important;
  }

  .list-card {
    display: flow !important;
  }
}

.main-mobile-slider-area {
  display: inline;
  position: relative;
  top: -15px;
}

.main-body-area-single-project-caurosel {
  display: none !important;
}

@media (min-width: 801px) {
  .main-mobile-body-single-project-area {
    display: none !important;
  }

  .main-mobile-slider-area {
    display: none !important;
  }

  .main-body-area-single-project-caurosel {
    display: inline !important;
  }

  .mobile-collapse-button {
    display: none !important;
  }

}
.profile-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  background: linear-gradient(90deg,
      rgba(48, 49, 50, 0.497) 0%,
      rgba(35, 38, 38, 0.709) 20%),
    url(/static/media/profileheadBg.7e1c2b43.png) -1230px 10px;
  background-size: cover;
  background-position: center;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #fff;
  justify-content: left;
  align-items: left;
}

.profile-header-cont {
  width: 100%;
}


.user-details {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.user-details-info {
  height: 100%;
  text-align: left;
  padding: 16px;
}

.user-details-info h3 {
  margin-top: 16px;
  font-size: 28px;
}

.profile-avatar {
  width: 200px;
  border-radius: 8px;
  border: 2px solid #fff;
}

.edit-area {
  text-align: right;
  padding: 20px;
}

.prof-edit-btn {
  width: 170px;
  height: 50px;
  font-size: 16px;
  color: #fff;
  background: unset;
  border-radius: 6px;
  border: 1px solid #fff;
  margin: 20px;
}

.dots-temp-btn {
  position: relative;
  top: 5px;
  width: 70px;
  height: 50px;
  background: unset;
  border-radius: 6px;
  border: 1px solid #fff;
  color: #fff;
}

.plus-icon {
  display: none;
}

.mobile-edit {
  display: none;
}

.profile-main {
  width: 100%;
}

.profile-nav {
  padding-top: 16px;
  margin: 0;
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
}

.profile-nav-ul {
  position: relative;
  right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.profile-nav ul li {
  font-size: 20px;
  list-style-type: none;
  cursor: pointer;
}

.profile-nav ul li:hover {
  border-bottom: 1px solid #f5913e;
}

.profile-summary {
  width: 100%;
  display: flex;
  flex-direction: row;

}

.profile-summary-details {
  width: 70%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
}

.profile-summary-feed {
  width: 30%;
}

.profile-mv-card {
  display: flex;
  flex-direction: row;
  height: 200px;
  border-bottom: 1px solid #e2e2e2;
}

.summary-contact-card {
  display: flex;
  flex-direction: row;
  height: 300px;
}

.profile-mv-card-icon {
  width: 5%;
  padding: 8px;
}

.profile-mv-card-details {
  width: 95%;
  padding: 24px;
}

.profile-mv-card-details h3 {
  color: #309897;
  font-size: 20px;
  font-weight: 500;
}

.profile-mv-card-details h4 {
  margin-top: 20px;
}

.profile-mv-card-details p,
.profile-mv-card-details h4 {
  color: #2e2e2e;
  font-size: 18px;
  font-weight: 300;
}

.profile-recents {
  width: 90%;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  margin: 16px;
}

.profile-recents-header {
  background: #047c7c;
  height: 56px;
  padding: 16px;
}

.profile-recents-header h3 {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.profile-idea-card h5 {
  font-size: 16px;
  font-weight: 500;
}

.profile-idea-card h6 {
  font-size: 15px;
  font-weight: 400;
}

.profile-idea-card h5,
.profile-idea-card h6 {
  color: #2e2e2e;
}

/******CORE VALUES *******/

.profile-core-values {
  margin: 0;
  padding: 6px;
  width: 100%;
}

.profile-core-values h1,
.profile-core-values h2 {
  text-align: center;
  color: #309897;
  font-weight: 600;
}

.core-value-card {
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 50px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #f5913e;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
}

.core-value-card i {
  position: relative;
  bottom: 8px;
  color: #309897;
}

.core-value-card span {
  position: relative;
  bottom: 8px;
  font-size: 24px;
  color: #2e2e2e;
}

.core-value-form-container {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.core-value-form-container form input {
  width: 80%;
  margin: 8px;
}

.core-value-form-container form button {
  position: relative;
  bottom: 5px;
  width: 20%;
  margin: 8px;
}

.no-cores-thumbnail {
  width: 300px;
}

/**********NETWORK*******/

.profile-net-form {
  width: 90%;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
}

.network-contact-card {
  width: 95%;
  display: flex;
  height: 200px;
  flex-direction: row;
  border-radius: 8px;
  margin-top: 6px;
  margin-left: auto;
  margin-right: auto;
}

.network-contact-card-avatar {
  width: 150px;
  height: 150px;
}

.network-contact-card-details {
  display: flex;
  flex-direction: column;
}

.profile-project-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.project-card {
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 390px;
  margin: 10px;
  border-radius: 8px;
  padding: 8px;
}

.project-card-image {
  background-color: rgb(247, 235, 235);
  width: 100%;
  height: 167px;
  border-radius: 4px;
}

.project-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-card-title {
  white-space: nowrap;
  overflow-x: scroll;
  padding: 6px;
}

.project-card-details {
  width: 100%;
  padding: 6px;
  height: 165px;
  overflow-y: scroll;
}

.project-card-btn {
  border: unset;
  background: #309897;
  color: #ffffff;
  width: 280px;
  height: 46px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  text-align: center;
  text-decoration: none !important;
  font-weight: 500;
  padding: 8px;
}

.project-card-btn:hover {
  background: #ffffff;
  color: #309897;
  border: 1px solid #309897;
}

/******************************/

/*=====MEDIA QUERIES=====*/

@media only screen and (max-width: 1026px) {
  .profile-mv-card-icon img {
    width: 45px;
  }

  .profile-mv-card-details-title {
    margin-left: 16px;
  }

  .profile-summary-details {
    width: 60%;
  }

  .profile-summary-feed {
    width: 40%;
  }
}

@media only screen and (max-width: 770px) {
  .profile-header {
    height: 250px;
  }

  .user-details-info h3 {
    margin-top: 16px;
    font-size: 18px;
  }

  .profile-avatar {
    width: 120px;
  }

  .user-details-info {
    width: 70%;
    height: 100%;
  }

  .head-social-links {
    display: none;
  }
}

.toggle-bar-container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.display-value {
  width: 250px;
  min-height: 100vh;
  max-height: 100vh;
  height: 100vh;
  min-width: 250px;
  max-width: 250px;
  border-right: 0.5px solid rgb(192, 184, 184);
}

.display-my-suggetions-on-prfl {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.suggestion-card-share-cont {
  padding: 6px;
  margin: 10px;
  border-radius: 8px;
}

@media only screen and (max-width: 680px) {
  .profile-header {
    flex-direction: column;
  }

  .profile-header-cont {
    text-align: center;
    width: 100%;
  }

  .profile-avatar {
    width: 90px;
  }

  .user-details {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
  }

  .user-details-info {
    position: relative;
    bottom: 8px;
    width: 100% !important;
    text-align: center !important;
    padding: 0 !important;
  }

  .user-details-info h3 {
    margin-top: 8px;
    font-size: 14px;
  }

  .profile-header-cont-2 {
    display: none !important;
  }

  .mobile-edit {
    display: unset;
    font-size: 12px;
    color: #fff;
    background: unset;
    border-radius: 6px;
    border: 1px solid #fff;
    padding: 8px;
  }

  .profile-nav ul {
    margin-left: 0 !important;
    overflow-x: scroll;
    white-space: nowrap;
  }

  .profile-nav ul li {
    font-size: 14px !important;
    list-style-type: none;
    cursor: pointer;
    display: inline;
    margin-right: 5px;
    white-space: nowrap;
  }

  .profile-main {
    width: 100%;
    padding-top: 8px;
  }

  .profile-summary {
    flex-direction: column;
    margin: 8px;
  }

  .profile-summary-details {
    width: 100%;
  }

  .profile-summary-feed {
    width: 100%;
  }

  .profile-mv-card-details p,
  .summary-contact-card h4,
  .profile-recents p,
  .profile-recents h5 {
    font-size: 13px !important;
  }

  .profile-core-values h1 {
    font-size: 24px;
  }

  .plus-icon {
    display: unset;
  }

  .add-word {
    display: none;
  }

  /**********NETWORK*******/

  .network-contact-card {
    height: 130px;
  }

  .network-contact-card-avatar {
    width: 60px;
    height: 60px;
  }

  .network-contact-card h4 {
    font-size: 12px;
  }
}
.custom-modal {
  position: fixed; 
  opacity: 0.1;
  z-index: 2; 
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto;
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
  font-family: "Roboto", sans-serif;
  -webkit-animation-name: modal-opacity;
          animation-name: modal-opacity;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes modal-opacity {
  100% {
    opacity: 1;
  }
}

@keyframes modal-opacity {
  100% {
    opacity: 1;
  }
}

.custom-modal-header {
  width: 100%;
  height: 80px;
  background-color: #047C7C;
  color: #fff;
  display: flex;
  flex: row 1;
  justify-content: space-between;
  padding-left: 16px;
  padding-top: 24px;
}

.custom-modal-header button {
  position: relative;
  right: 8px;
  bottom: 10px;
  border: unset;
  background: unset;
  color: #fff;
}

.custom-modal-body {
  background-color: #fefefe;
}

.custom-modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.custom-modal-close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.custom-modal-close:hover,
.custom-modal-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
* {
  scroll-behavior: smooth;
}

.postion-margin {
  margin-bottom: 20px;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: purple;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.project-container {
  width: 100%;
}

.project-idea-container {
  color: #2E2E2E;
  opacity: 0.8;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 60px;
}

.slider-card-searched .project-idea-container p {
  height: 120px;
  color: #2E2E2E;
  opacity: 0.8;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 47px !important;
}

.slider-card-searched .project-idea-container {
  margin-bottom: 30px;
}

.input-fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tt-project-title-btn {
  display: flex;
  flex-wrap: nowrap;
}

.tit {
  margin-top: 8px;
  flex: 1 1;
}

.project-filter {
  margin: 10px;
  width: 200px;
  border: 1px solid #c3bcbc;
  border-radius: 8px;
  padding: 8px;
  color: #2E2E2E;
}

.g-i {
  position: relative;
  top: 7px;
  font-size: 30px;
}

.tit h4 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin-left: 10px;
}

.view-project-btn {
  background: #047c7c !important;
  border-radius: 5px;
  color: #fff !important;
  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: center;
  width: 100% !important;
}

.view-project-btn:hover {
  background: #ffffff !important;
  color: #047c7c !important;
}

.tt-projectBtn-container button {
  width: 40px;
  box-shadow: 0px 4px 14px -5px rgba(0, 0, 0, 0.31);
  border-radius: 6px !important;
  color: #fff !important;
  font-family: "Roboto", sans-serif;
  z-index: 1;
  cursor: pointer;
}

.project-upvote {
  display: flex;
  flex-wrap: nowrap;
}

.project-follow {
  display: flex;
  flex-wrap: nowrap;
}


.tt-project-form-container h3 {
  color: #047c7c !important;
}

.tt-project-form-area .form-group {
  margin-top: 20px;
  margin-bottom: 20px;
}

.tt-project-form-area .form-group label {
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #2e2e2e;
}

.tt-display-projects-container {
  position: relative;
  top: 50px;
}

.create-project-btn {
  background: #309897;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  color: #fff;
  font-family: "Roboto", sans-serif !important;
}

.tt-project-form-area {
  position: relative;
  padding: 25px;
  background: #ffffff;
  box-shadow: 0px 11px 47px -4px rgba(0, 0, 0, 0.2);
  border-radius: 13px;
}

.add-image-projects {
  display: flex;
  flex-wrap: nowrap;
}

.add-image-projects label {
  flex: 1 1;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height, or 104% */
  color: #2e2e2e;
}

.add-image-projects input {
  flex: 1 1;
  margin-top: -9px;
  font-family: "Roboto", sans-serif;
}

.tt-project-form-area .form-control {
  background: #ffffff;
  border: 1px solid #c4c4c4 !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
}

.project-goal-field {
  padding-left: 25px;
  padding-right: 25px;
}

.tt-project-display-entry {
  position: relative;
  top: 25px;
}

.tt-project-display-entry button {
  color: #000;
  font-weight: 900;
  font-family: "Ubuntu", sans-serif;
  font-size: 21px;
  letter-spacing: 0.8px;
}

.tt-project-display-entry .card {
  border-radius: 10px 10px 10px 10px;
  border-bottom: 1.5px solid #c4c4c4;
  border-top: unset;
  border-left: 1.5px solid #c4c4c4;
  border-right: 1.5px solid #c4c4c4;
  min-height: 462px;
  margin-bottom: 78px;
}

.tt-project-display-entry .card-img-top {
  max-height: 300px;
  height: 240px;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
  object-fit: fill;
}

.tt-project-display-entry .card-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  padding-bottom: 15px;
}

.tt-project-display-entry .card-text {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #2e2e2e;
  opacity: 0.8;
}

.tt-project-display-entry .card a {
  width: 100%;
  font-family: "Ubuntu", sans-serif;
}

.project-details {
  margin-top: 10px;
}

.project-details .topPurposeDisplay {
  padding: 25px;
  text-align: left;
}

.project-details .tt-statement-disp {
  margin-left: 10px;
}

.project-details .top10NameDisplay {
  top: 20px;
}

.project-details .view-details {
  background: #eee;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  box-shadow: -1px 2px 18px 2px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: -1px 2px 18px 2px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: -1px 2px 18px 2px rgba(0, 0, 0, 0.51);
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 10px;
}

.objective-goals-projects .btn-link .disp-numberline {
  font-size: 20px;
}

.objective-goals-projects .btn-link {
  font-size: 20px;
  font-family: "Ubuntu", sans-serif;
  color: #695f5f;
}

.objective-goals-projects .card {
  box-shadow: -1px 8px 23px 13px rgba(217, 201, 201, 0.9);
  -webkit-box-shadow: -1px 8px 23px 13px rgba(217, 201, 201, 0.9);
  -moz-box-shadow: -1px 8px 23px 13px rgba(217, 201, 201, 0.9);
  padding: 10px;
}

.display-objectives .badge {
  margin-right: 10px;
  font-size: 18px;
  font-family: "Fjalla One", sans-serif;
}

.disp-list-value {
  font-family: "Ubuntu", sans-serif;
}

.add-image-projects .form-control {
  border: unset !important;
}

.goal-title-obj-dis {
  font-family: "Alfa Slab One", cursive;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 33px;
}

.objective-goals-projects .add-btn {
  box-shadow: -1px 2px 18px 2px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: -1px 2px 18px 2px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: -1px 2px 18px 2px rgba(0, 0, 0, 0.51);
  border-radius: 20px;
}

.project-goals-tt {
  border: 2px solid #eee;
  padding: 20px;
  margin-top: 20px;
  font-weight: 550;
  font-family: "Fjalla One", sans-serif;
  font-size: 22px;
  margin-bottom: 20px;
}

.goals-obj-dis-tt {
  margin-bottom: 20px;
}

.project-comment-form label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #2e2e2e;
  text-transform: capitalize;
}

.project-comment-form i {
  color: #e1a241;
}

.comment-count-sort {
  display: flex;
  flex-wrap: nowrap;
}

.comment-count-sort h4 {
  flex: 1 1;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  text-transform: capitalize;
}

.comment-sorting span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #2e2e2e;
  opacity: 0.75;
}

.display-comment-values .card {
  box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  border: none;
}

.idea-display {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #1d1d1d;
}

.display-comment-values .card-body h4 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #2e2e2e;
  margin-top: 20px;
}

.display-comment-values .card-body h4 span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 24px;
  line-height: 28px;
  color: #ff9b00;
}

.display-comment-values .card-body p {
  font-family: "PT Sans Narrow", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 29px;
  color: #000000;
}

/* .display-list-items {
  margin-top: 25px;
} */

.display-list-items span {
  line-height: 2;
}

.display-list-items span::first-letter {
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
}

.display-list-items .line-app {
  padding-right: 25px;
  font-weight: bold;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: 5px;
  font-size: 1.2em;
  margin-top: 10px;
  margin-left: -40px;
  position: relative;
  top: 60px;
  letter-spacing: 0px;
}

.edit-need {
  color: #047C7C;
  border: 1px solid #047C7C;
  width: 150px;
  height: 30px;
  border-radius: 2px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}

.profile-core-values .add-button-need {
  position: absolute;
  right: 50px;
}

.profile-core-values .card {
  margin-top: 10px;
}

.need-display {
  color: #1d1d1d;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.display-list-items .need-display::first-letter {
  text-transform: uppercase !important;
  font-family: "Roboto", sans-serif;
}

.purpose-needs {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #FF9B00;
  padding-right: 0px;
}

.needs-purpose-display {
  font-family: "Roboto", sans-serif;
  color: #000000;
  opacity: 0.6;
  font-size: 18px;
  margin-left: -10px;
}

.display-list-items .needs-purpose-display::first-letter {
  text-transform: uppercase !important;
  font-family: "Roboto", sans-serif;
}

.goal-title {
  color: #2E2E2E !important;
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  padding-left: 10px;
}

.down-btn-goal {
  font-size: 30px;
  color: #000;
  outline: none !important;
  font-weight: 800;
}

#goalAccordian .card {
  border: none !important;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom: 30px;
}

.objective_container {
  font-weight: 600 !important;
  font-family: "Roboto", sans-serif !important;
}

.s-project-description {
  margin-top: 50px;
}

#goalAccordian .card p {
  font-family: "Roboto", sans-serif;
  color: #000;
  font-weight: 300;
}

#goalAccordian .card .btn {
  position: relative;
  top: 10px;
}

.edit-goal-new-button {
  z-index: 0 !important;
  max-height: 40px !important;
  margin-top: 18px !important;
}

.edit-kpi {
  position: absolute !important;
  right: 0px !important;
}

.button-goal-collapse .btn:focus {
  outline: none !important;
  box-shadow: 0 0 0 0.2rem #f8f9fa !important
}

.objective_container .objective-title {
  background-color: purple;
  padding: 5px;
  color: #fff;
  border-radius: 5px;
  margin-right: 10px;
  text-transform: uppercase;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
}


.button_group {
  right: 12px;
  position: absolute;
  margin-top: -32px;
  z-index: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.button_group_goal {
  z-index: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}


.objective-bar {
  background-color: purple;
  padding: 8px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 20px;
  border-radius: 5px;
}

.objective-it-self {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;

}

.objective-card {
  display: flex !important;
}

#goalAccordian {
  margin-bottom: 10px;
}

#goalAccordian .card-body {
  border-left: 2px solid #c3bcbc;
}

#goalAccordian .card-footer {
  border-left: 2px solid #c51db4 !important;
}


.edit-project-goal {
  color: #2E2E2E;
  font-size: 15px;
}

.project-goal-display {
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 62px;
  color: #2E2E2E;
  font-family: "Roboto", sans-serif;
  margin-left: 0px;
  display: flex;

}

.goal-bar-display {
  background-color: purple;
  padding: 8px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 20px;
  border-radius: 5px;
  max-height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10px;
  font-size: 15px;
}


.objectives-display {
  margin-top: 25px;
}

.objectives-title {
  color: #2E2E2E !important;
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  padding-left: 10px;
  position: relative;
}

.down-button-support {
  position: relative;
  margin-left: 10px;
}

#loading-button-project {
  min-width: 200px;
  min-height: 50px
}

.show-the-needs {
  color: #1d1d1d;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
}

.display-sdgs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.display-sdgs img {
  height: 100px;
}

.remove-sub-project {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.remove-sub-project i {
  font-size: 30px;
  font-weight: bolder;
}

/* ==== PROJECT DISCUSSIONS ==== */
.discussion-invite-area {
  margin-top: 60px !important;
  margin-bottom: 100px !important;
}

.invitation-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px !important;
  width: 90%;
}

.invitation-title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #2E2E2E;
}

.Invitation-form {
  margin-top: 30px;
}

.discussion-display-container {
  width: 100%;
}

.discussion-card-area {
  margin-top: 20px !important;
  display: flex;
  flex-wrap: wrap;

}


.dis-profile-creator {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
}

.dis-created-by {
  margin-top: 15px;
}

.dis-created-by span {
  font-family: "Roboto", sans-serif;
  color: #1d1d1d !important;
  font-weight: 400;
  font-size: 16px;
}

.sb-like-comment-btn .fa {
  font-size: 30px;
  color: grey;
}

.modal-size {
  width: 90% !important;
  min-width: 90% !important;
  overflow-y: auto;
}

.comment-discussion-card {
  margin-top: -8px !important
}

.tt-discussion-comment-form {
  margin-top: 20px;
}

.form-control-text-area {
  border: 1px solid rgba(46, 46, 46, 0.5);
  border-radius: 8px;
  width: 100%;
}

.divide-form-replies {
  margin-top: 70px;
}

.project-comment-replies-container {
  margin-top: 10px;
}

.number-of-comments h5 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;


  color: #2E2E2E;

}

.main-comments-section {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 50px;
  margin-bottom: 50px;
}

.image-part-main img {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  flex: 1 1;
}

.diolog-section {
  flex: 2 1;
  padding-left: 30px;
}

.diolog-section p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: #2E2E2E;
  opacity: 0.6;
}

.main-reply-buttons {
  margin-top: -50px;
}

.form-control-text-area-reply {
  width: 90%;
  border: 1px solid rgba(46, 46, 46, 0.5);
  border-radius: 8px;
  margin-left: 110px;
}

.reply-card-main {
  width: 98%;
  margin-left: 30px;
}

.reply-card-main .main-comments-section {
  margin-top: 70px !important;
}

.reply-card-main .diolog-section p {
  margin-left: -20px;
}

.creation-date-mh {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.creation-date-mh span {
  font-size: 18px;
  color: green;
  font-weight: bolder;

}

.creation-date-mh h6 {
  padding: 5px;
}

.display-down {
  display: flex;
  justify-content: center;
}

.display-down i {
  font-size: 30px;
  color: #e0871c;
}

.display-project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.projects-blocks {
  text-align: center;
  display: flex;
  width: 350px;
  height: 250px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 25px;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 5px;
  margin-top: 10px;
  cursor: pointer;
  background-color: grey;
  box-shadow: -4px -1px 19px 7px rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: -4px -1px 19px 7px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: -4px -1px 19px 7px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}

.project-container-header-name {
  padding-left: 15px;
}

.projects-blocks h4 {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
}

.project-categories-header {
  color: #2E2E2E;
  font-family: "Roboto", sans-serif;
  position: relative;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 28px;
  top: 10px;
}

.project-container-header-button {
  position: relative;
  top: 10px;
}

.tit i {
  color: #047C7C;
  font-size: 35px;
  font-weight: 600;
  cursor: pointer;
}

.tit span {
  font-size: 25px;
  text-transform: capitalize;
  margin-left: 10px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}

.underline {
  margin-top: 15px;
  background: linear-gradient(170deg, #01e4f8 0%, #1d3ede 100%);
  height: 5px;
  width: 150px;
  border-radius: 20px;
}

.name-of-category {
  position: absolute;
  left: 45%;
  margin-top: 10px;
}

.name-of-category h3 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
}

/* drop down menu */

.dropdown-menu {
  min-width: 250px !important;
  min-height: 300px !important;
  left: -50px !important;
}

.dropdown-item {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 300 !important;
  font-size: 18px;
  cursor: pointer;
}

.dropdown-item i {
  padding-left: 20px;
  padding-right: 10px;
  font-size: 22px;
  font-weight: 900;
}

.fix-sizes-on-plus {
  font-size: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
}

.project-category-header-container {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgb(112 144 176 / 10%);
  height: 100px;
}

.project-dis-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.project-container-header-name {
  flex: 1 1
}

/* .display-ass-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
} */

.asss-disp {
  height: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.project-card-buttons {
  display: flex;
  position: absolute;
  bottom: 10px
}

.un-follow-btn {
  background: yellowgreen !important;
}

.follow-btn {
  background: orangered !important;
}

.upvoted_group_container {
  position: relative;
  bottom: -20px;

  left: 14px;
}

/* .MuiAvatar-root {
  width: 30px !important;
  height: 30px !important;
} */



/* <==== Project media queries ====> */

@media (min-width: 1388px) {
  .display-project-container {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }

  .card-container-display {
    padding-left: 2% !important;
    padding-right: 2% !important;
  }

}
.landing-container,
.landing-features-container,
.abt-container,
.prospect-container,
.pricing-container,
.footer-content,
.subscribe-content,
.comments-content,
.landing-navigation {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.landing-nav {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.landing-header {
  margin-top: 40px;
}

/* .navigation-item{
  width: 100px;
} */

.navigation-bar li a {
  text-align: center;
}

.nav-button {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #FF9B00;
  border-radius: 8px;
  border: none;
}

.welcome-msg {
  margin-top: auto;
  margin-bottom: auto;
}

.welcome-msg h1 {
  font-family: "Montserrat", sans-serif;
  color: #2e2e2e;
  font-weight: bold;
}

.welcome-msg p {
  color: #2e2e2e;
  margin-top: 10px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
}

.welcome-video {
  margin-top: 20px;
}

.landing-cta-btn {
  border: unset;
  background: #ec9528;
  box-shadow: 0px 4px 14px -5px rgba(0, 0, 0, 0.31);
  border-radius: 6px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-top: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 35px;
  padding-left: 35px;
}

.together-bold {
  font-weight: 800;
}

.landing-cta-btn-sm {
  border: unset;
  background: #ec9528;
  box-shadow: 0px 4px 14px -5px rgba(0, 0, 0, 0.31);
  border-radius: 6px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-top: 20px;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-right: 100px;
  padding-left: 100px;
}

.centre-button-landing {
  display: flex;
  justify-content: center;
}

.embed-responsive {
  max-height: 423px;
  border-radius: 6px;
}

.landing-features-content {
  margin-bottom: 80px;
  margin-top: 100px;
}

.landing-features {
  text-align: center;
}

.landing-features p,
.landing-features h5 {
  font-family: "Montserrat", sans-serif;
}

.abt-container,
.prospect-container,
.pricing-container {
  margin-bottom: 100px;
  margin-top: 50px;
}

.abt-img {
  width: 755px;
  height: 573px;
  border-radius: 8px;
}

.abt-text {
  margin-top: 15px;
}

.abt-card-text p,
.abt-card-text h5 .prospect-text p,
.prospect-text h5,
.pricing8,
.contact-content {
  font-family: "Montserrat", sans-serif;
}

.abt-card-header {
  border: none;
  background-color: white;
}

.prospect-text {
  margin-top: auto;
  margin-bottom: auto;
}

.prospect-img {
  width: 739px;
  max-height: 573px;
}

.prospect-card-text {
  max-width: 755px;
}

/* ul {
  list-style-type: none;
} */

.pricing8 h1,
.pricing8 h2,
.pricing8 h3,
.pricing8 h4,
.pricing8 h5,
.pricing8 h6 {
  color: #3e4555;
}

.prospect-12 {
  color: #000 !important;
  font-size: 20px !important;
}

.marketplace-anchor a {
  color: #000 !important;
  cursor: pointer;
}

.marketplace-anchor a:hover {
  text-decoration: unset;
}

.pricing8 h5 {
  line-height: 22px;
  font-size: 18px;
}

.pricing8 .subtitle {
  line-height: 24px;
}

.pricing8 .display-5 {
  font-size: 3rem;
}

.pricing8 .font-14 {
  font-size: 14px;
}

.pricing-box {
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
}

.pricing8 .pricing-box sup {
  top: -20px;
  font-size: 16px;
}

.slide {
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}

.activeSlide {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.comments-content {
  padding-bottom: 60px;
}

.comments-container {
  background-color: #f4f5f7;
}

.comment-header {
  padding-top: 50px;
  padding-bottom: 40px;
}

.comment-header hr {
  width: 70px;
  border: 2px solid #FF9B00
}

.comment-card {
  width: 300px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

.comment-img {
  border-radius: 50%;
  width: 118px;
  height: 114px;
  margin-left: auto;
  margin-right: auto;
}

.contact-container {
  background-color: #f4f5f7;
  padding-bottom: 80px;
}

.contact-content {
  width: 55%;
  margin-left: auto;
  margin-right: auto;
}

.subscribe-img {
  width: 300px;
}

.subscribe-title {
  width: 650px;
  margin-top: auto;
  margin-bottom: auto;
}

.subscribe-input {
  border-radius: 8px 0px 0px 8px;
  height: 60px;
}

.subscribe-btn {
  background: #ff9b00;
  border-radius: 8px 0px 0px 8px;
  padding-right: 50px;
  padding-left: 50px;
}

.subscribe-main {
  padding-top: 50px;
  padding-bottom: 50px;
}

.Footer-container {
  background-color: #252b42;
}

.footer-content {
  color: whitesmoke;
}

.footer-logo {
  width: 33px;
}

.footer-group {
  padding-top: 100px;
}

.footer-group-one p {
  width: 300px;
}

.footer-group-two ul,
.footer-group-three ul,
.footer-group-four ul {
  list-style-type: none;
  padding: 0;
}

.footer-content hr {
  border: 1px solid #303e74;
}

.landing-features img {
  height: 100px;
}

@media (max-device-width: 991px) {
  .landing-nav-img {
    margin-left: 10px;
  }

  .welcome-msg-content h1 {
    margin-left: auto;
    margin-right: auto;
    line-height: 43px;
    text-align: center;
  }

  .welcome-msg-content p {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

@media (min-device-width: 1200px) {
  .welcome-msg h1 {
    font-size: 50px;
  }
}

@media (max-device-width: 576px) {
  .landing-cta-btn-sm {
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 45px;
    padding-left: 45px;
  }
}

@media (min-device-width: 992px) and (max-device-width: 1200px) {
  .welcome-msg h1 {
    font-family: "Roboto", sans-serif;
    font-size: 40px;
  }
}

@media (min-device-width: 768px) and (max-device-width: 991px) {}
.categories-body{
  width: 100%;
}

#categories-title {
  position: relative;
  width: 80%;
  left: 27px;
  bottom: 20px;
}

#categories-title h4 {
  font-size: 2rem;
  font-family: 'Fjalla One', sans-serif;
  margin-left: 0;
  margin-bottom: 0;
}

.category {
  padding: 30px 0 40px;
  margin-bottom: 140px;
  max-width: 248px;
  min-width: 248px;
  background-color: #ffffff;
  text-align: center;
  overflow: hidden;
  position: relative;
  height: 293px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 5px 8px 13px -13px rgba(0,0,0,0.75);
  background: linear-gradient(to left bottom, #ff9b00 48%,#212529 50%);
  color: white;
}

.category .picture {
  display: inline-block;
  height: 130px;
  width: 130px;
  margin-bottom: 50px;
  z-index: 1;
  position: relative;
  object-fit: cover;
}

.category .picture::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 40%;
  background-color: #f3c02f40;
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.9;
  -webkit-transform: scale(3);
          transform: scale(3);
  transition: all 0.3s linear 0s;
}

.category:hover .picture::before {
  height: 100%;
}

.category .picture::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #f3c02f99;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.category .picture img {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: all 0.9s ease 0s;
  object-fit: cover;
  background-color: #fff;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.category:hover .picture img {
  box-shadow: 0 0 0 14px #ffffff;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}

.category .title {
  display: block;
  font-size: 15px;
  color: #4e5052;
  text-transform: capitalize;
}

.category-content {
  position: relative;
  bottom: 41px;
}

.category-content h3 {
  font-family: 'PT Sans Narrow', sans-serif;
}

.category-content h4 {
  font-family: 'Ubuntu', sans-serif;
}

.picture-bottom {
  width: 100%;
  margin-left: 4px;
  bottom: 17px;
  margin-top: 0;
  position: relative;
}

.view {
  color: #6a6a6a;
  cursor: pointer;
}

.view .btn {
  background-color: #f3c02f;
  font-size: 12px;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 600;
  box-shadow: -4px 2px 10px -5px rgba(0,0,0,0.75);
  border-radius: 30px 30px 30px 30px;
  height: 25px;
  padding-top: 3px;
  padding-left: 7px;
  margin-bottom: 6px;
  padding-right: 13px;
  color: #10050591;
}

.view:before,
.view:after{
  content:" ";
  width: 20%;
  height: 1px;
  margin: 0 10px;
  vertical-align: super;
  background-color:#80808087;
  display:inline-block; 
}

*:focus {
    outline: none;
}

.appReusableCard_main_container__1ibNh {
    min-width: 450px;
    max-width: 450px;
    position: relative;
    margin-bottom: 10px;
    min-height: 730px;
}

.appReusableCard_buttons_container__2Slui {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
}

.appReusableCard_item_height__2nOca {
    min-height: 140px;
    margin-bottom: 15px;
}

.appReusableCard_buttons_container__2Slui button {
    margin-top: 18px;
    margin-bottom: 18px;
}

.appReusableCard_buttons_container__2Slui span {
    font-size: 12.8px !important;
    font-weight: 600;
}

/* ========= MOBILE DESIGN ===== */
@media (max-width: 801px) {
    .appReusableCard_main_container__1ibNh {
        min-width: unset !important;
        max-width: unset !important;
        margin-top: 20px !important;
    }

    .appReusableCard_buttons_container__2Slui>button {
        margin-bottom: -25px !important;
        margin-top: 20px !important;
    }

    .appReusableCard_love_button__1DYEP {
        margin-top: 40px !important;
    }

}
.Suggestion_suggestion_container__1HrFu {
    display: flex;
    height: 100vh;

    min-height: 100%;
}



.Suggestion_suggestion_categories__2YkJn {
    width: 15%;
    position: sticky;
    top: 0;
    z-index: 0;
    margin-bottom: 40px;

}

.Suggestion_suggestions_data__1xFyG {
    flex: 1 1;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    padding: 5px;

}
.sidebar_side_bar_container__1Uqsa {
    overflow-y: scroll;
    min-height: 100vh;
    height: 100vh;

}

.sidebar_sidebar_top_bar__38ypm {
    width: 100%;
    min-height: 170px;
    background-color: #eee;
}

.sidebar_close_icon__1hkob {
    font-weight: 800;
    position: relative;
    top: 10px;
    text-align: end;
    margin-bottom: 20px;
    right: 15px;
    cursor: pointer;
}

.sidebar_active_tab__63hAp {
    background-color: purple;
    color: #fff;
}

.sidebar_side_bar_divider__2GSz3 {
    background-color: white;
    color: white;
}

.sidebar_project_creator__2JJPF {
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.sidebar_avatar_project_creator__1gAqT {
    align-items: center;
    left: 40%;
}

.sidebar_project_creator__2JJPF h6 {
    text-transform: uppercase;
    font-weight: 800;
}

.sidebar_project_creator__2JJPF span {
    text-transform: uppercase;
    font-weight: 800;
    color: purple;
}

.sidebar_creator_avator__3Rxqf {
    display: flex;
    justify-content: center;
}
.TopProjectBar_upvote_favorite_container__3V0QF {
    display: flex;
    flex-wrap: wrap;
}

.TopProjectBar_bread_navigation__12zJ1 {
    margin-top: 6px;
}

.TopProjectBar_upvote_container__TKk5g {
    display: flex;
    flex-wrap: nowrap;
    justify-items: flex-start;
}

.TopProjectBar_upvote_container__TKk5g span {
    margin-left: 10px;
    text-transform: lowercase;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}

.TopProjectBar_top_bar_container__1qBqf {
    width: 100% !important;
}

.TopProjectBar_upvote_items__1pHWH {
    margin-top: 0px;
}

.TopProjectBar_upvoted_by_container__3VndO {
    margin-left: 10px;
    cursor: pointer;
}

.TopProjectBar_favorite_container__zk5Rj {
    display: flex;
    flex-wrap: nowrap;
}

.TopProjectBar_favorite_icon__3bYFq {
    margin-top: -1px;
    cursor: pointer;
}

.TopProjectBar_favorite_container_items__tKaYK {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 5px;
}

.TopProjectBar_favorite_container_items__tKaYK span {
    margin-left: 10px;
    text-transform: capitalize;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    margin-top: 5px;
}

.TopProjectBar_jumbotron_container__4nEFk {
    margin-top: 10px;
    width: 100%;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -moz-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
}

.TopProjectBar_actual_jumbotron_area__MEHfU {
    border-radius: 3px;

}

.TopProjectBar_tab_selection_display__1Erh7 {
    position: relative;
    bottom: -60px;
}

.TopProjectBar_jumbotron_container__4nEFk .TopProjectBar_jumbotron__1s5sV {
    text-align: unset !important;
}

.TopProjectBar_bread_project_name_one__2R47T {
    background-color: #eee !important;
    color: #000 !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}


.TopProjectBar_bread_project_name__1uvbL {
    background-color: green !important;
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}

.TopProjectBar_bread_project_name_active__Ehlww {
    background-color: purple !important;
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}

.TopProjectBar_project_image_container__1th9v {
    position: relative;
    top: -62px;
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    height: 38px;
    padding: 8px;
}

.TopProjectBar_project_image_container__1th9v .TopProjectBar_project_image__37oul {
    box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.75);
}

.TopProjectBar_project_name_display__2xxZb {
    margin-left: 12px;
    font-size: 20px;
    font-weight: 700;
    color: purple;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    margin-left: 5px;
    position: relative;
}

.TopProjectBar_tab_selection_display__1Erh7 {
    position: relative;
}

.TopProjectBar_timeline_container__pbE-Z {
    width: 100%;
    border: 1px solid #E5EAF2;
    border-radius: 3px;
    min-height: 342px;
    background: #eee;
    overflow-y: visible;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -moz-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    max-width: 400px;
    position: relative;

}

.TopProjectBar_timeline_top_bar__7onoQ {
    background-color: #fff;
    height: 45px;
    align-items: center;
    display: flex;
    padding-left: 20px;
}

.TopProjectBar_timeline_top_bar__7onoQ h6 {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    margin: 5px;
    color: purple;
}

.TopProjectBar_time_line_button_bar__3sxAq {
    background-color: #fff;
    height: 45px;
    align-items: center;
    display: flex;
    padding-left: 20px;
    bottom: 33px;
    justify-content: center;
}

.TopProjectBar_selection_display__1EDSt {
    position: relative;
    bottom: -50px;
}

/* ==== MOBILE RESPONSIVENESS ==== */
@media (max-width: 801px) {
    .TopProjectBar_bread_navigation__12zJ1 ol {
        display: block !important;
    }

    .TopProjectBar_collaborators_container__t6y0U {
        margin-top: 8px !important;
        margin-bottom: 15px !important;
        border-top: 1px solid #eee !important;
        padding-top: 10px !important;
    }


    .TopProjectBar_project_image_container__1th9v {
        top: -32px !important;
    }

    .TopProjectBar_jumbotron_container__4nEFk {
        margin-top: 0px !important;
        margin-bottom: -30px !important;
    }

}
.CommentBox_scroll_area__2dd2u {
    overflow-y: scroll !important;
    margin-bottom: 200px;
}

.CommentBox_comment_box_area_cont__1oGY4 {
    display: flex;
}

.CommentBox_comment_content_area__3aAxk {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}


.CommentBox_profile_container_images__3Pno7 {
    flex: 1 1;
}


.CommentBox_main_comment_container__n8uqy {
    flex: 12 1;
}


.CommentBox_comment_box_area_box__PfxUa {
    padding: 2px;
    width: 100%;

}

.CommentBox_tool_bar_container_box__Wp9xI {
    min-height: 0px !important;
}

.CommentBox_each_comment_container__3bjFS {
    display: flex;
    flex-wrap: nowrap;
    background-color: #E8E8E8;
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #000;
}

/* ==== Comments top bar ==== */
.CommentBox_comments_number_over_view__1B2R4 {
    padding: 20px;
    color: rgb(85, 82, 82);
    font-weight: 500;
    text-align: center;
}

.CommentBox_comments_number_over_view__1B2R4>span {
    text-transform: capitalize;
    font-size: 18px;
    margin-left: 10px;
}

.CommentBox_module_overview_container__1HxPH {
    min-height: 300px;

}

.CommentBox_heading_title__30Qmw {
    padding: 10px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    color: purple;
    width: 100%;
    min-width: 100%;
    text-align: center;
    box-shadow: -2px 1px 26px -1px rgba(150, 150, 150, 0.8);
    -webkit-box-shadow: -2px 1px 26px -1px rgba(150, 150, 150, 0.8);
    -moz-box-shadow: -2px 1px 26px -1px rgba(150, 150, 150, 0.8);
}



.CommentBox_heading_title__30Qmw>h4 {
    font-weight: 800;
    margin-bottom: -15px;
    font-size: 1.3rem !important;
}

.CommentBox_label_title__27-Gd {
    position: relative;
    font-size: 20px !important;
    margin-top: 10px;
    left: 10px;
    color: green;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 800;
}

.CommentBox_details_container__7Vv2X {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px;
}

.CommentBox_details_label_container__gV1pw {
    display: flex;
    flex-wrap: nowrap;
}

.CommentBox_actual_details__35sIZ {
    position: relative;
    top: 8px;
    left: 20px;
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
}

/* === comments top bar ==== */

.CommentBox_comment_content_container__2K9DA {
    margin-left: 10px;
    position: relative;
    width: 100%;

}

.CommentBox_date_and_action_items__1wVEE {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 3px;
}

.CommentBox_comment_details__SKVww {
    padding: 0px;
    border-radius: 10px;
    width: 100%;
}

.CommentBox_creator_name__1j41F {
    font-weight: 900;
    font-family: "Roboto", sans-serif;
}

.CommentBox_creator_date__3eH9E {
    font-weight: 900;
    font-family: "Roboto", sans-serif;
}

.CommentBox_comment_details__SKVww {
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-weight: 400;
}

.CommentBox_action_items_container__3rrpP {
    border: 1px solid #E8E8E8;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    position: relative;
    top: -32px;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.CommentBox_action_items_buttons__3nuD6 {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.CommentBox_comment_box_title_bar__1HVnh {
    display: grid;
    width: 100%;
    min-height: 200px;
    margin-bottom: 10px;
    border: 1.5px solid #E8E8E8;
    margin-top: 10px;
    border-radius: 6px;
    overflow-y: scroll;
    box-shadow: -3px 1px 23px 0px rgba(0, 0, 0, 0.26) !important;
    -webkit-box-shadow: -3px 1px 23px 0px rgba(0, 0, 0, 0.26) !important;
    -moz-box-shadow: -3px 1px 23px 0px rgba(0, 0, 0, 0.26) !important;
    max-height: 300px;
}

.CommentBox_comment_box_title_label__3dqSB {
    width: 200px;
    margin-left: 10px;
    margin-top: 10px;
    background: green;
    height: 35px;
    color: #fff;
    text-align: center;
    padding: 6px;
    border-radius: 5px;
    font-weight: 800;
    text-transform: uppercase;

}

.CommentBox_comment_box_title_label_details__ZHVF7 {
    margin-left: 10px;
    margin-top: 10px;
    color: #000;
}

.CommentBox_actions_buttons_responses__3x01N {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: -2px 1px 61px -15px rgb(150 141 141 / 75%);
    margin-bottom: 25px;

}

.CommentBox_creator_date_reponses__1YGXK {
    padding-left: 20px;
    font-weight: 900;
    font-family: "Roboto", sans-serif;
}

.CommentBox_responses_container__3O2CQ {
    border: 1px solid #a29999;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
    box-shadow: -2px 1px 61px -15px rgb(150 141 141 / 75%);
    margin-top: 25px;
    background: #fff;
}

.CommentBox_display_of_upvoted_profiles__3ynRN {
    display: flex;
    flex-wrap: nowrap;
}

.CommentBox_avators__3Csn3 {
    padding-top: 7px;
}

.CommentBox_each_comment_container_reponses__2UJRK {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 30px;
    background-color: #fff;
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.CommentBox_response_action_edit_items__2Louz {
    display: flex;
    flex-wrap: nowrap;
}

.CommentBox_alert_displayed_profiles__lciOU {
    min-height: 50px;
}

.CommentBox_upvoted_alert_header__32ihD {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
}

.CommentBox_close_icon_button__qI63h {
    position: absolute;
    right: 35px;
    margin-top: -45px;
    cursor: pointer;
}

.CommentBox_new_comment_reply_label__QxAin {

    color: green;
    text-transform: uppercase;
    font-weight: 800;
    text-decoration: underline;
}

/* ==== MOBILE RESPONSIVENESS ==== */
@media (max-width: 801px) {

    .CommentBox_responses_container__3O2CQ {
        margin-left: -32px;
    }

    .CommentBox_actions_buttons_responses__3x01N {
        margin-left: -32px;
        width: 100%;
    }

}
.ProjectContainer_MobileDisplay__3hQJV {
    display: none;
}

.ProjectContainer_sticky_sec__26hpy {
    position: sticky;
    top: 0;
    z-index: 0;
    margin-bottom: 40px;
}

.ProjectContainer_categories_container__1dm46 {
    margin-top: 40px;
    background: #FFFFFF;
    box-shadow: 0px 5px 14px -3px rgba(0, 0, 0, 0.11);
}

.ProjectContainer_card_header__3RfQ1 {
    font-weight: 800 !important;
    font-weight: 700;
    color: #222222;
}

.ProjectContainer_img_thumbnail__3Nn2H {
    height: 40px;
    width: 40px;
    border: 1px solid #807e7e;
    padding: 4px;
}

.ProjectContainer_category_name__3gl4j {
    margin-left: 10px;
    font-weight: 600;
    font-family: 'Fjalla One', sans-serif;
    font-size: 20px;

}

.ProjectContainer_category_count__2jKlZ {
    position: absolute;
    right: 5px;
    margin-top: 2px;
    font-weight: 800;
}

.ProjectContainer_category_item_container__3Zc5a {
    padding: 10px;
    cursor: pointer;
    width: 100%;
}

.ProjectContainer_category_item_active__BrdR2 {
    padding: 10px;
    cursor: pointer;
    width: 100%;
    background-color: purple;
    color: white;
}

.ProjectContainer_category_item_container__3Zc5a :hover {
    cursor: pointer;
    background-color: purple;
    color: white;

}

.ProjectContainer_projects_container__2KTPs {
    margin-top: 40px;
}

.ProjectContainer_pinned_projects_container__n4zPS {
    margin-top: 40px;
}

.ProjectContainer_search_bar_area__3dTbX {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
}

.ProjectContainer_create_new_project_button__3VaB2 {
    margin-left: 20px;
}

.ProjectContainer_project_item__3kGtg {
    margin-top: 20px;
    margin-bottom: 10px;
}

.ProjectContainer_pinned_container__dHBNT {
    margin-top: 10px;
    width: 100%;
    border: 2px solid purple;
    min-height: 80px;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


/* === MOBILE AND RESPONSIVENESS === */

@media (max-width: 801px) {
    .ProjectContainer_MobileDisplay__3hQJV {
        display: inline;
    }

    .ProjectContainer_desktopDisplay__-wOpZ {
        display: none;
    }

}
.css_button_card_container__dyZUG {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
    background-color: #eee;
    box-shadow: -1px 0px 16px 0px rgba(0, 0, 0, 0.37);
    -webkit-box-shadow: -1px 0px 16px 0px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: -1px 0px 16px 0px rgba(0, 0, 0, 0.37);
    align-items: center;
    justify-content: center;
}

.css_image_list_item__1N7s8 {
    box-shadow: -5px 1px 19px -2px rgba(148, 135, 135, 0.75);
    -webkit-box-shadow: -5px 1px 19px -2px rgba(148, 135, 135, 0.75);
    -moz-box-shadow: -5px 1px 19px -2px rgba(148, 135, 135, 0.75);
    object-fit: cover;
    padding: 8px;
    border-radius: 10px;
}


.css_input__2bgvF {
    display: none;
}
.singleProjectNetwork_container__x1asZ {
    width: 100%;
    border: 1px solid #E5EAF2;
    border-radius: 3px;
    min-height: 180px;
    background: #eee;
    overflow-y: visible;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -moz-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    max-width: 400px;
    position: relative;
    min-width: 400px;
    margin-bottom: 50px;
}

.singleProjectNetwork_container_header__14k8m {
    background-color: #fff;
    height: 45px;
    align-items: center;
    display: flex;
    padding-left: 20px;
}

.singleProjectNetwork_container_header__14k8m>h6 {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    margin: 5px;
    color: purple;
}

.singleProjectNetwork_container_footer__XVJJE {
    background-color: #fff;
    height: 45px;
    align-items: center;
    display: flex;
    padding-left: 20px;
    bottom: 33px;
    justify-content: center;
    position: absolute;
    bottom: 0px;
    width: 100%;
}
.shareDiscussionModal_main_title_area__1ENyF {
    width: 100%;
    background-color: #eee;
    text-align: center;
    color: black;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    font-weight: 400;
}

.shareDiscussionModal_close_icon__3q9Ow {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
}

.shareDiscussionModal_main_content_area__iORD3{
    min-height: 300px;
}

.shareDiscussionModal_main_navigation_container__2Fr0G{
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.shareDiscussionModal_main_content_display_area__3QuOP{
    margin-top: 20px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.shareDiscussionModal_main_content_display_area__3QuOP .shareDiscussionModal_invitation-card__39fAc{
    max-width: 400px !important;
    width: 400px !important;
    min-width: 400px !important
}

.shareDiscussionModal_profile_display_container_card__LdFz9{
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
}

.shareDiscussionModal_profile_display_container_card_content_area__C_FyL{
    overflow-y: scroll;
}

.shareDiscussionModal_list_item_list__1L34O{
    position: relative;
     top: 13px;
     font-family: 'Roboto Condensed', sans-serif !important;
     font-weight: 700;
}

.shareDiscussionModal_check_box__3YKit{
    position: relative;
    top: 18px;
}

.shareDiscussionModal_send_action_container__yrLIR{
    position: absolute;
    bottom: 2px;

    margin-top: 10px;
}
.project-top-ten-main {
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 60px;
}

.title-bac-ground-10 {
  background-color: #000000;
  width: 100%;
  height: 50px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
}

.title-bac-ground-10 h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
}

.project-tt-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* ==== DISPLAY BANNER ==== */
.display-top-10-banner {
  width: 100%;
  background: #eee;
  min-height: 200px !important;
  height: 200px !important;
  box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  border-radius: 3px;
  overflow-y: scroll;
}

.back-to-t0p-10s {
  position: relative;
  top: -20px;
}

.edit-top-entrty-btn {
  z-index: 0;
}

.banner-header-top10 {
  width: 100%;
  min-height: 50px;
  height: 50px;
  background-color: #fff;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
}

.entry-add-button {
  position: absolute !important;
  right: 30px !important;
}

.top-10-actual-description {
  color: #2e2e2e;
  font-size: 18px;
  font-weight: 300;
  padding: 8px;
}

.top-10-decription-title {
  color: purple;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  margin-left: 10px;
  position: relative;
  top: 1px;
  text-transform: uppercase;
}

.top-10-name-display {
  color: #000 !important;
  font-weight: 900 !important;
  text-decoration: underline;
  font-family: 'Anek Telugu', sans-serif !important;
  font-size: 1.4rem !important;
  top: 3px !important;
  position: relative;
  text-transform: uppercase;
  margin-left: 10px;
}



.project-tt-header button:hover {
  border: 1px solid #309897;
  background: #F7F7F7;
  color: #309897;
}

.project-top-ten-card {
  width: 400px;
  margin: 16px;
  height: 450px;
  background: #f4f2f2;
  border-radius: 8px;
}

.project-top-ten-card-header {
  height: 80px;
  background: #2a2e43;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.project-top-ten-card-header-text {
  position: relative;
  top: 25px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}

.header-split {
  position: relative;
  bottom: 19px;
  height: 5px;
  background: rgb(102, 94, 255);
  background: linear-gradient(141deg,
      rgba(102, 94, 255, 1) 0%,
      rgba(87, 115, 255, 1) 23%,
      rgba(52, 151, 253, 1) 66%,
      rgba(58, 204, 225, 1) 87%,
      rgba(58, 204, 225, 1) 100%);
}

.project-top-ten-card-list {
  padding: 8px;
  position: relative;
  display: flex;
  flex-direction: row;
  bottom: 35px;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
}

.card-list-item {
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  font-size: 1.12rem;
  line-height: 2.5;
  font-family: "Roboto", sans-serif;
  color: #000000;
}

.project-top-ten-card-buttons {
  position: relative;
  bottom: 30px;
  display: flex;
  width: 100%;
}

.project-top-ten-card-buttons button {
  margin: 6px;
  height: 40px;
  color: #f4f2f2;
  border-radius: 4px;
  border: unset;
}

.ptt-exp-btn {
  width: 70%;
  background: #309897;
}

.ptt-exp-btn:hover {
  border: 1px solid #309897;
  color: #309897;
  background: #ffffff;
}

.ptt-edit-btn {
  width: 30%;
  color: #f4f2f2;
  background: #5590d9;
}

.ptt-edit-btn:hover {
  border: 1px solid #5590d9;
  color: #5590d9;
  background: #f4f2f2;
}

.project-tt-entry-card {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d5d0d0;
}

.project-tt-entry-card-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  padding: 10px;
  flex-wrap: nowrap;
}

.project-entry-name-tt {
  flex: 2 1;
}

.project-tt-entry-card-header-idx {
  background: #309897;
  border-radius: 4px;
  color: #fff;
  padding: 5px 15px 5px 15px;
  margin-right: 15px;
}

.project-tt-entry-card-details {
  width: 100%;
  padding: 8px;
  height: 0;
  -webkit-animation-name: details-show;
          animation-name: details-show;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.extend-c {
  height: 250px !important;
}

@-webkit-keyframes details-show {
  0% {
    height: 0;
  }

  100% {
    height: 164px;
  }
}

@keyframes details-show {
  0% {
    height: 0;
  }

  100% {
    height: 164px;
  }
}

.p-ttdet-btn {
  border: unset;
  border-radius: 17px;
  background: #C4C4C4;
  color: #fff;
  height: 34px;
  flex: 1 1;
}

.p-ttdet-btn:hover {
  background: #309897;
}

.project-tt-entry-card-details-info {
  width: 95%;
  height: 144px;
  background: #F7F7F7;
  margin-left: auto;
  margin-right: auto;
}

.extend {
  height: 200px !important;
}

.project-tt-entry-card-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1 1;
}

.project-tt-entry-card-btns button {
  font-size: 20px;
  background: unset;
  border: unset;
  color: #9d9898;
}

.ptt-e-creator {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 800;
}

.pp-t-users {
  position: relative;
  bottom: 22px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: scroll;
  width: 250px;
}

.value-title {
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  font-size: 16px;
}

.value-display-container {
  padding: 14px;
}

.entry-values-tt {
  color: #1d1d1d;
  font-family: "Roboto", sans-serif;
  color: #000000;
  opacity: 0.6;
  font-size: 18px;
  padding-left: 10px;
}

/* ==== Media queries ==== */

@media (max-width: 600px) {
  .project-tt-header {
    margin-top: 80px;
  }
}

/* ========= MOBILE DESIGN ===== */
@media (max-width: 801px) {
  .entry-add-button {
    top: 66px;
    margin-top: unset;
    left: 2px;
    position: absolute;
    right: 2px !important;
  }

  .display-top-10-banner {
    margin-top: 12px;
  }

  .banner-header-top10 {
    flex-wrap: wrap;
    margin-top: 10px;
    height: unset !important;
  }

  .project-tt-entry-card {
    margin-bottom: 50px;
  }

  .project-tt-entry-card-header {
    display: contents !important;
  }

  .project-entry-name-tt {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .project-tt-entry-card-btns {
    margin-bottom: 7px;
    border-top: 1px solid #eee;
    padding-top: 10px;
  }
}
.project-sb-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project-sb-header button {
  position: relative;
  top: 15px;
  right: 25px;
  height: 30px;
  border: unset;
  background: #309897;
  color: #fff;
  font-family: "Roboto", sans-serif;
  border-radius: 6px;
}

.project-sb-header button:hover {
  border: 1px solid #309897;
  background: #F7F7F7;
  color: #309897;
}

.project-sug-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 6px;
}

.project-sug-body {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
}

.project-sug-extras {
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100%;
  padding: 8px;
}

.project-sug-card-fix {
  width: 49% !important;
}

.project-suggestions-images {
  display: flex;
  justify-content: center;
}

.project-suggestions-images img {
  height: 100px !important;
}

.sb-banner {
  width: 100%;
  height: 250px;
  padding: 6px;
}

.sb-banner img {
  width: 100%;
  height: 280px;
  border-radius: 6px;
}

.project-sb-card {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 356px;
  padding: 16px;
  margin-top: 20px;
  border-radius: 16px;
  background: #fffcfc;
  flex-wrap: wrap;
}

.project-sb-card-head {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40%;
}

.p-sb-left {
  width: 10%;
  height: 100%;
  padding: 25px 20px 0 20px;
}

.p-sb-mid {
  width: 75%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.p-sg-title {
  display: flex;
  flex-direction: row;
}

.p-sg-title-div {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
}

.p-sg-title span {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  line-height: 40px;
}

.p-sg-title img, .psb-idea-icon {
  width: 30px;
  height: 30px;
}

.p-sg-sub-title span {
  position: relative;
  left: 30px;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 21px;
}

.p-sg-tags {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  padding-top: 20px
}

.p-sb-right {
  width: 15%;
  height: 100%;
}

.p-sb-right span {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: italic;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: right;
  color: rgb(57, 56, 56);
}

.project-sb-card-details {
  width: 100%;
  height: 45%;
  overflow-y: scroll;
}

.project-sb-card-details p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 12.5%;
}

.project-sb-card-footer {
  width: 100%;
  height: 15%;
}

.project-sb-card-footer-btns {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-left: 12.5%;
  padding-right: 12.5%;
  justify-content: space-between;
}

.project-sb-card-footer-btns button {
  font-family: "Roboto", sans-serif;
  border: unset;
  background: unset;
  font-size: 20px;
  cursor: pointer;
}

.project-sb-card-footer-btns button span {
  font-size: 16px;
  font-weight: 200;
  font-style: italic;
  color: #8e8b8b;
}

.project-sb-comments {
  position: relative;
  bottom: 14px;
  width: 100%;
  height: 600px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background: #fffcfc;
  z-index: 1;
  -webkit-animation-name: comments-show;
          animation-name: comments-show;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  overflow-y: scroll;
}

@-webkit-keyframes comments-show {
  0% {
    height: 0;
  }

  100% {
    height: 600px;
  }
}

@keyframes comments-show {
  0% {
    height: 0;
  }

  100% {
    height: 600px;
  }
}

.project-sb-comment-form {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

.project-sb-comment-form input {
  font-style: italic;
  border-radius: 18px !important;
}

.project-sb-comment-form button {
  position: relative;
  top: 1px;
  height: 38px;
  margin-left: 10px;
  border: unset;
  background: #309897;
  color: #fff;
  font-family: "Roboto", sans-serif;
  border-radius: 18px;
}

.p-sb-br {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.p-sb-comment-entry {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  width: 100%;
  height: 170px;
}

.p-sb-comment-entry-top {
  display: flex;
  flex-direction: row;
  height: 120px;
}

.p-sb-comment-entry-top-left {
  width: 10%;
  height: 100%;
  padding: 10px 0 0 40px;
}

.p-sb-comment-entry-top-center {
  width: 80%;
  height: 100%;
  overflow-y: scroll;
  padding: 8px;
}

.p-sb-comment-entry-top-right {
  width: 10%;
  height: 100%;
  text-align: right;
  padding-right: 6px;
}

.p-sb-comment-entry-top-right span {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
}

.p-sb-comment-entry-btns {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-left: 14.5%;
  padding-right: 14.5%;
  justify-content: space-around;
}

.p-sb-comment-entry-btns button {
  font-family: "Roboto", sans-serif;
  border: unset;
  background: unset;
  font-size: 18px;
  cursor: pointer;
}

.p-sb-comment-entry-btns span {
  font-size: 14px;
  font-weight: 200;
  font-style: italic;
  color: #8e8b8b;
}

/*==MEDIA QUERIES==*/

@media only screen and (max-width: 1110px) {
  .p-sg-title span {
    font-size: 18px;
    line-height: 20px;
    padding-left: 8px;
  }

  .p-sg-sub-title span {
    font-size: 17px;
  }

  .p-sg-title img, .psb-idea-icon {
    width: 20px;
    height: 20px;
  }

  .p-sg-tags {
    padding-top: 10px;
  }

  .p-sb-comment-entry-top-left {
    padding: 10px 0 0 20px;
  }

  .p-sb-comment-entry-top-center {
    width: 70%;
  }

  .p-sb-comment-entry-top-right {
    width: 20%;
  }

  .p-sb-comment-entry-top-right span {
    line-height: 10px;
    font-size: 12px;
  }

  .sb-banner, .sb-banner img {
    height: 200px;
  }

}

@media only screen and (max-width: 770px) {
  .p-sb-comment-entry-top-left {
    padding: 5px 0 0 10px;
  }

  .project-sug-body {
    width: 100%;
  }

  .project-sug-extras {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .project-sb-header h2 {
    font-size: 24px;
    font-weight: 800;
  }
}

@media only screen and (max-width: 600px) {
  .project-sb-header {
    margin-top: 88px !important;
  }
}
.swot-jumbo {
    min-height: 70px !important;
    background-color: #eee !important;
    height: 160px;
    background: linear-gradient(170deg, #01e4f8 0%, #1d3ede 100%);
    color: #fff;
}

.tabs-app-bar {
    background-color: #eee !important;
    border-radius: 20px !important;
}

.tabs-items-swot {
    font-weight: 800 !important;
    font-size: 16px !important;
    font-family: "Roboto", sans-serif !important;
}

.what_is_swot {
    position: relative;
    top: 20px;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

.swot-name-container h4 {
    color: #000;
    font-family: "Roboto", sans-serif;
    position: relative;
    top: 20px;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.underline {
    margin-top: 15px;
    background: linear-gradient(170deg, #01e4f8 0%, #1d3ede 100%);
    height: 5px;
    width: 150px;
    border-radius: 20px;
}

.more-details-button {
    display: flex;
    justify-content: right;
}

.more-details-button .fa {
    align-self: flex-start;
    font-size: 38px;
    position: relative;
    cursor: pointer;
    font-weight: 900;
    top: -30px;
}

.more-details-button span {
    cursor: pointer;
    font-weight: 900;
    top: -22px;
    position: relative;
    margin-right: 15px;
}

.create-new-swort {
    display: flex;
    flex-wrap: nowrap;
}

.create-new-swort .learn-button {
    flex: 1 1;
    color: #000;
    font-family: "Roboto", sans-serif;
    position: relative;
    top: 10px;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.create-new-swort .fa {
    font-size: 38px;
    cursor: pointer;
    font-weight: 900;
    position: relative;
    top: 5px;
    margin-left: 10px;
}

.not-found-statement {
    display: flex;
    justify-content: center;
    align-items: center;
}

.not-found-statement .alert {
    min-height: 150px;
    max-height: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #000;
}

.not-found-statement .alert h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
}

.not-found-statement .alert i {
    font-size: 45px;
    font-weight: 900;
    margin-right: 20px;
}

.loader-items-sort {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 70px;
}

.loader-items-sort .spinner-grow-sm {
    width: 8rem !important;
    height: 8rem !important;
    color: green !important;
}

.back-btn-createNew {
    display: flex;
}

.back-arrow-btn .fa {
    color: #309897;
    font-weight: 900;
    font-size: 40px;
    cursor: pointer;
}

.title-container {
    flex: 1 1;
}

.title-name-tt {
    color: #309897;
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    text-transform: uppercase;
}

.list-card {
    display: flex;
    align-items: center;
}

.list-card-container {
    margin-top: 10px;
    margin-bottom: 20px;
    min-height: 70px;
    display: flex;
}

.list-number {
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;

    background: #309897;
    border-radius: 4px;
    color: #fff;
    padding: 5px 15px 5px 15px;
    margin-right: 15px;
}

.list-item-stength {
    font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
    font-size: 20px;
    margin-top: 6px;
    flex: 2 1
}

.swot-row-cont {
    padding-left: 20px;
    padding-right: 20px;
}


.edit-button-list .fa {
    font-size: 38px;
    color: #000;
    font-weight: 900;
}

.title-anaysis-tabs {
    color: #fff !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 900 !important;
}

.swot-info-container {
    background-color: #eee;
    padding: 15px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    border-radius: 3px;
    margin-top: 20px;
}

.swot-info-container p {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 300;

}

.action_items_container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.action_swot_item {
    padding-right: 40px !important;
}

.what-is-swot {
    position: relative;
    color: #1d3ede !important;
}

@media (max-width: 600px) {
    .swot-main-container {
        position: relative;
        top: 40px;
    }

    .more-details-button {
        justify-content: right;
        margin-top: 50px;
        position: relative;
    }

    .swot-name-container h4 {
        justify-content: right;
        margin-top: 50px;
        position: relative;
    }

    .underline {
        width: 50px;
    }

    .card-swot-display {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 25px;
    }

    .card-swot-display h1 {
        font-family: "Roboto", sans-serif;
        font-weight: 100;
    }

    .swot-row-cont {
        padding-bottom: 65px;
    }

    .action_items_container {
        justify-content: space-around !important;
    }

    .action_swot_item {
        padding-right: unset !important;
    }
}
.bmc-view-container {
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.blocks-row {
    margin-top: -40px;
    margin-bottom: 40px
}

.what-is-bmc {
    color: #000 !important;
    font-weight: 900 !important;
}

.tabs-items-bmc {
    font-weight: 800 !important;
    font-size: 16px !important;
    font-family: "Roboto", sans-serif !important;
}

.back-btn-bmc {
    position: absolute;
    margin-top: -50px;
    cursor: pointer;
    z-index: 1;
}

.back-btn-bmc i {
    font-size: 40px;
    margin-left: 15px;
    font-weight: 800;
}

.back-btn-bmc span {
    font-size: 20px;
    margin-left: 10px;
    top: -7px;
    position: relative;
    text-transform: capitalize;
    color: green;
}

.loader-bmc-list {
    left: 50%;
}

.block-title-container {
    flex: 1 1;
}

.block-title-container h3 {
    align-items: center;
    align-content: center;
    justify-content: center;
    font-family: "Alfa Slab One", cursive;
    margin-top: 10px;
    text-transform: uppercase;
    left: 50%;
    position: relative;
}

.bmc-ne-l-butn {
    z-index: 1;
    font-size: 20px;
    cursor: pointer;
}

.bmc-ne-l-butn i {
    font-size: 28px;
}

.bcm-title-name {
    margin-top: -40px;
}

.bcm-title-name span {
    font-size: 1.5rem;
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    color: green;
}

.bcm-description-t {
    margin-top: 12px;

}

.bcm-description-t span {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 300;
}

.bmc-crd-igo {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    min-height: 116px;
}

.bmc-crd-igo h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
}

.question-bmc {
    font-size: 50px !important;
    margin-top: 15px;
    margin-left: 50px;
    cursor: pointer;
}

.question-bmc-active {
    font-size: 50px !important;
    margin-top: 15px;
    margin-left: 50px;
    cursor: pointer;
    color: purple !important;
}

@media (min-width: 600px) {
    .blocks-row {
        margin-top: unset;
        margin-bottom: unset;
    }
}

@media (min-width: 801px) {
    .bmc-swot-tab-box {
        border-radius: 0px !important;
    }

}



@media (max-width: 600px) {
    .bmc-container {
        position: relative;
        top: 90px;
    }

    .bcm-title-name {
        margin-top: unset;
    }

    .blocks-row {
        margin-top: 90px;
    }

    .bmc-ne-l-butn {
        position: relative;
        top: 90px;
    }

    .bcm-title-name {
        position: relative;
        top: 5px;
    }

    .back-btn-bmc {
        margin-top: unset;
    }

    .bmc-add-btn {
        margin-top: -80px !important;
        position: absolute;
        right: 0px;
    }

    .block-title-container h3 {
        left: unset;
        text-align: center;
        font-size: 1.25rem;
    }

    .not-found-statement {
        margin-top: 50px;
        margin-bottom: 50px;
    }

}
.ProjectCalender_project_event_display__1_RHK {
    width: 100%;
    height: 500px;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    background: #eee;
    max-width: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
    text-overflow: ellipsis;
}

.ProjectCalender_project_event_display_title__1qV0L {
    background: #fff;
    text-align: center;
    color: purple;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    text-transform: uppercase;
    padding: 10px;
    border-radius: 8px;
}

.ProjectCalender_project_event_display_title__1qV0L>span {
    margin-left: 10px;
}

.ProjectCalender_to_do_icon__ikate {
    color: black !important;
    font-weight: 900;
}

.ProjectCalender_footer_list_container__2M-1X {
    position: relative;
    bottom: -200px;
    right: -45px;
}

.ProjectCalender_form_detail_cont_title__APSir {
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    color: purple;
    text-decoration: underline;
}

.ProjectCalender_form_detail_cont__2Duln {
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    font-weight: 900;
}

.ProjectCalender_add_profiles_button__1olZN {
    margin-top: -10px;
    margin-left: 20px;
    cursor: pointer;
}

.ProjectCalender_display_profile_image_list_cont__2LluQ {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
}

.ProjectCalender_select_user_button__3SrjR {
    position: relative;
    right: -90px;
}

.ProjectCalender_event_title__3PxGn {
    font-family: 'Quicksand', sans-serif;
    font-size: 18px !important;
    font-weight: 400 !important;
    text-wrap: wrap;
    text-overflow: ellipsis;
}

.ProjectCalender_event_container_item__3Hdw6 {
    padding: 15px;
}

.ProjectCalender_event_container_item__3Hdw6:hover {
    background-color: purple;
    cursor: pointer;
    color: #fff;
    width: 100%;
    border-radius: 20px;
    padding-left: 10px;
    margin-left: 10px;
    margin-top: 5px;
}

.ProjectCalender_task_name_field__1GogU {
    width: 100% !important;
}

.ProjectCalender_no_event_container__3E_bk {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 100px;
    color: black;
    font-size: 30px;
    font-family: 'Anek Telugu', sans-serif !important;
    font-weight: 900;
}

.ProjectCalender_icon_no_item__3PS7_ {
    font-size: 35px;
    font-weight: 900;
    margin-right: 10px;
    color: purple;
}

.ProjectCalender_open_item_title_con__3Equ9 {
    font-family: 'Anek Telugu', sans-serif !important;
    font-weight: 700;
    color: #1c0202;
    font-size: 28px;
    margin-bottom: 30px;
    text-decoration: underline;
    margin-top: 25px;
}

.ProjectCalender_bread_crumbs_cont__1diWn {
    margin-bottom: 15px;
}

.ProjectCalender_list_test_name_il__2Y9LS {
    color: #000;
    font-weight: 900 !important;
    font-family: "Roboto", sans-serif !important;
}

.ProjectCalender_list_test_name_date__3dif9 {
    color: #918d8d;
    font-family: 'Quicksand', sans-serif !important;
    font-weight: 400;
    font-size: 17px !important;
}

.ProjectCalender_tabselection_container__3gXnO {
    margin-top: 30px;
    width: 100%;
    min-height: 400px;
    max-height: 400px;
    overflow-y: scroll;
}

.ProjectCalender_open_item_description_container__2PJbt {
    width: 100%;
    min-height: 100px;
    background-color: rgb(199, 227, 227);
    border-radius: 10px;
    color: purple;
    font-family: "Roboto", sans-serif !important;
    padding: 10px;
    font-size: 17.5px;
}

.ProjectCalender_date_picker_display__1tede {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
}

/* width */
.ProjectCalender_tabselection_container__3gXnO ::-webkit-scrollbar {
    width: 10px !important;
    visibility: visible !important;
}

/* Track */
.ProjectCalender_tabselection_container__3gXnO ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
    visibility: visible !important;
}

/* Handle */
.ProjectCalender_tabselection_container__3gXnO ::-webkit-scrollbar-thumb {
    background: #888 !important;
    visibility: visible !important;
}

/* Handle on hover */
.ProjectCalender_tabselection_container__3gXnO ::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
    visibility: visible !important;
}

.ProjectCalender_add_comment_btn__3A5jY {
    position: relative;
    top: -14px;
    cursor: pointer;
    left: 50%;
}

.ProjectCalender_loading_text__2cZEC {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
}

.ProjectCalender_loading_text__2cZEC>h6 {
    color: purple;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px !important;
}

.ProjectCalender_creator_name__pxsPd {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 800 !important;
    font-size: 20px !important;
}

.ProjectCalender_mobile_button_event__3a5BO {
    display: none !important;
}

@media (max-width: 801px) {
    .ProjectCalender_project_event_display__1_RHK {
        display: none !important;
    }

    .ProjectCalender_mobile_button_event__3a5BO {
        display: inline !important;
        margin-top: 20px !important;
    }

    .ProjectCalender_mobile_button_event__3a5BO {
        margin: 10px;
    }

    .ProjectCalender_calender_container__20_0p {
        margin-top: 30px;
    }
}
.AppSideBarProject_menu_top__2pFdI {
    width: 100%;
    height: 150px;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AppSideBarProject_close_menu_button__3m7lD {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    font-weight: 800;
    cursor: pointer;
}

.AppSideBarProject_menu_top__2pFdI h5 {
    font-weight: 800;
    text-transform: capitalize;
}

.AppSideBarProject_menu_item__9y0oI {
    font-family: Roboto, sans-serif !important;
    font-size: 18px;
    font-weight: 300;
    color: #000;
}

.AppSideBarProject_active_tab__3xYRn {
    background-color: purple;
    color: #fff;
}
.fa-forward {
  color: black !important;
}

.box_form_shadow {
  box-shadow: 0px 2px 48px 1px rgba(168, 165, 168, 1);
}

.fa-backward {
  color: orange !important;
}

.jumbo-suggest {
  margin: 0 auto;
}

.suggest-btn-group {
  text-align: center;
}

.suggest-btn {
  border: none;
  padding: 10px;
  background-color: orange;
  margin: 0 auto;
}

.legend-table-container {
  width: 100%;
  padding: 16px;
  margin-top: 16px;
}

.legend-table-container table {
  width: 100%;
  border-radius: 8px;
}

.legend-table-container table th {
  padding: 8px;
  background-color: #047C7C;
}

.legend-association {
  background-color: orange;
  padding: 12px;
  font-size: 18px;
  font-weight: 600;
}

.legend-table-container table tr td {
  padding: 8px;
}

.table-hr {
  height: 3px;
  background-color: rgb(237, 233, 229);
}
.pagination-desktop {
  display: none;
}

.all-users-title h3 {
  font-family: "Roboto", sans-serif;
  color: #000000;
  font-weight: 500;
  font-size: 20px;
  margin-top: 30px;
}

.pagination-real-styles{
  position: absolute;
  right: 10px;
}

.all-users-card {
  margin-top: 40px;
}

.all-users-card .card {
  width: 380px;
  border: 1px solid #C4C4C4;
  border-radius: 15px;
  background-color: #fff;
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-top: unset !important;
}

.all-users-card .upper {
  height: 100px
}

.all-users-card .upper img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px
}

.all-users-card .user {
  position: relative
}

.all-users-card .profile img {
  height: 80px;
  width: 80px;
  margin-top: -8px;
}

.all-users-card .profile {
  position: absolute;
  top: -50px;
  left: 38%;
  height: 90px;
  width: 90px;
  border-radius: 50%
}

.all-users-card .follow {
  border-radius: 15px;
  padding-left: 20px;
  padding-right: 20px;
  height: 35px
}

.all-users-card .stats span {
  font-size: 29px
}

.user-name {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #000000;
}

.user-living-place {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #000000;
  margin-top: 10px;
}

.user-position {
  margin-top: 25px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #047C7C;
}

.pagination-mobile {
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  right: 0px;
}

/* <==== Media queries ====> */

@media (min-width: 576px) {

  .all-users-search-bar .form-control{
    width: 70% !important;
  }
  
  .display-mobile-line {
    display: none;
  }
  .all-users-card .profile {
    left: 30%;
  }
  .all-users-card .profile img {
    margin-top: -20px;
  }
  .all-users-card .card {
    min-height: 290px;
  }
  .pagination-mobile {
    display: none;
  }
  .pagination-desktop {
    display: flex;
    flex-wrap: nowrap;
  }
  .pagination-desktop .number_of_users {
    flex: 1 1;
  }
  .number_of_users {
    margin-top: 10px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    opacity: 0.8;
  }
  .pagination-desktop {
    margin-top: 20px;
  }
  .reusable-sorting-view {
    display: flex;
    flex-wrap: nowrap;
  }
  .reusable-sorting-view i {
    margin-left: 4px;
    margin-right: 0px;
    margin-top: 4px;
  }
  .reusable-sorting-component{
    position: relative;
    left: -80px;
    top: 25px;
  }

  .reusable-sorting-sort-by{
    display: flex;
    flex-wrap: nowrap;
  }

  .reusable-sorting-show span{
    margin-left: 8px;
    margin-right: 1px;
  }

  .reusable-sorting-sort-by span{
    margin-left: 3px;
    margin-right: 1px;
  }
  .reusable-sorting-component .reusable-sorting-view {
    flex: unset;
  }


}

@media (min-width: 660px) {
  .all-users-card .profile {
     left: 38%;
  }
}
.search-box {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}

.input-search {
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  background-color: orange;
  padding-right: 40px;
  color: black;
}

.input-search::-webkit-input-placeholder {
  color: rgba(119, 108, 108, 0.5);
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
}

.input-search:-ms-input-placeholder {
  color: rgba(119, 108, 108, 0.5);
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
}

.input-search::placeholder {
  color: rgba(119, 108, 108, 0.5);
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
}

.btn-search {
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color: black;
  background-color: transparent;
  pointer-events: painted;
}

.btn-search:focus ~ .input-search {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid orange;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}

.input-search:focus {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid orange;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}

.financial-body {
  margin-top: 20px;
}

.financial-form form {
  margin-bottom: 40px;
}

.financial-form select {
  width: 50% !important;
  z-index: 100;
}

.financ-container-all .card {
  margin-bottom: 15px;
}

.financ-container-all .card .card-header {
  color: #1d1d1d;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding-left: 8px;
}

.department {
  position: absolute;
  right: 10px;
  border: 1px solid #eee;
  padding: 6px;
  border-radius: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-transform: capitalize;
  top: 6px;
  font-size: 14px;
}

.info-bg {
  border: 1px solid #eee;
  padding: 6px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-transform: capitalize;
  padding-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #2e2e2e;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.7px;
}

.financ-container-all .card-title {
  color: #1d1d1d;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 30px;
}

.financ-container-all .card-text {
  font-family: "Roboto", sans-serif;
  color: #1d1d1d;
}

.date-display {
  position: relative;
  right: 2px;
  bottom: 2px;
  margin-top: 60px;
}

.financials-amount-used {
  font-size: 18px;
  text-transform: unset;
  color: #2e2e2e;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.task-all {
  min-height: 500px !important;
}

.create-new-item {
  position: absolute;
  right: 10px;
  margin-top: 10px;
  background: #ec9528;
  box-shadow: 0px 4px 14px -5px rgb(0 0 0 / 31%);
  border-radius: 6px;
  padding: 5px;
  border: unset;
  color: #ffff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.form-tasks-class {
  position: absolute;
}
.list-collaborators {
  display: flex;
  flex-wrap: nowrap;
}

.list-collaborators small {
  flex: 1 1;
}

.collaborators-label {
  font-size: 15px !important;
}

.edit-open-item {
  background: #ec9528;
  box-shadow: 0px 4px 14px -5px rgb(0 0 0 / 31%);
  padding: 5px;
  border: unset;
  color: #ffff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.create-new-financial-record {
  position: relative;
  margin-top: -30px;
  margin-bottom: 10px;
}

.create-new-financial-record button{
    background: #ec9528;
    box-shadow: 0px 4px 14px -5px rgb(0 0 0 / 31%);
    padding: 5px;
    border: unset;
    color: #ffff;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100%;
}

.check-new-employee {
  display: flex;
}

.check-new-employee small {
  flex: 1 1;
}

.profile-image-financials {
  height: 120px;
}

.amount-recieved {
  display: flex;
  flex-wrap: nowrap;
}

.amount-recieved .card-title {
  flex: 1 1;
  position: absolute;
  right: 10px;
}

.remaining-amount {
  margin-top: 20px;
}

.financial-notes{
    position: relative;
    top: 20px;
}
.sort-financials{
  width: 100%;
}

.TaskMenuBar_main_container_open_items__2Tvr0 {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}

.TaskMenuBar_main_side_bar_container__1lFuM {
    flex: 1 1;
}

.TaskMenuBar_main_area_container__1VBAQ {
    flex: 7 1;
}

.TaskMenuBar_side_bar_container__3G-Qg {
    min-height: 100vh !important;
    position: relative;
}

.TaskMenuBar_sidebar_top_bar__26Ag4 {
    width: 100%;
    background: linear-gradient(to right bottom, #fff 48%, purple 49%);
    background-color: #fff;
    height: 110px;
    border: #272F3A solid 1px;
}

.TaskMenuBar_active_tab__2CK48 {
    background-color: purple;
    color: #fff !important;
}

.TaskMenuBar_over_holder_todo__31geI {
    border-radius: 9px 9px 0px 0px;
    -moz-border-radius: 0 0 9px 0px;
    -webkit-border-radius: 0px 0px 0px 0px;
    border: 0px solid #000000;
    width: 274px;
    margin-top: 20px;
    background-color: #ffc107;
}

.TaskMenuBar_over_holder_todo_2__2Z7Pt {
    border-radius: 9px 9px 0px 0px;
    -moz-border-radius: 0 0 9px 0px;
    -webkit-border-radius: 0px 0px 0px 0px;
    border: 0px solid #007bffba;
    width: 274px;
    margin-top: 20px;
    background-color: #007bffba;
    ;
}

.TaskMenuBar_over_holder_todo_3__1RwcD {
    border-radius: 9px 9px 0px 0px;
    -moz-border-radius: 0 0 9px 0px;
    -webkit-border-radius: 0px 0px 0px 0px;
    border: 0px solid #dc3545;
    width: 274px;
    margin-top: 20px;
    background-color: #dc091db5;
}

.TaskMenuBar_over_holder_todo_4__3vbfa {
    border-radius: 9px 9px 0px 0px;
    -moz-border-radius: 0 0 9px 0px;
    -webkit-border-radius: 0px 0px 0px 0px;
    border: 0px solid #000000;
    width: 274px;
    margin-top: 20px;
    background-color: #30de36c9;
}

.TaskMenuBar_to_do_board__27u57 {
    border-top: 0px solid #7f20dc;
}

.TaskMenuBar_board_title__2hpFx {
    display: flex;
    position: relative;
    top: 6px;
    margin-left: 5px;
}

.TaskMenuBar_h6_board_title__YPlmG {
    display: inline-flex;
    font-family: 'Ubuntu', sans-serif;
    padding-top: 10px;
    padding-left: 10px;
    color: #050525;
    width: 86%;
}

.TaskMenuBar_switch_name__2Fgiv {
    font-weight: 800 !important;
    font-family: 'Ubuntu', sans-serif;
}

.TaskMenuBar_drop_down_menu_show__1F6JO {
    font-family: 'Baloo Tammudu 2', cursive;
    border-radius: 8%;
}

.TaskMenuBar_assigned_to__1eFNB {
    display: flex;
    justify-content: space-between;
}

.TaskMenuBar_assigned_to__1eFNB>span {
    margin-top: 16px;
    font-family: 'Baloo Tammudu 2', cursive;
}

.TaskMenuBar_drop_item_menu__3uxmm {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 700 !important;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    font-family: 'Ubuntu', sans-serif !important;
    margin-top: 30px;
    margin-bottom: 30px;
}


.TaskMenuBar_card_sections__2iytb {
    position: relative;
    margin-top: 28px;
    box-shadow: 13px 13px 15px -8px rgba(0, 0, 0, 0.75);
    width: 264px;
    left: 4px;
}

#TaskMenuBar_add_items__3y4HG {
    border-style: dotted;
    background-color: #fafafb;
    border-color: #d9d9dc54;
    height: 52px;
    padding-bottom: 0;
    padding-top: 12px;
    padding-right: 0px;
    padding-left: 31px;
}

.TaskMenuBar_align_items_center__1b4dk {
    align-items: center !important;
    display: inline;
}

.TaskMenuBar_add_plus__1E4VA {
    margin-left: 91px;
    color: #454343 !important;
    font-weight: 900 !important;
}

.TaskMenuBar_card_text__2zEpX {
    font-size: 15px;
    font-family: 'Baloo Tammudu 2', cursive;
}

.TaskMenuBar_ms__2Sben {
    font-size: 14px;
    font-family: 'Baloo Tammudu 2', cursive;
    margin-top: 17px;
}

.TaskMenuBar_ntf__3j1iX {
    float: right;
}

.TaskMenuBar_drawer_header__uCFiv {
    width: 100%;
    height: 65px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;

}

.TaskMenuBar_drawer_header__uCFiv>h3 {
    text-transform: capitalize;
    font-family: 'Ubuntu', sans-serif !important;
}

.TaskMenuBar_close_button_drawer__3Ejgf {
    position: absolute !important;
    right: 20px !important;
}

.TaskMenuBar_drawer_body__2xbeP {
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;
}


.TaskMenuBar_bread_crumbs_cont__uaHL_ {
    margin-bottom: 15px;
    display: flex;
}

.TaskMenuBar_task_creator_name__1HCAw {
    position: relative;
    top: 22px;
    text-transform: uppercase;
    font-family: 'Anek Telugu', sans-serif !important;
    font-size: 20px;
}

.TaskMenuBar_loading_text___Sy13 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
}

.TaskMenuBar_open_item_title_con__2mLfP {
    font-family: 'Anek Telugu', sans-serif !important;
    font-weight: 700;
    color: #1c0202;
    font-size: 28px;
    margin-bottom: 30px;
    text-decoration: underline;
    margin-top: 25px;
}

.TaskMenuBar_open_item_description_container__EVIei {
    width: 100%;
    min-height: 100px;
    background-color: rgb(199, 227, 227);
    border-radius: 10px;
    color: purple;
    font-family: "Roboto", sans-serif !important;
    padding: 10px;
    font-size: 17.5px;
}

.TaskMenuBar_date_picker_display__25xvp {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
}

/* width */
.TaskMenuBar_tabselection_container__1e-Yi ::-webkit-scrollbar {
    width: 10px !important;
    visibility: visible !important;
}

/* Track */
.TaskMenuBar_tabselection_container__1e-Yi ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
    visibility: visible !important;
}

/* Handle */
.TaskMenuBar_tabselection_container__1e-Yi ::-webkit-scrollbar-thumb {
    background: #888 !important;
    visibility: visible !important;
}

/* Handle on hover */
.TaskMenuBar_tabselection_container__1e-Yi ::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
    visibility: visible !important;
}

.TaskMenuBar_add_comment_btn__1kIit {
    position: relative;
    top: -14px;
    cursor: pointer;
    left: 50%;
}

.TaskMenuBar_loading_text___Sy13 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
}

.TaskMenuBar_loading_text___Sy13>h6 {
    color: purple;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px !important;
}

.TaskMenuBar_creator_name__1P0id {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 800 !important;
    font-size: 20px !important;
}

.TaskMenuBar_tabselection_container__1e-Yi {
    margin-top: 30px;
    width: 100%;
    min-height: 400px;
    max-height: 400px;
    overflow-y: scroll;
}


.TaskMenuBar_list_test_name_il__3EHdB {
    color: #000;
    font-weight: 900 !important;
    font-family: "Roboto", sans-serif !important;
}

.TaskMenuBar_list_test_name_date__3EQqr {
    color: #918d8d;
    font-family: 'Quicksand', sans-serif !important;
    font-weight: 400;
    font-size: 17px !important;
}

.TaskMenuBar_task_name_field__38WNs {
    width: 100% !important;
}

.TaskMenuBar_no_event_container__2KzyF {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 100px;
    color: black;
    font-size: 30px;
    font-family: 'Anek Telugu', sans-serif !important;
    font-weight: 900;
}

.TaskMenuBar_icon_no_item__3VOFj {
    font-size: 35px;
    font-weight: 900;
    margin-right: 10px;
    color: purple;
}

.TaskMenuBar_event_title__2Tg-0 {
    font-family: 'Quicksand', sans-serif;
    font-size: 18px !important;
    font-weight: 400 !important;
    text-wrap: wrap;
    text-overflow: ellipsis;
}

.TaskMenuBar_event_container_item__22i4W {
    padding: 15px;
}

.TaskMenuBar_event_container_item__22i4W:hover {
    background-color: purple;
    cursor: pointer;
    color: #fff;
    width: 100%;
    border-radius: 20px;
    padding-left: 10px;
    margin-left: 10px;
    margin-top: 5px;
}

.TaskMenuBar_display_profile_image_list_cont__318PI {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
}

.TaskMenuBar_select_user_button__1kaC6 {
    position: relative;
    right: -90px;
}

.TaskMenuBar_creator_name__1P0id {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 800 !important;
    font-size: 20px !important;
}

.TaskMenuBar_project_display_container__2uFqE {
    border: #eee solid 1px;
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
}

.TaskMenuBar_project_display_container__2uFqE>span {
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 18px;
}
.profileSidebar_side_bar_container__2mNW2 {
    overflow-y: scroll;
    min-height: 100vh;
    height: 100vh;

}

.profileSidebar_sidebar_top_bar__1SAHP {
    width: 100%;
    min-height: 150px;
    background-color: #eee;
}

.profileSidebar_close_icon__2mafm {
    font-weight: 800;
    position: relative;
    top: 32px;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
}

.profileSidebar_active_tab__2hv7c {
    background-color: purple;
    color: #fff;
}

.profileSidebar_side_bar_divider__1xMNv {
    background-color: white;
    color: white;
}

.profileSidebar_project_creator__1Vx-p {
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.profileSidebar_avatar_project_creator__3L7HZ {
    align-items: center;
    left: 40%;
}

.profileSidebar_project_creator__1Vx-p h6 {
    text-transform: uppercase;
    font-weight: 800;
}

.profileSidebar_project_creator__1Vx-p span {
    text-transform: uppercase;
    font-weight: 800;
    color: purple;
}

.profileSidebar_creator_avator__3t9HF {
    display: flex;
    justify-content: center;
}
.profile_main_container__18X2G {
    width: 100%;
    display: flex;
}

.profile_main_display_area__3_73u {
    width: 100%;
}

.profile_header_image_area__6oZ2l {
    width: 100%;
    background-image: url(/static/media/backdropProfile.21f71736.jpg);
    background-size: cover;
    background-position: center;
    height: 150px;

}

.profile_profile_image_area__Qf6UX {
    position: relative;
    top: -40px;
    margin-left: 50px;
}

.profile_profile_name_container__3bUkV {
    position: relative;
    top: -80px;
    margin-left: 160px;
}

.profile_actual_profile_name__3pWNz {
    color: black;
    font-weight: 800;
    text-transform: capitalize;
}

.profile_profile_role__Wujzt {
    color: #515050;
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
}

.profile_profile_edit_button__3RbJ_ {
    position: absolute;
    right: 20px;
    margin-top: 50px;
}

.profile_social_media_icons__1exwc {
    position: relative;
    display: flex;
    top: -80px;
    margin-left: 160px;

}

.profile_profile_nav__1mMmz {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.profile_profile_summary_container__TGFgV {
    width: 100%;
    margin-top: 20px;
}

.profile_profile_reusable_card_container__3kCGR {
    height: 250px;
    max-height: 250px;
    min-width: 400px;
    min-height: 250px;
    width: 100%;
    background-color: #eee;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    border-radius: 3px;
    overflow-y: scroll;
    margin-bottom: 25px;
}

.profile_profile_reusable_card_header__3K8So {
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    background: #fff;
    width: 100%;
    min-height: 50px;
    padding: 8px;
    margin-bottom: -12px;

}

.profile_profile_reusable_card_header__3K8So>span {
    color: purple;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    margin-left: 5px;
    position: relative;
    top: 3px;
    text-transform: uppercase;
}

.profile_profile_reusable_card_body__FhrVP {
    margin: 15px;
}

.profile_actual_details__1vHwL {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 300;
    padding: 8px;
}

.profile_badges_container__1QhDZ {
    border: 1px solid #eee;
    min-height: 250px;
    border-radius: 10px;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
}
.master-need-container {
  width: 100%;
  margin-top: 100px;
}

.master-needs-header {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  padding: 8px;
}

.master-needs-header button {
  border: unset;
  color: #ffffff;
  background: #309897;
  border-radius: 8px;
  width: 180px;
  padding: 6px;
}

.master-needs-header button:hover {
  border: 1px solid #309897;
  color: #309897;
  background: #ffffff;
}

.master-need-categories {
  display: flex;
  flex: row 1;
  flex-wrap: wrap;
  justify-content: center;
}

.master-need-category-card {
  width: 250px;
  height: 60px;
  margin: 8px;
  color: #fff;
  font-size: 25px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.master-need-card-container {
  width: 100%;
  padding: 16px;
}

.master-need-card {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.master-need-card-header {
  background-color: #efe8e8;
  display: flex;
  justify-content: space-between;
  color: #309897;
  width: 100%;
  border: 1px solid rgb(201, 194, 194);
  border-radius: 8px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

.master-need-card-header i {
  color: #8f1fd0;
}

.master-need-card-header button {
  color: #309897;
  border: unset;
  background: unset;
  border-radius: 50%;
}

.master-need-card-header button i {
  color: #309897;
}

.master-need-card-body {
  position: relative;
  bottom: 5px;
  width: 100%;
}

.master-need-card-body-details {
  width: 90%;
  margin-top: 10px;
  margin-left: 22px;
  padding-bottom: 10px;
}

.master-need-card-body-details h6, .master-need-card-body-details h5 {
  color: #309897;
}

.master-need-card-body-info {
  width: 100%;
  height: 180px;
  background-color: #f3eeee;
  border-radius: 6px;
}

.master-need-card-body-info-top {
  width: 100%;
  height: 75%;
  padding: 6px;
  overflow-y: scroll;
}

.master-need-card-body-info-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  border-top: 1px solid #c2bdbd;
  width: 100%;
  height: 25%;
}

.master-need-card-body-info-bottom button {
  border: 1px solid #309897;
  color: #309897;
  background: unset;
  margin-right: 6px;
  border-radius: 6px;
}

.master-need-card-body-info-bottom button:hover {
  background: #309897;
  color: #fff;
}

.master-need-card-body-support {
  position: relative;
  bottom: 7px;
  width: 100%;
  height: 300px;
  border: 1px solid #efe8e8;
  padding: 6px;
}

.master-need-card-body-header {
  height: 10%;
}

.master-need-card-body-msgs {
  height: 70%;
  overflow-y: scroll;
}

.master-need-card-body-form {
  display: flex;
  height: 20%;
  align-items: center;
}

.master-need-card-body-form button {
  margin-left: 10px;
  width: 100px;
  height: 38px;
  background: #309897;
  border: unset;
  border-radius: 4px;
  color: #fff;
}

.master-need-support-msg {
  display: flex;
  width: 90%;
  margin-top: 10px;
  margin-right: 30px;
}

.master-need-support-msg-avatar {
  width: 10%;
  padding-left: 20px;
}

.master-need-support-msg-text {
  width: 90%;
  background-color: #c0f2f1c5;
  padding: 10px;
  border-radius: 8px;
}
.library-card {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.library-card-header {
  background-color: #efe8e8;
  display: flex;
  justify-content: space-between;
  color: #309897;
  width: 100%;
  height: 50px;
  border: 1px solid rgb(201, 194, 194);
  border-radius: 8px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

.library-card-header i {
  color: #4b77e7;
}

.library-card-header button {
  color: #309897;
  border: unset;
  background: unset;
  border-radius: 50%;
}

.library-card-header button i {
  color: #309897;
}

.library-card-body {
  position: relative;
  bottom: 5px;
  width: 100%;
}

.library-card-body-details {
  width: 90%;
  margin-top: 10px;
  margin-left: 50px;
  padding-bottom: 10px;
}

.library-card-body-details h6,
.library-card-body-details h5 {
  color: #309897;
}

.library-card-body-info {
  width: 100%;
  height: 180px;
  background-color: #f3eeee;
  border-radius: 6px;
}

.library-card-body-info-top {
  width: 100%;
  height: 75%;
  padding: 6px;
  overflow-y: scroll;
}

.library-card-body-info-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  border-top: 1px solid #c2bdbd;
  width: 100%;
  height: 25%;
}

.library-card-body-info-bottom button,
.library-item-status button {
  border: 1px solid #309897;
  color: #309897;
  background: unset;
  margin-right: 6px;
  border-radius: 6px;
}

.library-card-body-info-bottom button:hover,
.library-item-status button:hover {
  background: #309897;
  color: #fff;
}

.library-item-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.library-modal-content {
  margin: 10% auto;
  border-radius: 6px;
  width: 80%;
}

.library-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  color: #fff;
  width: 100;
  height: 40px;
  padding: 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #309897;
}

.library-modal-header button {
  color: rgb(249, 249, 249);
  font-size: 18px;
  border: unset;
  background: unset;
}

.library-modal-header button:hover,
.library-modal-header button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.library-modal-body {
  width: 100%;
  height: 600px;
  padding: 8px;
  background-color: #fefefe;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow-y: scroll;
}

.library-item {
  width: 100%;
  margin-top: 10px;
}

.library-item-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  height: 50px;
  border: 1px solid rgb(212, 208, 208);
  border-radius: 6px;
  padding: 6px;
}

.library-item-header-title {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

.upload-img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.library-item-header button {
  border: unset;
  background: unset;
  color: #309897;
}

.library-item-body {
  position: relative;
  bottom: 3px;
  width: 100%;
  border: 1px solid rgb(212, 208, 208);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.library-item-body-details {
  width: 90%;
  margin-top: 10px;
  margin-left: 50px;
  padding-bottom: 10px;
}

.library-item-body-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 180px;
  padding: 6px;
  background-color: #f3eeee;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.library-item-body-info-img {
  width: 25%;
  border-right: 1px solid #d0d4d4;
  object-fit: contain;
}

.library-item-body-info-details {
  width: 75%;
  padding: 6px;
  overflow-y: scroll;
}

.library-item-status {
  margin-top: 10px;
  color: #309897;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}

.dashboard-card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.dashboard-card {
  display: flex;
  width: 300px;
  height: 250px;
  margin: 8px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  background-repeat: no-repeat;
}

.dashboard-card:hover {
   width: 320px;
  height: 280px;
}
.main_mobile_view__1wbPT {
    display: none;
}

.main_mobile_logo_container__2uF0T {
    display: none;
}


.main_main_dashboard_container__2tU12 {
    width: 100%;
    overflow-x: hidden;
}

.main_desk_top_view__2p3uq {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.main_improved_project_name__1i0Kl :hover {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-weight: "800";
}

/* .desk_top_side_bar {
    flex: 1;
} */

.main_desk_top_info_container__AL_0B {
    flex: 7 1;
}

/* === TOP BAR ==== */
.main_upvote_favorite_container__1S4fr {
    display: flex;
    flex-wrap: wrap;
}

.main_bread_navigation__32ZlW {
    margin-top: 12px;
}

.main_upvote_container__2XR2R {
    display: flex;
    flex-wrap: nowrap;
    justify-items: flex-start;
}

.main_upvote_container__2XR2R span {
    margin-left: 10px;
    text-transform: lowercase;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}

.main_top_bar_container__3OEvN {
    width: 100% !important;
}

.main_upvote_items__3FGn- {
    margin-top: 0px;
}

.main_upvoted_by_container__38ICA {
    margin-left: 10px;
    cursor: pointer;
}

.main_favorite_container__23PT2 {
    display: flex;
    flex-wrap: nowrap;
}

.main_favorite_icon__2Yx-k {
    margin-top: -1px;
    cursor: pointer;
}

.main_favorite_container_items__3exfz {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 5px;
}

.main_favorite_container_items__3exfz span {
    margin-left: 10px;
    text-transform: capitalize;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    margin-top: 5px;
}

.main_jumbotron_container__2BF3L {
    margin-top: 10px;
    width: 100%;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -moz-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
}

.main_actual_jumbotron_area__2BKRV {
    border-radius: 3px;

}

.main_tab_selection_display__3PDUF {
    position: relative;
    bottom: -60px;
}

.main_jumbotron_container__2BF3L .main_jumbotron__3VeoX {
    text-align: unset !important;
}

.main_bread_project_name_one__2lpBW {
    background-color: #eee !important;
    color: #000 !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}


.main_bread_project_name__38uWB {
    background-color: green !important;
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}

.main_bread_project_name_active__Ntf1E {
    background-color: purple !important;
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}

.main_project_image_container__En2Vi {
    position: relative;
    top: -62px;
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    height: 38px;
    padding: 8px;
}

.main_project_image_container__En2Vi .main_project_image__2yz-q {
    box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.75);
}

.main_project_name_display__38Pzh {
    margin-left: 12px;
    font-size: 20px;
    font-weight: 700;
    color: purple;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    margin-left: 5px;
    position: relative;
}

.main_tab_selection_display__3PDUF {
    position: relative;
}

.main_timeline_container__3y_O_ {
    width: 100%;
    border: 1px solid #E5EAF2;
    border-radius: 3px;
    min-height: 342px;
    background: #eee;
    overflow-y: visible;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -moz-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    max-width: 400px;
    position: relative;

}

.main_timeline_top_bar__2cVGn {
    background-color: #fff;
    height: 45px;
    align-items: center;
    display: flex;
    padding-left: 20px;
}

.main_timeline_top_bar__2cVGn h6 {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    margin: 5px;
    color: purple;
}

.main_time_line_button_bar__2czX7 {
    background-color: #fff;
    height: 45px;
    align-items: center;
    display: flex;
    padding-left: 20px;
    bottom: 33px;
    justify-content: center;
}

.main_selection_display__1dvPN {
    position: relative;
    bottom: -50px;
}

/* ===== SIDE BAR ===== */
.main_side_bar_container__30Q4p {
    overflow-y: scroll;
    height: 100vh;
    min-height: 100%;
}

.main_sidebar_top_bar__1HYTn {
    width: 100%;
    height: 150px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main_close_icon__3u8X4 {
    font-weight: 800;
    position: relative;
    top: 10px;
    text-align: end;
    margin-bottom: 20px;
    right: 15px;
    cursor: pointer;
}

.main_active_tab__2qol7 {
    background-color: purple;
    color: #fff;
}

.main_side_bar_divider__3_s6S {
    background-color: white;
    color: white;
}

.main_project_creator__nL8fm h5 {
    line-height: 40px;
    text-align: center;
    margin-top: 8px;
    color: #000 !;
    font-family: "Roboto", sans-serif;
    font-weight: 200;
    font-weight: 800;
    text-transform: capitalize;
}

.main_creator_avator__15UKp {
    display: flex;
    justify-content: center;
}

.main_tuc_logo__2_agd {
    height: 90px;
}

.main_footer_container__O-wU6 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 30px;
}

.main_footer_name__3HEy5 {
    text-align: center;
    margin-top: 20px
}

.main_footer_name__3HEy5>h5 {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}

/* ==== MAIN INFO AREA ====== */
.main_main_area_info_container__SP9jV {
    margin-top: 25px;

}

.main_info_card_container__2cjvM {
    min-height: 250px;
    max-height: 250px;
    background-color: #eee;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    border-radius: 3px;
    max-width: 500px;
    width: 100%;
    margin-bottom: 30px;
}

.main_info_card_release_container__3cwYu {
    margin-top: 15px;
    background-color: #eee;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    border-radius: 3px;
    width: 100%;
    min-height: 500px;
    max-height: 500px;
    overflow-y: hidden;
    overflow-x: hidden;
    margin-bottom: 30px;
}

.main_info_card_header__1G17x {
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #cac7c7;
}

.main_info_card_header_title__I3qLv {
    color: purple;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    margin-left: 5px;
    position: relative;
    top: 3px;
    text-transform: uppercase;

}

.main_info_card_release_container__3cwYu .main_info_card_body__3THdD {
    padding: 10px;
    overflow-y: scroll;
    min-height: 400px;
    max-height: 400px;

}

.main_card_info_header__2w-_R {
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    flex-wrap: nowrap;
}

.main_number_value__1AWke {
    color: #000 !important;
}

.main_name_value__2I0vV {
    font-weight: 300 !important;
}

.main_card_info_body__3rj8K {
    margin-top: 0px;
}

.main_number_value_container__3I0QL {
    flex: 1 1;
}

.main_progress_circular__2Qa2J {
    justify-self: flex-end;
}

.main_most_recent_activity_container__27uar {
    min-height: 100%;
    height: 90vh;
    border: #eee solid 1px;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    border-radius: 3px;
    width: 100%;
    overflow-y: scroll;
    max-height: 90vh;
    overflow-x: hidden;
}

.main_info_card_header_activites__x3B6D {
    height: 50px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main_card_info_body_container__2Nk1n {
    width: 100%;
    cursor: pointer;
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    color: black;

    background: #e5c9e5;
    min-height: 84px;
    border-radius: 5px;
}

.main_card_info_body_container__2Nk1n:hover {
    cursor: pointer;
    text-decoration: underline;
    color: purple;
}

.main_project_name_body__333C- {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 6px;
    color: #000;

}

.main_recent_activity_mobile_container_head__-mn-8 {
    background-color: #eee;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_recent_activity_mobile_container_head__-mn-8 h6 {
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    margin-top: 5px;
    font-weight: 800;
}

/* ==== MOBILE DESIGN ==== */
.main_app_logo_mobile__3r2tZ {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_tuc_logo_mobile__1G-PV {
    height: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.main_mobile_menu_top_container__IvEQp {
    background: #eee;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main_mobile_menu_text__19Qoy {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 300;
}

.main_mobile_menu_text__19Qoy :hover {
    background-color: green;
}

.main_pinned_projects_container__3a5w1 {
    margin-top: 40px;
}

.main_pinned_container__12FyR {
    margin-top: 10px;
    width: 100%;
    border: 2px solid purple;
    min-height: 80px;
    border-radius: 5px;
    margin-bottom: 10px;
    background: #fff;
}




/* ==== MOBILE RESPONSIVENESS ==== */
@media (max-width: 801px) {
    .main_bread_navigation__32ZlW ol {
        display: flex !important;
    }

    .main_collaborators_container__1dQpK {
        margin-top: 8px !important;
        margin-bottom: 15px !important;
        border-top: 1px solid #eee !important;
        padding-top: 10px !important;
    }


    .main_project_image_container__En2Vi {
        top: -32px !important;
    }

    .main_jumbotron_container__2BF3L {
        margin-top: 0px !important;
        margin-bottom: -30px !important;
    }

    .main_info_card_container__2cjvM {
        max-width: unset !important;
        margin-bottom: 60px;
    }

    .main_info_card_release_container__3cwYu {
        margin-bottom: 100px !important;
    }

    .main_mobile_logo_container__2uF0T {
        display: inline;
        flex: 1 1;
    }

    .main_tuc_logo_mobile__1G-PV {
        height: 50px;
        padding-top: unset !important;
        padding-bottom: unset !important;
    }

}


/* ==== RESPONSIVENESS ====  */
@media only screen and (max-width: 800px) {
    .main_mobile_view__1wbPT {
        display: inline;
        width: 100%;
    }

    .main_desk_top_view__2p3uq {
        display: none;
    }
}
.main_update_notice_main_container__dWzvi {
    width: 100%;
}

.main_mobile_view__GK5Zw {
    display: none;
}

.main_content_container__1s6zR {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.main_desk_top_side_bar__MKTSn {
    flex: 1 1;
}

.main_desk_top_info_container__30irz {
    flex: 6 1;
}

.main_filter_and_add_button_container__3WV-M {
    display: flex;
    flex-wrap: nowrap;
}

.main_add_new_button__1UH8I {
    margin-top: 15px !important;
    margin-right: 10px !important;
}



/* ==== RESPONSIVENESS ====  */
@media only screen and (max-width: 800px) {
    .main_mobile_view__GK5Zw {
        display: inline;
        width: 100%;
    }

    .main_desk_top_view__NQsH2 {
        display: none;
    }
}
.topBar_top_bar_container__1LDbz {
    width: 100% !important;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topBar_bread_project_name_one__JfX_k {
    background-color: #eee !important;
    color: #000 !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}


.topBar_bread_project_name__bFwzU {
    background-color: green !important;
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}

.topBar_bread_project_name_active__pUPe4 {
    background-color: purple !important;
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}

@media only screen and (max-width: 530px) {
    .topBar_bread_navigation__1S2zo ol {
        display: block !important;
    }

    .topBar_top_bar_container__1LDbz {
        width: 100%;
        min-height: 150px;
        align-items: center !important;
        justify-content: center !important;
    }

}
.ListUpdate_list_item_container__2CHJw {
    margin-top: 25px;
}

.ListUpdate_list_top_bar__1yAoH {
    display: flex;
}

.ListUpdate_date_container__25NL3 {
    flex: 1 1;
    text-transform: capitalize;
    font-weight: 500;
    color: #9e9999;
    font-family: "Roboto", sans-serif;
    align-self: flex-end;
    text-align: end;
    position: relative;
    top: -8px;
    font-size: 15px;
}

.ListUpdate_update_name__1u2Oh {
    padding-top: 10px;
    font-family: "Roboto", sans-serif;
}

.ListUpdate_buttons_container__1XPhx {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}
.FilterComponent_filter_container__2BubL {
    padding-top: 15px;
    padding-bottom: 5px;
}
.sideBar_list_container__2vNAi {
    height: 100px;
    width: 100%;
    background-color: #eee;
    top: -8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    font-family: "Roboto", sans-serif !important;
}

.sideBar_active_tab__2llsq {
    background: "purple" !important;
    color: "white";
    width: 100%;
    height: 50px;
}
.update-notice-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.update-notice-main-container {
  width: 100%;
  overflow-x: scroll;
}

.update-notice-main {
  width: 1296px;
  display: flex;
  height: 700px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  border: 1px solid rgb(196, 192, 192);
}

.update-notice-side-nav {
  width: 25%;
  padding: 8px;
  height: 100%;
  border-right: 1px solid rgb(196, 192, 192);
}

.update-notice-content {
  width: 75%;
  height: 100%;
}

.update-info-card {
  width: 100%;
  height: 50%;
}

.update-info-card-header {
  width: 100%;
  height: 15%;
  padding: 16px;
  text-align: center;
  background: #309897;
  color: #fff;
  border-radius: 8px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

.update-info-card-body {
  width: 100%;
  height: 85%;
  overflow-y: scroll;
}

.info {
  white-space: nowrap;
  overflow-x: scroll;
  cursor: pointer;
}

.info:hover {
  color: #fff !important;
  background-color: #309897;
}

.update-notice-content-header {
  display: flex;
  flex-direction: row;
  padding: 8px;
}

.update-notice-content-header .img-cont {
  width: 10%;
  height: 60px;
}

.update-notice-content-header .details-cont {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 60px;
  background: #309897;
  color: #fff;
  border-radius: 8px;
}

.details-cont-top {
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
  padding: 6px;
}

.details-cont-bottom {
  height: 50%;
  text-align: right;
  padding: 6px;
}

.details-cont-main {
  width: 90%;
  height: 85%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  border: 1px solid rgb(196, 192, 192);
  margin-top: 10px;
}

.details-cont-main p {
  width: 100%;
  padding: 8px;
  word-wrap: break-word;
}

.empty-view-container {
  width: 80%;
  margin: auto;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  color: #afb8b8;
}
.move-up {
  position: relative;
  bottom: 40px;
}
.user-role-container {
  display: grid;
  width: 100%;
  overflow-y: scroll;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  overflow-x: scroll;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

h2 {
  font-family: "Roboto", sans-serif;
  color: #309897;
}

.asgn-btn {
  border: unset;
  background: unset;
  cursor: pointer;
}



.dec-na {
  text-decoration: none !important;
}

.user-role-table {
  width: 600px;
}

.user-role-table thead tr th {
  background: #309897;
  color: #fff;
  text-align: center;
  padding: 6px;
  font-weight: bold;
}

.user-role-table thead tr td {
  color: #6f6f6f;
  padding: 6px;
  font-weight: bold;
}

.user-role-table tbody tr td {
  color: #6f6f6f;
  padding: 6px;
  font-weight: 400;
  white-space: nowrap;
  overflow-x: scroll;
}

.display-favorites-container {
    position: relative;
    top: 90px;
}
.tag-header {
  padding: 16px;
  color: #047C7C;;
}

.tag-container {
  padding: 16px;
}

.tag-btn {
  background-color: #047C7C;
  color: #ffffff;
}

.tag-btn:hover {
  color: #047C7C;
  border: 1px solid #047C7C;
  background-color: #ffffff;
}

.tags-nil {
  margin-top: 100px;
  color: #c8bbbb;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.admin-header {
    margin: 40px 0 0 30px;
    width: 95%;
    height: 80px;
    background-color: #efeeee;
    border: 1px solid #c8c5c5;
    border-radius: 16px;
    color: rgb(124, 121, 121);
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    padding: 16px;
}

.admin-feature-badge-container {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    margin-top: 30px;
}

.admin-feature-badge {
    display: flex;
    justify-content: center;
    margin: 8px;
    align-items: center;
    width: 350px;
    height: 100px;
    border: 1px solid rgb(180, 177, 177);
    font-size: 30px;
    color: #efeeee;
    border-radius: 8px;
    cursor: pointer;
}

.admin-feature-badge a {
    color: #efeeee;
}

.admin-user-search {
    width: 95%;
    height: 60px;
    padding-left: 50px;
    border: 1px solid #a5a1a1;
    border-radius: 16px;
    margin: 8px 0 0 5px;
    font-size: 20px;
}

.search-icon {
    position: relative;
    left: 50px;
    font-size: 24px;
    color: #aaa9a9;
}

.user-card-container {
    width: 97%;
    display: flex;
    flex-direction: column;
    padding: 24px;
}

.user-card {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-top: 8px;
    margin-left: 16px;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #047C7C
}

.engagement-stats-container {
    margin-top: 50px;
    width: 100%;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.engagement-stats-card {
    width: 350px;
    height: 250px;
    background: linear-gradient(90deg, rgba(128, 228, 227, 0.6839329481792717) 0%, rgba(203, 230, 230, 0.72875087535014) 35%, rgba(194, 210, 210, 1) 100%);
    padding: 8px;
    display: flex;
    margin: 8px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
}

.engagement-stats-card img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.engagement-search-bar {
    width: 100%;
    display: flex;
}

.classic-purple {
    background-color: purple;
}



.engagement-user-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    width: 80%;
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #047C7C;
}

.main_invitation_container__wB9uC {
    padding: 20px;
}

.main_header_text__ogR2F {
    font-weight: 800;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
}

.main_header_small_text__36UsR {
    color: grey;
    font-family: "Roboto", sans-serif;
}

.main_form_container__3sbPx {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    left: 200px;
    width: 100%;
}

.main_main_container__KoBn6 {
    overflow-x: hidden;
}

.main_use_invitation_container__2avnR {}
.styles_mobile_display__lPPZI {
    display: none;
}

.styles_main_container__Ts1Do {
    width: 100%;
    overflow-x: hidden;
}


.styles_search_container__1CNg9 {
    display: flex;
    margin-top: 50px;
    align-items: center;
}

.styles_desktop_display__3hjuY {
    margin-top: 10px;
}

.styles_card_container_sec__1TZZd {
    margin-top: 20px;
    margin-bottom: 20px;

}

.styles_show_page_container__swudQ {
    margin-top: 15px;
}

.styles_details_card_container__2OXvC {
    width: 100%;
    min-width: 400px;
    background-color: #eee;
    min-height: 250px;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    border-radius: 3px;

}

.styles_details_card_collaborators_container__1aE_8 {
    width: 100%;
    min-width: 300px;
    background-color: #eee;
    min-height: 400px;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    border-radius: 3px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.styles_details_card_body_collaborators__2PHPT {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 300;
    padding: 8px;
    max-height: 250px;
}

.styles_details_card_header__DbwJG {
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.styles_details_card_body__L3zef {
    min-height: 200px;
    overflow-y: scroll;
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 300;
    padding: 8px;
    max-height: 250px;
}

.styles_card_header_title__-Lpki {
    color: purple;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    margin-left: 5px;
    position: relative;
    top: 3px;
    text-transform: uppercase;
}

.styles_show_page_serch_bar__23vFh {
    display: flex;
    align-items: center;
    justify-content: center;
}

.styles_display_project_cards__2xxWS {
    margin-top: 10px;
    margin-bottom: 20px;
}

.styles_network_info_container__35OF5 {
    background: #fff;
    width: 100%;
    min-height: 595px;
    height: 595px;
    max-inline-size: 80vh;
    margin-top: 20px;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    border-radius: 8px;
    max-width: 500px;
    min-width: 400px;
    top: 0;
    z-index: 0;
    padding: 13px;
}

.styles_image_container_section__3SFAs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 5px;
}

.styles_creator_name_container__1qh-w {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 10px;
}

.styles_roles_container__13t3V {
    text-align: center;
    margin-top: 5px;
}

.styles_roles_container__13t3V>p {
    font-family: 'Fjalla One', sans-serif;
    font-weight: 400;
    color: grey;

}

.styles_profile_details_info__28m_B {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}

.styles_sticky-sec__1Mr8n {
    position: sticky;
    top: 0;
    z-index: 0;
    margin-bottom: 40px;
}

.styles_creator_name_container__1qh-w>h5 {
    font-weight: 800;
}

.styles_creator_name_container__1qh-w>h6 {
    color: grey;
    text-transform: uppercase;
    font-weight: 600;
}

.styles_team_display__2thzL {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 20px;
}

.styles_title_search_bar_container__14jIR {
    display: flex;
    flex-direction: row;
}

.styles_search_bar_area__2n7A7 {
    position: relative;
    top: 25px;
}

.styles_sort_buttons_projects__3G5_S {
    position: relative;
    top: 35px;
}

.styles_actual_project_container__2EtJd {
    position: relative;
    top: 50px;
}



/* === MOBILE AND RESPONSIVENESS === */

@media (max-width: 801px) {
    .styles_mobile_display__lPPZI {
        display: inline;
    }

    .styles_desktop_display__3hjuY {
        display: none;
    }

}
.assumptionsSettings_assumptions_container__3kBzU {
    width: 100%;
}

.assumptionsSettings_categories_form_container__2fSjs {
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;

}
