.update-notice-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.update-notice-main-container {
  width: 100%;
  overflow-x: scroll;
}

.update-notice-main {
  width: 1296px;
  display: flex;
  height: 700px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  border: 1px solid rgb(196, 192, 192);
}

.update-notice-side-nav {
  width: 25%;
  padding: 8px;
  height: 100%;
  border-right: 1px solid rgb(196, 192, 192);
}

.update-notice-content {
  width: 75%;
  height: 100%;
}

.update-info-card {
  width: 100%;
  height: 50%;
}

.update-info-card-header {
  width: 100%;
  height: 15%;
  padding: 16px;
  text-align: center;
  background: #309897;
  color: #fff;
  border-radius: 8px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

.update-info-card-body {
  width: 100%;
  height: 85%;
  overflow-y: scroll;
}

.info {
  white-space: nowrap;
  overflow-x: scroll;
  cursor: pointer;
}

.info:hover {
  color: #fff !important;
  background-color: #309897;
}

.update-notice-content-header {
  display: flex;
  flex-direction: row;
  padding: 8px;
}

.update-notice-content-header .img-cont {
  width: 10%;
  height: 60px;
}

.update-notice-content-header .details-cont {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 60px;
  background: #309897;
  color: #fff;
  border-radius: 8px;
}

.details-cont-top {
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
  padding: 6px;
}

.details-cont-bottom {
  height: 50%;
  text-align: right;
  padding: 6px;
}

.details-cont-main {
  width: 90%;
  height: 85%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  border: 1px solid rgb(196, 192, 192);
  margin-top: 10px;
}

.details-cont-main p {
  width: 100%;
  padding: 8px;
  word-wrap: break-word;
}

.empty-view-container {
  width: 80%;
  margin: auto;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  color: #afb8b8;
}
.move-up {
  position: relative;
  bottom: 40px;
}