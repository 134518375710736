@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");
.pagination-desktop {
  display: none;
}

.all-users-title h3 {
  font-family: "Roboto", sans-serif;
  color: #000000;
  font-weight: 500;
  font-size: 20px;
  margin-top: 30px;
}

.pagination-real-styles{
  position: absolute;
  right: 10px;
}

.all-users-card {
  margin-top: 40px;
}

.all-users-card .card {
  width: 380px;
  border: 1px solid #C4C4C4;
  border-radius: 15px;
  background-color: #fff;
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-top: unset !important;
}

.all-users-card .upper {
  height: 100px
}

.all-users-card .upper img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px
}

.all-users-card .user {
  position: relative
}

.all-users-card .profile img {
  height: 80px;
  width: 80px;
  margin-top: -8px;
}

.all-users-card .profile {
  position: absolute;
  top: -50px;
  left: 38%;
  height: 90px;
  width: 90px;
  border-radius: 50%
}

.all-users-card .follow {
  border-radius: 15px;
  padding-left: 20px;
  padding-right: 20px;
  height: 35px
}

.all-users-card .stats span {
  font-size: 29px
}

.user-name {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #000000;
}

.user-living-place {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #000000;
  margin-top: 10px;
}

.user-position {
  margin-top: 25px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #047C7C;
}

.pagination-mobile {
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  right: 0px;
}

/* <==== Media queries ====> */

@media (min-width: 576px) {

  .all-users-search-bar .form-control{
    width: 70% !important;
  }
  
  .display-mobile-line {
    display: none;
  }
  .all-users-card .profile {
    left: 30%;
  }
  .all-users-card .profile img {
    margin-top: -20px;
  }
  .all-users-card .card {
    min-height: 290px;
  }
  .pagination-mobile {
    display: none;
  }
  .pagination-desktop {
    display: flex;
    flex-wrap: nowrap;
  }
  .pagination-desktop .number_of_users {
    flex: 1;
  }
  .number_of_users {
    margin-top: 10px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    opacity: 0.8;
  }
  .pagination-desktop {
    margin-top: 20px;
  }
  .reusable-sorting-view {
    display: flex;
    flex-wrap: nowrap;
  }
  .reusable-sorting-view i {
    margin-left: 4px;
    margin-right: 0px;
    margin-top: 4px;
  }
  .reusable-sorting-component{
    position: relative;
    left: -80px;
    top: 25px;
  }

  .reusable-sorting-sort-by{
    display: flex;
    flex-wrap: nowrap;
  }

  .reusable-sorting-show span{
    margin-left: 8px;
    margin-right: 1px;
  }

  .reusable-sorting-sort-by span{
    margin-left: 3px;
    margin-right: 1px;
  }
  .reusable-sorting-component .reusable-sorting-view {
    flex: unset;
  }


}

@media (min-width: 660px) {
  .all-users-card .profile {
     left: 38%;
  }
}