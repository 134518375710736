.user-role-container {
  display: grid;
  width: 100%;
  overflow-y: scroll;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
  overflow-x: scroll;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

h2 {
  font-family: "Roboto", sans-serif;
  color: #309897;
}

.asgn-btn {
  border: unset;
  background: unset;
  cursor: pointer;
}



.dec-na {
  text-decoration: none !important;
}

.user-role-table {
  width: 600px;
}

.user-role-table thead tr th {
  background: #309897;
  color: #fff;
  text-align: center;
  padding: 6px;
  font-weight: bold;
}

.user-role-table thead tr td {
  color: #6f6f6f;
  padding: 6px;
  font-weight: bold;
}

.user-role-table tbody tr td {
  color: #6f6f6f;
  padding: 6px;
  font-weight: 400;
  white-space: nowrap;
  overflow-x: scroll;
}
