@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

.top-nav {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 94px;
  background-color:  #272F3A;
  border-bottom: 1px solid #e2e2e2;
  z-index: 1 !important;
}

.favorite-nav-btn {
  font-size: 25px;
}

.top-nav-ham {
  position: relative;
  top: 18px;
  margin-right: 10px;
  border: unset;
  background: unset;
  color: #fff;
}

.top-nav-link-menu {
  width: 33%;
  padding-top: 10px;
  padding-left: 24px;
}

.top-nav-search {
  padding-top: 24px;
  width: 33%;
}

.top-nav-search input {
  width: 90%;
  padding: 6px;
  border-radius: 16px;
  border: unset;
  background-color: #e2e2e2;
}

.top-nav-icons {
  display: flex;
  flex: row;
  justify-content: space-evenly;
  padding-top: 26px;
  justify-items: center;
  width: 33%;
}

.top-nav-icon-cont {
  display: flex;
  flex-direction: column !important;
  justify-items: center;
  align-items: center;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.top-nav-icon-cont-profile {
  display: flex;
    flex-direction: column !important;
    justify-items: center;
    align-items: center;
    color: #fff;
    font-family: "Roboto", sans-serif;
    position: relative;
    right: 25px;
    top: 20px;
    cursor: pointer;
}

.top-nav-icons i:hover {
  cursor: pointer;
  color: #FF9B00 !important;
}

.tuc-logo{
  position: relative;
  top: 8px;
  left: 20px;
}

.toggle-nav {
  width: 340px;
  height: 0;
  position: absolute;
  top: 90px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: #fff;
  border-radius: 8px;
  ;
  border: 1px solid rgb(205, 199, 199);
  padding: 30px;
  animation-name: toggle-nav-height;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  z-index: 100;
}

.user-toggle-nav {
  width: 340px;
  height: 0;
  position: absolute;
  right: 0;
  top: 90px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: #fff;
  border-radius: 8px;
  ;
  border: 1px solid rgb(205, 199, 199);
  padding: 30px;
  animation-name: user-toggle-nav-height;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  z-index: 100;
}

.toggle-nav a, .user-toggle-nav a, .bagdes-div {
  width: 100%;
  font-size: 20px !important;
  padding: 8px;
  font-weight: 400;
  color: #535151 !important;
  line-height: 50px;
  border-radius: 8px;
}

.toggle-nav a:hover, .user-toggle-nav a:hover {
  background-color: #FF9B00;
  color: #fff !important;
}

.nav-btn-logout {
  border: unset;
  background-color: rgb(237, 62, 62);
  color: #fff;
  border-radius: 8px;
  padding: 6px;
}

.btn-wrapper {
  border: unset;
  background: unset;
  text-align: left;
}

@keyframes toggle-nav-height {
  100% {
    height: 800px;
  }
}

@keyframes user-toggle-nav-height {
  100% {
    height: 400px;
  }
}

@media only screen and (max-width: 770px) {
  .top-nav-link-menu {
    width: 50%;
  }

  .top-nav-icons {
    width: 50%;
  }

  .top-nav-search {
    display: none;
    width: 0;
  }
}

@media only screen and (max-width:580px) {
  .tuc-logo {
    display: none;
  }

  .top-nav-ham {
    top: 16px;
  }

  .toggle-nav {
    width: 92%;
  }

  .category-header {
    font-size: 30px !important;
    text-align: center;
  }

  .fa-award, .fa-envelope, .fa-bell {
    display: none !important;
  }

  .top-nav-icons {
    justify-content: end;
    padding-right: 16px !important;
  }
}

@media only screen and (min-width: 800px) {
  .top-nav-icons {
    padding-right: 20px;
    width: unset;
    padding-top: unset;
  }

  .top-nav-icon-cont {
    padding: 20px;
  }
}