.master-need-container {
  width: 100%;
  margin-top: 100px;
}

.master-needs-header {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  padding: 8px;
}

.master-needs-header button {
  border: unset;
  color: #ffffff;
  background: #309897;
  border-radius: 8px;
  width: 180px;
  padding: 6px;
}

.master-needs-header button:hover {
  border: 1px solid #309897;
  color: #309897;
  background: #ffffff;
}

.master-need-categories {
  display: flex;
  flex: row;
  flex-wrap: wrap;
  justify-content: center;
}

.master-need-category-card {
  width: 250px;
  height: 60px;
  margin: 8px;
  color: #fff;
  font-size: 25px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.master-need-card-container {
  width: 100%;
  padding: 16px;
}

.master-need-card {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.master-need-card-header {
  background-color: #efe8e8;
  display: flex;
  justify-content: space-between;
  color: #309897;
  width: 100%;
  border: 1px solid rgb(201, 194, 194);
  border-radius: 8px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

.master-need-card-header i {
  color: #8f1fd0;
}

.master-need-card-header button {
  color: #309897;
  border: unset;
  background: unset;
  border-radius: 50%;
}

.master-need-card-header button i {
  color: #309897;
}

.master-need-card-body {
  position: relative;
  bottom: 5px;
  width: 100%;
}

.master-need-card-body-details {
  width: 90%;
  margin-top: 10px;
  margin-left: 22px;
  padding-bottom: 10px;
}

.master-need-card-body-details h6, .master-need-card-body-details h5 {
  color: #309897;
}

.master-need-card-body-info {
  width: 100%;
  height: 180px;
  background-color: #f3eeee;
  border-radius: 6px;
}

.master-need-card-body-info-top {
  width: 100%;
  height: 75%;
  padding: 6px;
  overflow-y: scroll;
}

.master-need-card-body-info-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  border-top: 1px solid #c2bdbd;
  width: 100%;
  height: 25%;
}

.master-need-card-body-info-bottom button {
  border: 1px solid #309897;
  color: #309897;
  background: unset;
  margin-right: 6px;
  border-radius: 6px;
}

.master-need-card-body-info-bottom button:hover {
  background: #309897;
  color: #fff;
}

.master-need-card-body-support {
  position: relative;
  bottom: 7px;
  width: 100%;
  height: 300px;
  border: 1px solid #efe8e8;
  padding: 6px;
}

.master-need-card-body-header {
  height: 10%;
}

.master-need-card-body-msgs {
  height: 70%;
  overflow-y: scroll;
}

.master-need-card-body-form {
  display: flex;
  height: 20%;
  align-items: center;
}

.master-need-card-body-form button {
  margin-left: 10px;
  width: 100px;
  height: 38px;
  background: #309897;
  border: unset;
  border-radius: 4px;
  color: #fff;
}

.master-need-support-msg {
  display: flex;
  width: 90%;
  margin-top: 10px;
  margin-right: 30px;
}

.master-need-support-msg-avatar {
  width: 10%;
  padding-left: 20px;
}

.master-need-support-msg-text {
  width: 90%;
  background-color: #c0f2f1c5;
  padding: 10px;
  border-radius: 8px;
}