*:focus {
    outline: none;
}

.main_container {
    min-width: 450px;
    max-width: 450px;
    position: relative;
    margin-bottom: 10px;
    min-height: 730px;
}

.buttons_container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
}

.item_height {
    min-height: 140px;
    margin-bottom: 15px;
}

.buttons_container button {
    margin-top: 18px;
    margin-bottom: 18px;
}

.buttons_container span {
    font-size: 12.8px !important;
    font-weight: 600;
}

/* ========= MOBILE DESIGN ===== */
@media (max-width: 801px) {
    .main_container {
        min-width: unset !important;
        max-width: unset !important;
        margin-top: 20px !important;
    }

    .buttons_container>button {
        margin-bottom: -25px !important;
        margin-top: 20px !important;
    }

    .love_button {
        margin-top: 40px !important;
    }

}