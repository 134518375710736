@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

* {
  scroll-behavior: smooth;
}

.postion-margin {
  margin-bottom: 20px;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: purple;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.project-container {
  width: 100%;
}

.project-idea-container {
  color: #2E2E2E;
  opacity: 0.8;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 60px;
}

.slider-card-searched .project-idea-container p {
  height: 120px;
  color: #2E2E2E;
  opacity: 0.8;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 47px !important;
}

.slider-card-searched .project-idea-container {
  margin-bottom: 30px;
}

.input-fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tt-project-title-btn {
  display: flex;
  flex-wrap: nowrap;
}

.tit {
  margin-top: 8px;
  flex: 1;
}

.project-filter {
  margin: 10px;
  width: 200px;
  border: 1px solid #c3bcbc;
  border-radius: 8px;
  padding: 8px;
  color: #2E2E2E;
}

.g-i {
  position: relative;
  top: 7px;
  font-size: 30px;
}

.tit h4 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin-left: 10px;
}

.view-project-btn {
  background: #047c7c !important;
  border-radius: 5px;
  color: #fff !important;
  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: center;
  width: 100% !important;
}

.view-project-btn:hover {
  background: #ffffff !important;
  color: #047c7c !important;
}

.tt-projectBtn-container button {
  width: 40px;
  box-shadow: 0px 4px 14px -5px rgba(0, 0, 0, 0.31);
  border-radius: 6px !important;
  color: #fff !important;
  font-family: "Roboto", sans-serif;
  z-index: 1;
  cursor: pointer;
}

.project-upvote {
  display: flex;
  flex-wrap: nowrap;
}

.project-follow {
  display: flex;
  flex-wrap: nowrap;
}


.tt-project-form-container h3 {
  color: #047c7c !important;
}

.tt-project-form-area .form-group {
  margin-top: 20px;
  margin-bottom: 20px;
}

.tt-project-form-area .form-group label {
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #2e2e2e;
}

.tt-display-projects-container {
  position: relative;
  top: 50px;
}

.create-project-btn {
  background: #309897;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  color: #fff;
  font-family: "Roboto", sans-serif !important;
}

.tt-project-form-area {
  position: relative;
  padding: 25px;
  background: #ffffff;
  box-shadow: 0px 11px 47px -4px rgba(0, 0, 0, 0.2);
  border-radius: 13px;
}

.add-image-projects {
  display: flex;
  flex-wrap: nowrap;
}

.add-image-projects label {
  flex: 1;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height, or 104% */
  color: #2e2e2e;
}

.add-image-projects input {
  flex: 1;
  margin-top: -9px;
  font-family: "Roboto", sans-serif;
}

.tt-project-form-area .form-control {
  background: #ffffff;
  border: 1px solid #c4c4c4 !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
}

.project-goal-field {
  padding-left: 25px;
  padding-right: 25px;
}

.tt-project-display-entry {
  position: relative;
  top: 25px;
}

.tt-project-display-entry button {
  color: #000;
  font-weight: 900;
  font-family: "Ubuntu", sans-serif;
  font-size: 21px;
  letter-spacing: 0.8px;
}

.tt-project-display-entry .card {
  border-radius: 10px 10px 10px 10px;
  border-bottom: 1.5px solid #c4c4c4;
  border-top: unset;
  border-left: 1.5px solid #c4c4c4;
  border-right: 1.5px solid #c4c4c4;
  min-height: 462px;
  margin-bottom: 78px;
}

.tt-project-display-entry .card-img-top {
  max-height: 300px;
  height: 240px;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
  object-fit: fill;
}

.tt-project-display-entry .card-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  padding-bottom: 15px;
}

.tt-project-display-entry .card-text {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #2e2e2e;
  opacity: 0.8;
}

.tt-project-display-entry .card a {
  width: 100%;
  font-family: "Ubuntu", sans-serif;
}

.project-details {
  margin-top: 10px;
}

.project-details .topPurposeDisplay {
  padding: 25px;
  text-align: left;
}

.project-details .tt-statement-disp {
  margin-left: 10px;
}

.project-details .top10NameDisplay {
  top: 20px;
}

.project-details .view-details {
  background: #eee;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  box-shadow: -1px 2px 18px 2px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: -1px 2px 18px 2px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: -1px 2px 18px 2px rgba(0, 0, 0, 0.51);
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 10px;
}

.objective-goals-projects .btn-link .disp-numberline {
  font-size: 20px;
}

.objective-goals-projects .btn-link {
  font-size: 20px;
  font-family: "Ubuntu", sans-serif;
  color: #695f5f;
}

.objective-goals-projects .card {
  box-shadow: -1px 8px 23px 13px rgba(217, 201, 201, 0.9);
  -webkit-box-shadow: -1px 8px 23px 13px rgba(217, 201, 201, 0.9);
  -moz-box-shadow: -1px 8px 23px 13px rgba(217, 201, 201, 0.9);
  padding: 10px;
}

.display-objectives .badge {
  margin-right: 10px;
  font-size: 18px;
  font-family: "Fjalla One", sans-serif;
}

.disp-list-value {
  font-family: "Ubuntu", sans-serif;
}

.add-image-projects .form-control {
  border: unset !important;
}

.goal-title-obj-dis {
  font-family: "Alfa Slab One", cursive;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 33px;
}

.objective-goals-projects .add-btn {
  box-shadow: -1px 2px 18px 2px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: -1px 2px 18px 2px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: -1px 2px 18px 2px rgba(0, 0, 0, 0.51);
  border-radius: 20px;
}

.project-goals-tt {
  border: 2px solid #eee;
  padding: 20px;
  margin-top: 20px;
  font-weight: 550;
  font-family: "Fjalla One", sans-serif;
  font-size: 22px;
  margin-bottom: 20px;
}

.goals-obj-dis-tt {
  margin-bottom: 20px;
}

.project-comment-form label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #2e2e2e;
  text-transform: capitalize;
}

.project-comment-form i {
  color: #e1a241;
}

.comment-count-sort {
  display: flex;
  flex-wrap: nowrap;
}

.comment-count-sort h4 {
  flex: 1;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  text-transform: capitalize;
}

.comment-sorting span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #2e2e2e;
  opacity: 0.75;
}

.display-comment-values .card {
  box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  border: none;
}

.idea-display {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #1d1d1d;
}

.display-comment-values .card-body h4 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #2e2e2e;
  margin-top: 20px;
}

.display-comment-values .card-body h4 span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 24px;
  line-height: 28px;
  color: #ff9b00;
}

.display-comment-values .card-body p {
  font-family: "PT Sans Narrow", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 29px;
  color: #000000;
}

/* .display-list-items {
  margin-top: 25px;
} */

.display-list-items span {
  line-height: 2;
}

.display-list-items span::first-letter {
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
}

.display-list-items .line-app {
  padding-right: 25px;
  font-weight: bold;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: 5px;
  font-size: 1.2em;
  margin-top: 10px;
  margin-left: -40px;
  position: relative;
  top: 60px;
  letter-spacing: 0px;
}

.edit-need {
  color: #047C7C;
  border: 1px solid #047C7C;
  width: 150px;
  height: 30px;
  border-radius: 2px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}

.profile-core-values .add-button-need {
  position: absolute;
  right: 50px;
}

.profile-core-values .card {
  margin-top: 10px;
}

.need-display {
  color: #1d1d1d;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.display-list-items .need-display::first-letter {
  text-transform: uppercase !important;
  font-family: "Roboto", sans-serif;
}

.purpose-needs {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #FF9B00;
  padding-right: 0px;
}

.needs-purpose-display {
  font-family: "Roboto", sans-serif;
  color: #000000;
  opacity: 0.6;
  font-size: 18px;
  margin-left: -10px;
}

.display-list-items .needs-purpose-display::first-letter {
  text-transform: uppercase !important;
  font-family: "Roboto", sans-serif;
}

.goal-title {
  color: #2E2E2E !important;
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  padding-left: 10px;
}

.down-btn-goal {
  font-size: 30px;
  color: #000;
  outline: none !important;
  font-weight: 800;
}

#goalAccordian .card {
  border: none !important;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom: 30px;
}

.objective_container {
  font-weight: 600 !important;
  font-family: "Roboto", sans-serif !important;
}

.s-project-description {
  margin-top: 50px;
}

#goalAccordian .card p {
  font-family: "Roboto", sans-serif;
  color: #000;
  font-weight: 300;
}

#goalAccordian .card .btn {
  position: relative;
  top: 10px;
}

.edit-goal-new-button {
  z-index: 0 !important;
  max-height: 40px !important;
  margin-top: 18px !important;
}

.edit-kpi {
  position: absolute !important;
  right: 0px !important;
}

.button-goal-collapse .btn:focus {
  outline: none !important;
  box-shadow: 0 0 0 0.2rem #f8f9fa !important
}

.objective_container .objective-title {
  background-color: purple;
  padding: 5px;
  color: #fff;
  border-radius: 5px;
  margin-right: 10px;
  text-transform: uppercase;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
}


.button_group {
  right: 12px;
  position: absolute;
  margin-top: -32px;
  z-index: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.button_group_goal {
  z-index: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}


.objective-bar {
  background-color: purple;
  padding: 8px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 20px;
  border-radius: 5px;
}

.objective-it-self {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;

}

.objective-card {
  display: flex !important;
}

#goalAccordian {
  margin-bottom: 10px;
}

#goalAccordian .card-body {
  border-left: 2px solid #c3bcbc;
}

#goalAccordian .card-footer {
  border-left: 2px solid #c51db4 !important;
}


.edit-project-goal {
  color: #2E2E2E;
  font-size: 15px;
}

.project-goal-display {
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 62px;
  color: #2E2E2E;
  font-family: "Roboto", sans-serif;
  margin-left: 0px;
  display: flex;

}

.goal-bar-display {
  background-color: purple;
  padding: 8px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 20px;
  border-radius: 5px;
  max-height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10px;
  font-size: 15px;
}


.objectives-display {
  margin-top: 25px;
}

.objectives-title {
  color: #2E2E2E !important;
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  padding-left: 10px;
  position: relative;
}

.down-button-support {
  position: relative;
  margin-left: 10px;
}

#loading-button-project {
  min-width: 200px;
  min-height: 50px
}

.show-the-needs {
  color: #1d1d1d;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
}

.display-sdgs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.display-sdgs img {
  height: 100px;
}

.remove-sub-project {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.remove-sub-project i {
  font-size: 30px;
  font-weight: bolder;
}

/* ==== PROJECT DISCUSSIONS ==== */
.discussion-invite-area {
  margin-top: 60px !important;
  margin-bottom: 100px !important;
}

.invitation-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px !important;
  width: 90%;
}

.invitation-title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #2E2E2E;
}

.Invitation-form {
  margin-top: 30px;
}

.discussion-display-container {
  width: 100%;
}

.discussion-card-area {
  margin-top: 20px !important;
  display: flex;
  flex-wrap: wrap;

}


.dis-profile-creator {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
}

.dis-created-by {
  margin-top: 15px;
}

.dis-created-by span {
  font-family: "Roboto", sans-serif;
  color: #1d1d1d !important;
  font-weight: 400;
  font-size: 16px;
}

.sb-like-comment-btn .fa {
  font-size: 30px;
  color: grey;
}

.modal-size {
  width: 90% !important;
  min-width: 90% !important;
  overflow-y: auto;
}

.comment-discussion-card {
  margin-top: -8px !important
}

.tt-discussion-comment-form {
  margin-top: 20px;
}

.form-control-text-area {
  border: 1px solid rgba(46, 46, 46, 0.5);
  border-radius: 8px;
  width: 100%;
}

.divide-form-replies {
  margin-top: 70px;
}

.project-comment-replies-container {
  margin-top: 10px;
}

.number-of-comments h5 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;


  color: #2E2E2E;

}

.main-comments-section {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 50px;
  margin-bottom: 50px;
}

.image-part-main img {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  flex: 1;
}

.diolog-section {
  flex: 2;
  padding-left: 30px;
}

.diolog-section p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: #2E2E2E;
  opacity: 0.6;
}

.main-reply-buttons {
  margin-top: -50px;
}

.form-control-text-area-reply {
  width: 90%;
  border: 1px solid rgba(46, 46, 46, 0.5);
  border-radius: 8px;
  margin-left: 110px;
}

.reply-card-main {
  width: 98%;
  margin-left: 30px;
}

.reply-card-main .main-comments-section {
  margin-top: 70px !important;
}

.reply-card-main .diolog-section p {
  margin-left: -20px;
}

.creation-date-mh {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.creation-date-mh span {
  font-size: 18px;
  color: green;
  font-weight: bolder;

}

.creation-date-mh h6 {
  padding: 5px;
}

.display-down {
  display: flex;
  justify-content: center;
}

.display-down i {
  font-size: 30px;
  color: #e0871c;
}

.display-project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.projects-blocks {
  text-align: center;
  display: flex;
  width: 350px;
  height: 250px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 25px;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 5px;
  margin-top: 10px;
  cursor: pointer;
  background-color: grey;
  box-shadow: -4px -1px 19px 7px rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: -4px -1px 19px 7px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: -4px -1px 19px 7px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}

.project-container-header-name {
  padding-left: 15px;
}

.projects-blocks h4 {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
}

.project-categories-header {
  color: #2E2E2E;
  font-family: "Roboto", sans-serif;
  position: relative;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 28px;
  top: 10px;
}

.project-container-header-button {
  position: relative;
  top: 10px;
}

.tit i {
  color: #047C7C;
  font-size: 35px;
  font-weight: 600;
  cursor: pointer;
}

.tit span {
  font-size: 25px;
  text-transform: capitalize;
  margin-left: 10px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}

.underline {
  margin-top: 15px;
  background: linear-gradient(170deg, #01e4f8 0%, #1d3ede 100%);
  height: 5px;
  width: 150px;
  border-radius: 20px;
}

.name-of-category {
  position: absolute;
  left: 45%;
  margin-top: 10px;
}

.name-of-category h3 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
}

/* drop down menu */

.dropdown-menu {
  min-width: 250px !important;
  min-height: 300px !important;
  left: -50px !important;
}

.dropdown-item {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 300 !important;
  font-size: 18px;
  cursor: pointer;
}

.dropdown-item i {
  padding-left: 20px;
  padding-right: 10px;
  font-size: 22px;
  font-weight: 900;
}

.fix-sizes-on-plus {
  font-size: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
}

.project-category-header-container {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgb(112 144 176 / 10%);
  height: 100px;
}

.project-dis-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.project-container-header-name {
  flex: 1
}

/* .display-ass-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
} */

.asss-disp {
  height: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.project-card-buttons {
  display: flex;
  position: absolute;
  bottom: 10px
}

.un-follow-btn {
  background: yellowgreen !important;
}

.follow-btn {
  background: orangered !important;
}

.upvoted_group_container {
  position: relative;
  bottom: -20px;

  left: 14px;
}

/* .MuiAvatar-root {
  width: 30px !important;
  height: 30px !important;
} */



/* <==== Project media queries ====> */

@media (min-width: 1388px) {
  .display-project-container {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }

  .card-container-display {
    padding-left: 2% !important;
    padding-right: 2% !important;
  }

}