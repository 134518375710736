@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

body {
  background-color: #EFF0F1;
}

.category-header {
  padding: 16px;
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-weight: 600;
}

.category-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 8px;
}

.category-crd {
  width: 260px;
  height: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin: 8px;
  padding: 8px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.category-img-cont {
  width: 245px;
  height: 135px;
  border-radius: 5px;
  border: 1px solid #EFF0F1;
}

.category-img-cont img {
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
}

.category-crd-title {
  font-size: 18px;
}

.category-crd-btn {
  border: 1px solid #047C7C;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  color: #047C7C!important;
}

.category-crd-btn:hover {
  background-color: #047C7C;
  color: #ffffff !important;
}
 