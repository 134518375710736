@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

.project-top-ten-main {
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 60px;
}

.title-bac-ground-10 {
  background-color: #000000;
  width: 100%;
  height: 50px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
}

.title-bac-ground-10 h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
}

.project-tt-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* ==== DISPLAY BANNER ==== */
.display-top-10-banner {
  width: 100%;
  background: #eee;
  min-height: 200px !important;
  height: 200px !important;
  box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  border-radius: 3px;
  overflow-y: scroll;
}

.back-to-t0p-10s {
  position: relative;
  top: -20px;
}

.edit-top-entrty-btn {
  z-index: 0;
}

.banner-header-top10 {
  width: 100%;
  min-height: 50px;
  height: 50px;
  background-color: #fff;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
}

.entry-add-button {
  position: absolute !important;
  right: 30px !important;
}

.top-10-actual-description {
  color: #2e2e2e;
  font-size: 18px;
  font-weight: 300;
  padding: 8px;
}

.top-10-decription-title {
  color: purple;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  margin-left: 10px;
  position: relative;
  top: 1px;
  text-transform: uppercase;
}

.top-10-name-display {
  color: #000 !important;
  font-weight: 900 !important;
  text-decoration: underline;
  font-family: 'Anek Telugu', sans-serif !important;
  font-size: 1.4rem !important;
  top: 3px !important;
  position: relative;
  text-transform: uppercase;
  margin-left: 10px;
}



.project-tt-header button:hover {
  border: 1px solid #309897;
  background: #F7F7F7;
  color: #309897;
}

.project-top-ten-card {
  width: 400px;
  margin: 16px;
  height: 450px;
  background: #f4f2f2;
  border-radius: 8px;
}

.project-top-ten-card-header {
  height: 80px;
  background: #2a2e43;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.project-top-ten-card-header-text {
  position: relative;
  top: 25px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}

.header-split {
  position: relative;
  bottom: 19px;
  height: 5px;
  background: rgb(102, 94, 255);
  background: linear-gradient(141deg,
      rgba(102, 94, 255, 1) 0%,
      rgba(87, 115, 255, 1) 23%,
      rgba(52, 151, 253, 1) 66%,
      rgba(58, 204, 225, 1) 87%,
      rgba(58, 204, 225, 1) 100%);
}

.project-top-ten-card-list {
  padding: 8px;
  position: relative;
  display: flex;
  flex-direction: row;
  bottom: 35px;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
}

.card-list-item {
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  font-size: 1.12rem;
  line-height: 2.5;
  font-family: "Roboto", sans-serif;
  color: #000000;
}

.project-top-ten-card-buttons {
  position: relative;
  bottom: 30px;
  display: flex;
  width: 100%;
}

.project-top-ten-card-buttons button {
  margin: 6px;
  height: 40px;
  color: #f4f2f2;
  border-radius: 4px;
  border: unset;
}

.ptt-exp-btn {
  width: 70%;
  background: #309897;
}

.ptt-exp-btn:hover {
  border: 1px solid #309897;
  color: #309897;
  background: #ffffff;
}

.ptt-edit-btn {
  width: 30%;
  color: #f4f2f2;
  background: #5590d9;
}

.ptt-edit-btn:hover {
  border: 1px solid #5590d9;
  color: #5590d9;
  background: #f4f2f2;
}

.project-tt-entry-card {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d5d0d0;
}

.project-tt-entry-card-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  padding: 10px;
  flex-wrap: nowrap;
}

.project-entry-name-tt {
  flex: 2;
}

.project-tt-entry-card-header-idx {
  background: #309897;
  border-radius: 4px;
  color: #fff;
  padding: 5px 15px 5px 15px;
  margin-right: 15px;
}

.project-tt-entry-card-details {
  width: 100%;
  padding: 8px;
  height: 0;
  animation-name: details-show;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

.extend-c {
  height: 250px !important;
}

@keyframes details-show {
  0% {
    height: 0;
  }

  100% {
    height: 164px;
  }
}

.p-ttdet-btn {
  border: unset;
  border-radius: 17px;
  background: #C4C4C4;
  color: #fff;
  height: 34px;
  flex: 1;
}

.p-ttdet-btn:hover {
  background: #309897;
}

.project-tt-entry-card-details-info {
  width: 95%;
  height: 144px;
  background: #F7F7F7;
  margin-left: auto;
  margin-right: auto;
}

.extend {
  height: 200px !important;
}

.project-tt-entry-card-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
}

.project-tt-entry-card-btns button {
  font-size: 20px;
  background: unset;
  border: unset;
  color: #9d9898;
}

.ptt-e-creator {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 800;
}

.pp-t-users {
  position: relative;
  bottom: 22px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: scroll;
  width: 250px;
}

.value-title {
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  font-size: 16px;
}

.value-display-container {
  padding: 14px;
}

.entry-values-tt {
  color: #1d1d1d;
  font-family: "Roboto", sans-serif;
  color: #000000;
  opacity: 0.6;
  font-size: 18px;
  padding-left: 10px;
}

/* ==== Media queries ==== */

@media (max-width: 600px) {
  .project-tt-header {
    margin-top: 80px;
  }
}

/* ========= MOBILE DESIGN ===== */
@media (max-width: 801px) {
  .entry-add-button {
    top: 66px;
    margin-top: unset;
    left: 2px;
    position: absolute;
    right: 2px !important;
  }

  .display-top-10-banner {
    margin-top: 12px;
  }

  .banner-header-top10 {
    flex-wrap: wrap;
    margin-top: 10px;
    height: unset !important;
  }

  .project-tt-entry-card {
    margin-bottom: 50px;
  }

  .project-tt-entry-card-header {
    display: contents !important;
  }

  .project-entry-name-tt {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .project-tt-entry-card-btns {
    margin-bottom: 7px;
    border-top: 1px solid #eee;
    padding-top: 10px;
  }
}