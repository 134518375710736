@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

.project-sb-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project-sb-header button {
  position: relative;
  top: 15px;
  right: 25px;
  height: 30px;
  border: unset;
  background: #309897;
  color: #fff;
  font-family: "Roboto", sans-serif;
  border-radius: 6px;
}

.project-sb-header button:hover {
  border: 1px solid #309897;
  background: #F7F7F7;
  color: #309897;
}

.project-sug-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 6px;
}

.project-sug-body {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
}

.project-sug-extras {
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100%;
  padding: 8px;
}

.project-sug-card-fix {
  width: 49% !important;
}

.project-suggestions-images {
  display: flex;
  justify-content: center;
}

.project-suggestions-images img {
  height: 100px !important;
}

.sb-banner {
  width: 100%;
  height: 250px;
  padding: 6px;
}

.sb-banner img {
  width: 100%;
  height: 280px;
  border-radius: 6px;
}

.project-sb-card {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 356px;
  padding: 16px;
  margin-top: 20px;
  border-radius: 16px;
  background: #fffcfc;
  flex-wrap: wrap;
}

.project-sb-card-head {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40%;
}

.p-sb-left {
  width: 10%;
  height: 100%;
  padding: 25px 20px 0 20px;
}

.p-sb-mid {
  width: 75%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.p-sg-title {
  display: flex;
  flex-direction: row;
}

.p-sg-title-div {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
}

.p-sg-title span {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  line-height: 40px;
}

.p-sg-title img, .psb-idea-icon {
  width: 30px;
  height: 30px;
}

.p-sg-sub-title span {
  position: relative;
  left: 30px;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 21px;
}

.p-sg-tags {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  padding-top: 20px
}

.p-sb-right {
  width: 15%;
  height: 100%;
}

.p-sb-right span {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: italic;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: right;
  color: rgb(57, 56, 56);
}

.project-sb-card-details {
  width: 100%;
  height: 45%;
  overflow-y: scroll;
}

.project-sb-card-details p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 12.5%;
}

.project-sb-card-footer {
  width: 100%;
  height: 15%;
}

.project-sb-card-footer-btns {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-left: 12.5%;
  padding-right: 12.5%;
  justify-content: space-between;
}

.project-sb-card-footer-btns button {
  font-family: "Roboto", sans-serif;
  border: unset;
  background: unset;
  font-size: 20px;
  cursor: pointer;
}

.project-sb-card-footer-btns button span {
  font-size: 16px;
  font-weight: 200;
  font-style: italic;
  color: #8e8b8b;
}

.project-sb-comments {
  position: relative;
  bottom: 14px;
  width: 100%;
  height: 600px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background: #fffcfc;
  z-index: 1;
  animation-name: comments-show;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  overflow-y: scroll;
}

@keyframes comments-show {
  0% {
    height: 0;
  }

  100% {
    height: 600px;
  }
}

.project-sb-comment-form {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

.project-sb-comment-form input {
  font-style: italic;
  border-radius: 18px !important;
}

.project-sb-comment-form button {
  position: relative;
  top: 1px;
  height: 38px;
  margin-left: 10px;
  border: unset;
  background: #309897;
  color: #fff;
  font-family: "Roboto", sans-serif;
  border-radius: 18px;
}

.p-sb-br {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.p-sb-comment-entry {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  width: 100%;
  height: 170px;
}

.p-sb-comment-entry-top {
  display: flex;
  flex-direction: row;
  height: 120px;
}

.p-sb-comment-entry-top-left {
  width: 10%;
  height: 100%;
  padding: 10px 0 0 40px;
}

.p-sb-comment-entry-top-center {
  width: 80%;
  height: 100%;
  overflow-y: scroll;
  padding: 8px;
}

.p-sb-comment-entry-top-right {
  width: 10%;
  height: 100%;
  text-align: right;
  padding-right: 6px;
}

.p-sb-comment-entry-top-right span {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
}

.p-sb-comment-entry-btns {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-left: 14.5%;
  padding-right: 14.5%;
  justify-content: space-around;
}

.p-sb-comment-entry-btns button {
  font-family: "Roboto", sans-serif;
  border: unset;
  background: unset;
  font-size: 18px;
  cursor: pointer;
}

.p-sb-comment-entry-btns span {
  font-size: 14px;
  font-weight: 200;
  font-style: italic;
  color: #8e8b8b;
}

/*==MEDIA QUERIES==*/

@media only screen and (max-width: 1110px) {
  .p-sg-title span {
    font-size: 18px;
    line-height: 20px;
    padding-left: 8px;
  }

  .p-sg-sub-title span {
    font-size: 17px;
  }

  .p-sg-title img, .psb-idea-icon {
    width: 20px;
    height: 20px;
  }

  .p-sg-tags {
    padding-top: 10px;
  }

  .p-sb-comment-entry-top-left {
    padding: 10px 0 0 20px;
  }

  .p-sb-comment-entry-top-center {
    width: 70%;
  }

  .p-sb-comment-entry-top-right {
    width: 20%;
  }

  .p-sb-comment-entry-top-right span {
    line-height: 10px;
    font-size: 12px;
  }

  .sb-banner, .sb-banner img {
    height: 200px;
  }

}

@media only screen and (max-width: 770px) {
  .p-sb-comment-entry-top-left {
    padding: 5px 0 0 10px;
  }

  .project-sug-body {
    width: 100%;
  }

  .project-sug-extras {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .project-sb-header h2 {
    font-size: 24px;
    font-weight: 800;
  }
}

@media only screen and (max-width: 600px) {
  .project-sb-header {
    margin-top: 88px !important;
  }
}