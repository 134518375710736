@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

.project_event_display {
    width: 100%;
    height: 500px;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    background: #eee;
    max-width: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
    text-overflow: ellipsis;
}

.project_event_display_title {
    background: #fff;
    text-align: center;
    color: purple;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    text-transform: uppercase;
    padding: 10px;
    border-radius: 8px;
}

.project_event_display_title>span {
    margin-left: 10px;
}

.to_do_icon {
    color: black !important;
    font-weight: 900;
}

.footer_list_container {
    position: relative;
    bottom: -200px;
    right: -45px;
}

.form_detail_cont_title {
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    color: purple;
    text-decoration: underline;
}

.form_detail_cont {
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    font-weight: 900;
}

.add_profiles_button {
    margin-top: -10px;
    margin-left: 20px;
    cursor: pointer;
}

.display_profile_image_list_cont {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
}

.select_user_button {
    position: relative;
    right: -90px;
}

.event_title {
    font-family: 'Quicksand', sans-serif;
    font-size: 18px !important;
    font-weight: 400 !important;
    text-wrap: wrap;
    text-overflow: ellipsis;
}

.event_container_item {
    padding: 15px;
}

.event_container_item:hover {
    background-color: purple;
    cursor: pointer;
    color: #fff;
    width: 100%;
    border-radius: 20px;
    padding-left: 10px;
    margin-left: 10px;
    margin-top: 5px;
}

.task_name_field {
    width: 100% !important;
}

.no_event_container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 100px;
    color: black;
    font-size: 30px;
    font-family: 'Anek Telugu', sans-serif !important;
    font-weight: 900;
}

.icon_no_item {
    font-size: 35px;
    font-weight: 900;
    margin-right: 10px;
    color: purple;
}

.open_item_title_con {
    font-family: 'Anek Telugu', sans-serif !important;
    font-weight: 700;
    color: #1c0202;
    font-size: 28px;
    margin-bottom: 30px;
    text-decoration: underline;
    margin-top: 25px;
}

.bread_crumbs_cont {
    margin-bottom: 15px;
}

.list_test_name_il {
    color: #000;
    font-weight: 900 !important;
    font-family: "Roboto", sans-serif !important;
}

.list_test_name_date {
    color: #918d8d;
    font-family: 'Quicksand', sans-serif !important;
    font-weight: 400;
    font-size: 17px !important;
}

.tabselection_container {
    margin-top: 30px;
    width: 100%;
    min-height: 400px;
    max-height: 400px;
    overflow-y: scroll;
}

.open_item_description_container {
    width: 100%;
    min-height: 100px;
    background-color: rgb(199, 227, 227);
    border-radius: 10px;
    color: purple;
    font-family: "Roboto", sans-serif !important;
    padding: 10px;
    font-size: 17.5px;
}

.date_picker_display {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
}

/* width */
.tabselection_container ::-webkit-scrollbar {
    width: 10px !important;
    visibility: visible !important;
}

/* Track */
.tabselection_container ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
    visibility: visible !important;
}

/* Handle */
.tabselection_container ::-webkit-scrollbar-thumb {
    background: #888 !important;
    visibility: visible !important;
}

/* Handle on hover */
.tabselection_container ::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
    visibility: visible !important;
}

.add_comment_btn {
    position: relative;
    top: -14px;
    cursor: pointer;
    left: 50%;
}

.loading_text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
}

.loading_text>h6 {
    color: purple;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px !important;
}

.creator_name {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 800 !important;
    font-size: 20px !important;
}

.mobile_button_event {
    display: none !important;
}

@media (max-width: 801px) {
    .project_event_display {
        display: none !important;
    }

    .mobile_button_event {
        display: inline !important;
        margin-top: 20px !important;
    }

    .mobile_button_event {
        margin: 10px;
    }

    .calender_container {
        margin-top: 30px;
    }
}