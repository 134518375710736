.categories-body{
  width: 100%;
}

#categories-title {
  position: relative;
  width: 80%;
  left: 27px;
  bottom: 20px;
}

#categories-title h4 {
  font-size: 2rem;
  font-family: 'Fjalla One', sans-serif;
  margin-left: 0;
  margin-bottom: 0;
}

.category {
  padding: 30px 0 40px;
  margin-bottom: 140px;
  max-width: 248px;
  min-width: 248px;
  background-color: #ffffff;
  text-align: center;
  overflow: hidden;
  position: relative;
  height: 293px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 5px 8px 13px -13px rgba(0,0,0,0.75);
  background: linear-gradient(to left bottom, #ff9b00 48%,#212529 50%);
  color: white;
}

.category .picture {
  display: inline-block;
  height: 130px;
  width: 130px;
  margin-bottom: 50px;
  z-index: 1;
  position: relative;
  object-fit: cover;
}

.category .picture::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 40%;
  background-color: #f3c02f40;
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.9;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.category:hover .picture::before {
  height: 100%;
}

.category .picture::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #f3c02f99;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.category .picture img {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
  object-fit: cover;
  background-color: #fff;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.category:hover .picture img {
  box-shadow: 0 0 0 14px #ffffff;
  transform: scale(0.7);
}

.category .title {
  display: block;
  font-size: 15px;
  color: #4e5052;
  text-transform: capitalize;
}

.category-content {
  position: relative;
  bottom: 41px;
}

.category-content h3 {
  font-family: 'PT Sans Narrow', sans-serif;
}

.category-content h4 {
  font-family: 'Ubuntu', sans-serif;
}

.picture-bottom {
  width: 100%;
  margin-left: 4px;
  bottom: 17px;
  margin-top: 0;
  position: relative;
}

.view {
  color: #6a6a6a;
  cursor: pointer;
}

.view .btn {
  background-color: #f3c02f;
  font-size: 12px;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 600;
  box-shadow: -4px 2px 10px -5px rgba(0,0,0,0.75);
  border-radius: 30px 30px 30px 30px;
  height: 25px;
  padding-top: 3px;
  padding-left: 7px;
  margin-bottom: 6px;
  padding-right: 13px;
  color: #10050591;
}

.view:before,
.view:after{
  content:" ";
  width: 20%;
  height: 1px;
  margin: 0 10px;
  vertical-align: super;
  background-color:#80808087;
  display:inline-block; 
}
