.MobileDisplay {
    display: none;
}

.sticky_sec {
    position: sticky;
    top: 0;
    z-index: 0;
    margin-bottom: 40px;
}

.categories_container {
    margin-top: 40px;
    background: #FFFFFF;
    box-shadow: 0px 5px 14px -3px rgba(0, 0, 0, 0.11);
}

.card_header {
    font-weight: 800 !important;
    font-weight: 700;
    color: #222222;
}

.img_thumbnail {
    height: 40px;
    width: 40px;
    border: 1px solid #807e7e;
    padding: 4px;
}

.category_name {
    margin-left: 10px;
    font-weight: 600;
    font-family: 'Fjalla One', sans-serif;
    font-size: 20px;

}

.category_count {
    position: absolute;
    right: 5px;
    margin-top: 2px;
    font-weight: 800;
}

.category_item_container {
    padding: 10px;
    cursor: pointer;
    width: 100%;
}

.category_item_active {
    padding: 10px;
    cursor: pointer;
    width: 100%;
    background-color: purple;
    color: white;
}

.category_item_container :hover {
    cursor: pointer;
    background-color: purple;
    color: white;

}

.projects_container {
    margin-top: 40px;
}

.pinned_projects_container {
    margin-top: 40px;
}

.search_bar_area {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
}

.create_new_project_button {
    margin-left: 20px;
}

.project_item {
    margin-top: 20px;
    margin-bottom: 10px;
}

.pinned_container {
    margin-top: 10px;
    width: 100%;
    border: 2px solid purple;
    min-height: 80px;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


/* === MOBILE AND RESPONSIVENESS === */

@media (max-width: 801px) {
    .MobileDisplay {
        display: inline;
    }

    .desktopDisplay {
        display: none;
    }

}