/* New auth features*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,700;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

/* ==to be removed == */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
/* === React Notifications === */
@import 'react-notifications/lib/notifications.css';

*:focus {
  outline: none;
}

.nav-bar-color-fix .navbar-toggler {
  background-color: #000;
}


.top-ten-cont {
  margin-top: 94px;
}

.nav-bar-color-fix a {
  padding: 10px;
  color: #2e2e2e !important;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.top-ten-image img {
  width: 100%;
  height: 400px;
  object-fit: contain;
  margin-top: -30px;
}

.top-ten-image .btn-view-tt {
  margin-top: -20px;
}

/* ==to be removed == */

.e-margin {
  margin-top: 250px;
}

.text-orange {
  color: #ff9b00;
}

.logo-one {
  margin: 16px;
  height: 58px;
  width: 120px;
}

textarea {
  min-height: 160px !important;
  font-style: normal !important;
}

.auth-col {
  background-color: #ff9b00;
}

.auth-col,
.auth-col-II {
  font-family: "Ubuntu", sans-serif;
}

.auth-col-height {
  height: 100vh;
}

.auth-btn-one {

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  background-color: #ff9b00;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: unset;
  color: #ffffff;
}

.app-auth-form-error {
  color: red;
  font-weight: bold;
  font-family: 'Nunito Sans';
  position: absolute;
  font-size: 13px;
}

.register-screen {
  width: 100%;
  background-repeat: no-repeat;
  background-image: url("../assets/together.jpg");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.tuc-logo-signup {
  background: #D99334;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 0px;
  left: 0;
  border-radius: 35px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.register-screen h1 {
  text-align: center;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;

  color: #FFFFFF;
}

.account-quiz {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #828282;
}

.account-signin-register a {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  text-decoration-line: underline;

  color: #FF9B00;
}

.app-label {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #828282;
}

.sign-in-register {
  margin-bottom: 20px;
}

.register-content-form h1 {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  text-align: center;

  color: #525252;
}


.sign-in-register {
  text-align: center;
}

/* New auth End */

.fc .fc-toolbar-title {
  font-weight: 900 !important;
  color: purple !important;
  text-transform: uppercase !important;
  font-size: 1.4em !important;
}

.edit_button_class {
  position: relative;
  text-align: right;
}

.loading-sp {
  color: #ff9b00;
}

.no-deco {
  text-decoration: none;
}

/* === New css by peter === */

.sidenav {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #000;
  overflow-x: hidden;
  padding-top: 60px;
  min-width: 230px;
  max-width: inherit;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 16px;
  color: #ddd;
  display: block;
  line-height: 28px;
  font-family: "Ubuntu", sans-serif;
  transition: 0.3s;
}

.transform-div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  width: 100%;
  background: linear-gradient(to right bottom, #ff9b00 48%, #000000f2 49%);
}

.categories-link-nav {
  font-size: 18px;
  position: relative;
  top: -10px;
  left: -50px;
}

#profile-image-nav {
  position: relative !important;
  left: unset !important;
  top: unset !important;
  bottom: 30px;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  border: solid;
  text-transform: capitalize;
}

.main {
  padding-left: unset !important;
  padding-right: unset !important;
}

.nav-div {
  padding-right: unset !important;
  padding-left: unset !important;
}

#dropdown-icon {
  position: relative;
  color: #fff;
  top: 8px;
  left: 6px;
}

.view {
  color: #6a6a6a;
  cursor: pointer;
  margin-top: 20px;
}

#user-name-navbar {
  display: contents;
  flex-wrap: nowrap;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
}

.view:before,
.view:after {
  content: " ";
  width: 64px;
  height: 1px;
  margin: 0 10px;
  vertical-align: super;
  background-color: #80808087;
  display: inline-block;
}

.v-btn {
  background-color: #ff9b00;
  font-size: 12px;
  align-items: center;
  font-family: "Ubuntu", sans-serif;
  font-weight: 600;
  border-radius: 30px;
  height: 25px;
  padding: 8px;
  color: #1b191991;
}

.v-btn:hover {
  text-decoration: none;
  color: #151515;
}

/* ==TOP TEN LIST == */

.top-ten-list {
  overflow: hidden;
}

.top-ten-list-body {
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.top-ten-title h4 {
  font-size: 2rem;
  font-family: "Fjalla One", sans-serif;
  padding: 50px;
}

.top-card {
  width: 420px;
  margin: 6px;
  padding: 20px;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
  background: #ffffff;
  color: #000000;
  box-shadow: 0px 4px 11px 3px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.filter {
  width: 100%;
}

.card-entry-container .card-entry {
  text-align: left;
  line-height: 2;
  white-space: nowrap;
  overflow-x: scroll;
  font-size: 1.12rem;
  font-family: "Roboto", sans-serif;
  padding-top: 5px;
  padding-left: 25px;
  color: #000000;
}

.card-entry::-webkit-scrollbar {
  display: none;
}

/* ==TOP TEN LIST == */

/* === End css by peter === */

/* ==== Css by El ==== */

/*Connecting Project*/

.project-link-div {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
}

.project-link-div span {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
}

.project-link-div button {
  height: 30px;
  padding: 0 8px 0 8px !important;
  border: unset;
  background: #309897;
  color: #fff;
  font-family: "Roboto", sans-serif;
  border-radius: 6px;
}

/*Connecting Project End*/

.add-btn {
  background-color: #047C7C;
  color: rgb(48, 46, 46);
  text-align: center;
  height: 45px;
  width: 120px;
  padding-top: 2px;
  margin-bottom: 9px;
  padding-left: 9px;
  font-family: "Ubuntu", sans-serif;
  border: none;
}

.add-btn-plus {
  display: flex;
  font-size: 40px;
  justify-content: center;
  position: relative;
  top: -13px;

}

.space-line {
  width: 95%;
  margin-top: 60px;
}

.entry-container {
  width: 95%;
}

.entry-list-cont-header {
  background-color: #ddf5fe;
  color: rgb(48, 46, 46);
  width: 100%;
  height: 15%;
}

.all-entries-container {
  background-color: #f3fbfe;
  width: 100%;
  height: 85%;
  overflow-y: scroll;
  overflow-x: scroll;
}

.add-entry-btn {
  background-color: #2bbcf345;
  color: rgb(48, 46, 46);
  text-align: center;
  height: 39px;
  width: 130px;
  padding-top: 5px;
  margin-bottom: 9px;
  padding-left: 9px;
  font-family: "Ubuntu", sans-serif;
  border: none;
}

.num {
  background-color: #2bbcf345;
  padding: 10px;
}

/* ==side nav bar logout btn == */

.btn-logout {
  font-size: 16px;
  margin-left: 26px;
  color: #ddd;
  display: block;
  line-height: 28px;
  font-family: "Ubuntu", sans-serif;
  background: unset;
  border: unset;
  margin-top: 8px;
}

#close-btn {
  background: unset;
  position: relative;
  top: -50px;
  left: 190px;
  font-size: 25px;
  border: unset;
  color: #fff;
}

#open-btn {
  position: absolute;
  top: -6px;
  font-weight: bolder;
  left: -32px;
  background: unset;
  border: unset;
  font-size: 25px;
}

#logout-btn {
  position: absolute;
  right: -30px;
  background: unset;
  border: unset;
  font-size: 25px;
  top: -5px;
  display: none;
}

/* === Voting === */

.voting-container {
  position: absolute;
  border: unset;
}

.voting-btn {
  text-align: right;
  right: 5px;
  position: absolute;
  display: flex;
  border: unset;
  border-radius: 20px;
  background: unset;
  font-size: 18px;
  color: #6d6969;
}

.voting-hrt-white {
  color: #fff;
}

.voting-hrt-red {
  color: rgb(221, 43, 43);
}

/* === End css by El === */

@media (max-width: 768px) {
  .home-wallpaper {
    height: 350px;
    width: 400px;
  }

  .top-ten-card {
    margin-left: auto;
    margin-right: auto;
  }

  .auth-col-height {
    margin-bottom: 150px;
  }
}

@media (max-width: 1400px) {
  .sidenav {
    display: none;
  }

  #open-btn {
    display: inline-block;
  }

  #logout-btn {
    display: inline-block;
  }
}

/* == Peter css For card Entries === */

.entry-container button,
input {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

.entry-container a {
  color: #f96332;
}

.entry-container a:hover,
a:focus {
  color: #f96332;
}

.entry-container p {
  line-height: 1.61em;
  font-weight: 500;
  font-size: 1.35em;
  color: #000;
}

.entry-container .category {
  text-transform: capitalize;
  font-weight: 700;
  color: #9a9a9a;
}

.entry-container {
  color: #2c2c2c;
  font-size: 14px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-y: hidden;
}

.entry-container .nav-item .nav-link,
.nav-tabs .nav-link {
  -webkit-transition: all 300ms ease 0s;
  -moz-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  -ms-transition: all 300ms ease 0s;
  transition: all 300ms ease 0s;
  cursor: pointer;
}

.entry-container .card a {
  -webkit-transition: all 150ms ease 0s;
  -moz-transition: all 150ms ease 0s;
  -o-transition: all 150ms ease 0s;
  -ms-transition: all 150ms ease 0s;
  transition: all 150ms ease 0s;
}

[data-toggle="collapse"][data-parent="#accordion"] i {
  -webkit-transition: transform 150ms ease 0s;
  -moz-transition: transform 150ms ease 0s;
  -o-transition: transform 150ms ease 0s;
  -ms-transition: all 150ms ease 0s;
  transition: transform 150ms ease 0s;
}

[data-toggle="collapse"][data-parent="#accordion"][aria-expanded="true"] i {
  filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.now-ui-icons {
  display: inline-block;
  font: normal normal normal 14px/1 "Nucleo Outline";
  font-size: inherit;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@-webkit-keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes nc-icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.now-ui-icons.objects_umbrella-13:before {
  content: "\ea5f";
}

.now-ui-icons.shopping_cart-simple:before {
  content: "\ea1d";
}

.now-ui-icons.shopping_shop:before {
  content: "\ea50";
}

.now-ui-icons.ui-2_settings-90:before {
  content: "\ea4b";
}

.entry-container .nav-tabs {
  border: 0;
  padding: 15px 0.7rem;
}

.entry-container .nav-tabs:not(.nav-tabs-neutral)>.nav-item>.nav-link.active {
  box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
}

.entry-container .card .nav-tabs {
  border-top-right-radius: 0.1875rem;
  border-top-left-radius: 0.1875rem;
}

.entry-container .nav-tabs>.nav-item>.nav-link {
  color: #888888;
  margin: 0;
  margin-right: 5px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 30px;
  font-size: 14px;
  padding: 11px 23px;
  line-height: 1.5;
}

.entry-container .nav-tabs>.nav-item>.nav-link:hover {
  background-color: transparent;
}

.entry-container .nav-tabs>.nav-item>.nav-link.active {
  background-color: #ff9b00;
  border-radius: 30px;
  color: #212529;
}

.entry-container .nav-tabs>.nav-item>.nav-link i.now-ui-icons {
  font-size: 14px;
  position: relative;
  top: 1px;
  margin-right: 3px;
}

.entry-container .nav-tabs.nav-tabs-neutral>.nav-item>.nav-link {
  color: #ffffff;
}

.entry-container .nav-tabs.nav-tabs-neutral>.nav-item>.nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}

.entry-container .card {
  min-height: 250px;
  border: 0;
  border-radius: 0.1875rem;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

.entry-container .card .card-header {
  background-color: #212529;
  border-bottom: 0;
  border-radius: 0;
  padding: 0;
}

.entry-container .card[data-background-color="yellow"] {
  background-color: #ff9b00;
}

.card[data-background-color="blue"] {
  background-color: #2ca8ff;
}

.card[data-background-color="green"] {
  background-color: #15b60d;
}

[data-background-color="orange"] {
  background-color: #e95e38;
}

[data-background-color="black"] {
  background-color: #2c2c2c;
}

[data-background-color]:not([data-background-color="gray"]) {
  color: #ffffff;
}

[data-background-color]:not([data-background-color="gray"]) p {
  color: #ffffff;
}

[data-background-color]:not([data-background-color="gray"]) a:not(.btn):not(.dropdown-item) {
  color: #ffffff;
}

[data-background-color]:not([data-background-color="gray"]) .nav-tabs>.nav-item>.nav-link i.now-ui-icons {
  color: #ffffff;
}

@font-face {
  font-family: "Nucleo Outline";
  src: url("https://github.com/creativetimofficial/now-ui-kit/blob/master/assets/fonts/nucleo-outline.eot");
  src: url("https://github.com/creativetimofficial/now-ui-kit/blob/master/assets/fonts/nucleo-outline.eot") format("embedded-opentype");
  src: url("https://raw.githack.com/creativetimofficial/now-ui-kit/master/assets/fonts/nucleo-outline.woff2");
  font-weight: normal;
  font-style: normal;
}

.now-ui-icons {
  display: inline-block;
  font: normal normal normal 14px/1 "Nucleo Outline";
  font-size: inherit;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 768px) {
  .entry-container .nav-tabs {
    display: inline-block;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    text-align: center;
  }

  .entry-container .nav-tabs .nav-item>.nav-link {
    margin-bottom: 5px;
  }
}

/* ===peter css fixing loging notification === */

.notification_log {
  font-weight: 600;
  font-family: "Fjalla One", sans-serif;
  margin-top: 100px;
}

.temp_sign_in a {
  margin-right: 30px;
}

/* ====peter css fixing end */

/* == Peter css for card Entries End === */

/* == Carlos css changes for fixing top navbar and bottom navbar == */

/* Bottom NavBAr */

.bottomnav {
  display: none;
}

/* fix navbar */

#mainCol {
  flex-basis: 100%;
  max-width: 100%;
  height: 100vh;
}

/*bottom navbar buttons*/

#btm-open-btn {
  display: none;
}

#btm-logout-btn {
  display: none;
}

/* TOP NAVABAR */

#mainCol {
  flex-basis: 100%;
  max-width: 100%;
  height: 100vh;
}

#container_cat {
  height: 100vh;
}

#btm-dash-btn {
  display: none;
}

@media (max-width: 1400px) {
  .categories-body {
    padding-bottom: 90px;
  }

  .top-ten-list {
    padding-bottom: 90px;
  }

  .entry-container {
    padding-bottom: 90px;
  }

  #btm-dash-btn {
    display: none;
    position: absolute;
    top: -6px;
    font-weight: bolder;
    left: -32px;
    background: unset;
    border: unset;
    font-size: 25px;
  }

  #btm-logout-btn {
    display: inline-block;
    position: absolute;
    top: -6px;
    font-weight: bolder;
    right: -7px;
    background: unset;
    border: unset;
    font-size: 25px;
  }

  .bottomnav-logo {
    position: absolute;
    margin-top: 35px;
    margin-left: 50px;
    font-size: 23px;
    color: #121010;
    font-weight: 400;
    font-family: "Ubuntu", sans-serif;
    text-transform: uppercase;
    text-align: center;
  }

  .home-wallpaper {
    height: 350px;
    width: 400px;
  }

  .top-ten-card {
    margin-left: auto;
    margin-right: auto;
  }

  #open-btn {
    display: none;
  }

  #logout-btn {
    display: none;
  }

  .back-orange {
    background-color: #ff9b00 !important;
  }
}

/* ==== CSS PETER ==== */

/* ==== entry tables peter */

.numberline {
  background-color: #2f2c2c;
  border-radius: 20px;
  width: 30px;
  color: #fff;
  padding-right: 8px;
  margin-right: 6px;
  padding-left: 10px;
  margin-left: -15px;
}

.displayVotes {
  min-width: 70px;
}

.voting_class {
  /* position: relative;
 text-align: right;
 font-family: 'Ubuntu';
    font-weight: 200;
    color: #4d4f52;
    font-size: 15px;
    margin-bottom: -30px; */
  width: 33.333%;
  text-align: right;
}

.table-card {
  overflow-x: scroll;
  overflow-y: scroll;
}

.card-header th {
  background-color: #fff !important;
}

.styled-table {
  min-height: 500px;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #dee2e6;
  min-width: 1450px;
  overflow-x: scroll;
  overflow-y: scroll;
}

th,
td {
  border: 1px solid #dee2e6;
}

.styled-table thead th {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  font-size: 14px;
  color: #8898aa;
  font-family: "Ubuntu", sans-serif;
  background-color: #f6f9fc;
  vertical-align: middle;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-bottom: 1px solid #e9ecef;
}

.styled-table td {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  font-family: "Open Sans Condensed", sans-serif;
  letter-spacing: 0.25px;
}

.list tr {
  min-height: 200px;
  height: 110px;
  overflow-y: scroll;
  font-family: "Ubuntu", sans-serif;
  background-color: #38adbc0a;
}

.avatar img {
  z-index: 2;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  height: 39px;
  border: 2px solid #afaeae;
}

.rounded-circle {
  height: 57px;
  width: 54px;
}

.rounded-circle {
  border-radius: 50% !important;
}

.avatar-group .avatar {
  position: relative;
  z-index: 2;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 50%;
  background-color: #adb5bd;
  align-items: center;
  justify-content: center;
  float: left;
}

.edit-btn {
  display: flex;
  border: unset;
  position: relative;
  border-radius: 20px;
  z-index: 2;
  text-align: right;
  background: unset;
  color: #6d6969;
  font-size: 18px;
  width: 100%;
}

.edit_button_class {
  width: 33.333%;
  text-align: center;
  /* position: relative;
  top: 30px;
  right: 80px;
  text-align: right; */
  display: flex;
}

.modal-title {
  text-transform: uppercase !important;
  font-family: "Open Sans Condensed", sans-serif !important;
  font-weight: bolder !important;
}

.blk-btn {
  display: flex;
  border-radius: 20px;
  background: unset;
  font-size: 18px;
  color: #6d6969;
  z-index: 2;
  border-radius: 20px;
  border: unset;
}

.remixed_item {
  position: relative;
  color: #ff9b00;
  top: -70px;
  right: 25px;
  text-align: end;
}

.remixed_item i {
  font-size: 20px;
}

.jumbotron {
  text-align: center;
  width: 100%;
  font-size: 15px !important;
  color: #000;
  font-family: "Alfa Slab One", cursive;
  text-transform: uppercase;
  font-weight: 400
}

.display-4 {
  font-size: 1.5rem !important;
}

.categoryDisplay {
  font-weight: 400;
  top: -58px;
  text-transform: uppercase;
  letter-spacing: 0px;
  position: relative;
  font-size: 18px;
  color: #000;
  font-family: "Montserrat";
  color: #fff;
}

.top10NameDisplay {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  position: relative;
  top: 14px;
  color: #2E2E2E;
  text-transform: uppercase;
}

.topPurposeDisplay {
  margin-top: -25px;
  font-family: "Montserrat";
  font-size: 25px;
}

.tt-titleDisp {
  font-family: "Alfa Slab One", cursive;
}

.tt-class-disp {
  font-size: 25px;
  letter-spacing: 0px;
  font-weight: 700;
  padding-left: 10px;
}

.tt-statement-disp {
  text-transform: capitalize;
  font-size: 20px;
}

.tt-cat-disp {
  margin-right: 25px;
}

.tt-creator-signature {
  text-align: left;
  position: relative;
  top: 60px;
}

.tt-sig-display {
  font-size: 20px;
}

.creator-name {
  font-family: "Allura", cursive;
  text-transform: lowercase;
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 2px;
  position: relative;
  top: -7px;
}

@media (max-width: 750px) {
  .jumbotron {
    font-size: 20px;
  }

  .cont-jumbotron {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .tt-btn-1 {
    position: absolute !important;
    top: 115px;
    left: 5px;
  }

  .tt-btn-2 {
    position: absolute !important;
    top: 115px;
    right: 5px;
  }

  .categoryDisplay {
    top: 0px;
    padding-bottom: 60px;
  }

  .tt-btn-1-tc {
    position: absolute !important;
    top: 74px;
    left: -35px;
  }

  .tc-jumbo {
    margin-top: 60px;
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .top-ten-list .d-flex {
    display: unset !important;
  }

  .top-ten-list-body {
    margin-top: -80px;
  }
}

@media (max-width: 450px) {
  .tt-creator-signature {
    margin-top: 25px;
    text-align: center;
    position: unset;
    top: unset;
  }
}

/* === COMMENTS STYLING MOVE TO ITS ON FILE WHEN COMPLETE ===== */

.comment-btn {
  display: flex;
  border: unset;
  position: relative;
  border-radius: 20px;
  color: #6d6969;
  z-index: 2;
  text-align: right;
  background: unset;
  font-size: 18px;
}

.comment_button_class {
  width: 33.3333%;
  /* position: relative;
  border: unset;
  height: 30px;
  width: 30px;
  z-index: 2;
  top: 60px;
  border-radius: 20px; */
}

.Comments_filter button {
  text-transform: capitalize;
  font-size: 17px;
  font-family: "Roboto", sans-serif !important;
}

.comment_input_field textarea {
  position: relative;
  width: 100%;
  min-height: 125px;
  border-radius: 5px;
  border: 1px solid #000;
  font-family: "Roboto", sans-serif !important;
}

.comment_input_field input {
  width: 100%;
  height: 45px;
  outline-color: #C4C4C4 !important;
  box-shadow: 0px 4px 5px -2px rgb(0 0 0 / 15%);
  border-radius: 6px;
  color: #000;
}

.submit-tt-comment {
  background: #FF9B00 !important;
  color: #fff !important;
}

.comment_user_profile {
  background-color: rgb(232, 224, 224);
  border-radius: 20px;
  height: 30px;
  width: 30px;
}

.comment_display_elemets {
  padding-top: 25px;
  padding-bottom: 25px;
}

.comment_user_profile {
  background-color: rgb(232, 224, 224);
  border-radius: 25px;
  height: 50px;
  width: 50px;
  text-align: center;
}

.comment_user_profile span {
  position: relative;
  top: 5px;
  font-size: 25px;
  font-weight: bolder;
  text-transform: uppercase;
}

.comment_display_elemets .comment-user-name {
  text-transform: uppercase !important;
  font-weight: bolder !important;
  padding-bottom: 10px;
}

.comment-user-statement {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
}

.comments-actions ul {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
}

.comments-actions ul li {
  font-family: "Roboto", sans-serif;
  padding-left: 10px;
  font-size: 16px;
  padding-right: 10px;
  text-transform: capitalize;
  color: #737171;
}

.comment_input_field input {
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 10px;
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 8px;
}

.comment-reply {
  padding-left: 65px !important;
  border: 1px solid #000;
}

.comment-reply .comment_user_profile {
  height: 33px !important;
  width: 33px !important;
}

.comment-reply .comment_user_profile span {
  font-size: 16px;
}

.comment-reply .comment-user-name {
  font-size: 12px !important;
  text-transform: uppercase !important;
}

.comment-reply .comment-user-statement {
  font-family: "PT Sans Narrow", sans-serif !important;
  color: #000 !important;
  font-size: 17px;
}

.comment-reply .comments-actions li {
  font-family: "PT Sans Narrow", sans-serif !important;
}

.comment-reply-form {
  width: 100%;
}

.reply-input-field {
  width: 100%;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 7px;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #c4c4c4;
  font-weight: 400;
  padding-left: 20px;
  padding-top: 10px;
  font-family: "Roboto", sans-serif;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c4c4c4;
  font-weight: 400;
  padding-left: 20px;
  padding-top: 10px;
  font-family: "Roboto", sans-serif;
}

::placeholder {
  color: #c4c4c4;
  font-weight: 400;
  padding-left: 20px;
  padding-top: 10px;
  font-family: "Roboto", sans-serif;
}

.reply-submit-btn {
  width: 20%;
  border-radius: 5px;
  border: 2px solid #000;
  outline-color: #000;
  font-family: "PT Sans Narrow", sans-serif !important;
}

.comment-reply-button {
  cursor: pointer;
}

.idea-container {
  padding-top: 10px !important;
  padding-bottom: 5px !important;
}

.purpose-container {
  padding-top: 10px !important;
  padding-bottom: 15px !important;
}

.tt-dis-entries .numberLine {
  font-family: "Montserrat";
  font-weight: bolder;
  padding-bottom: 5px;
  border-radius: 15px;
}

.tt-dis-entries .card {
  min-height: unset !important;
  border-bottom: solid 2px #ff9b00;
  border-radius: 2px;
}

.tt-dis-entries .card-header {
  background-color: unset !important;
  padding: 10px !important;
}

.tt-dis-entries .card-header .btn-link {
  color: #000 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  text-transform: inherit;
}

.tt-dis-entries ul {
  list-style-type: none;
}

.tt-dis-entries .last_entries {
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  bottom: 0px;
  left: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.first-element {
  font-family: "Ubuntu", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.name_comment {
  font-size: 12px;
  padding-right: 5px;
  font-family: "Fjalla One", sans-serif;
}

.tt-dis-entries .badge {
  text-transform: uppercase;
  background: #309897;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  color: #FFFFFF;
}

.tt-entries-container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.tt-entries-container li {
  padding-top: 5px;
  padding-bottom: 20px;
}

.tt-entries-container .avatar-group {
  position: absolute;
  top: 13px;
  right: 10px;
}

.tt-more-users {
  height: 40px;
  width: 40px;
  border: 1px dotted black;
  border-radius: 30px;
  font-size: 15px;
  padding: 10px;
  position: relative;
  top: 10px;
  color: #000;
  z-index: 10;
  cursor: pointer;
}

.info-display {
  font-size: 19px !important;
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: 600;
}

.display-comments-division {
  padding-bottom: 14px;
}

.info-class ul {
  display: flex;
  flex-wrap: nowrap;
}

.info-class ul li {
  padding: 5px;
  font-family: "Ubuntu", sans-serif;
  flex: 1;
}

.vt-rx-bg {
  font-size: 15px;
  padding: 4px;
  border-radius: 20px;
  color: #fff;
}

.vt-names {
  padding-left: 5px;
}

.disp-numberline {
  position: relative;
  left: -18px;
}

.tt-dis-entries .btn-link:hover {
  text-decoration: unset !important;
}

.tt-dis-entries .btn-link:active {
  outline: none !important;
}

.tt-dis-entries .btn-link:focus {
  outline: none !important;
  box-shadow: unset !important;
}

.tt-dis-entries .card-body {
  min-height: 150px;
  width: 100%;
  border-top: 2px solid #eee;
}

.tt-footer-section {
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background: #eee;
}


.info-class ul {
  margin-top: 15px;

}

.tt-vote-remix {
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-wrap: nowrap;
}

.tt-vote-remix .name_comment {
  text-transform: capitalize;
}

.disp-engaged-users {
  position: absolute;
  right: 12px;
  margin-top: -40px;
  display: flex;
  flex-wrap: nowrap;
}

.other-elements-container {
  position: relative;
  top: 40px;
  margin-bottom: 50px;
}

.elements-contaimer {
  width: 100%;
  min-height: 100px;
  background-color: #eee;
  padding-top: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.view-details {
  min-height: 80px;
  height: 100%;
  border-left: solid 2px #ffc107;
  max-height: 100%;
  display: grid;
}

.view-details .badge {
  font-size: 20px;
  position: relative;
  top: 22px;
  border-radius: 40px;
  padding: 8px;
  height: 35px;
  width: 35px;
  text-align: center;
}

.el-title {
  margin-left: 50px;
  font-size: 16px;
  position: relative;
  top: -8px;
  font-weight: 800;
  font-family: "Ubuntu", sans-serif;
}

.el-details {
  font-size: 18px;
  position: relative;
  left: unset;
  margin-left: 50px;
  margin-top: 0px;
  margin-bottom: 20px;
  color: #695f5f;
  font-weight: 500;
}

.entry-created-by {
  margin-top: 65px;
  color: #000 !important;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.created_by_footer {
  margin-top: 22px;
  position: relative;
  margin-bottom: -15px;
  text-transform: capitalize;
  color: #595959;
}

.accordion {
  margin-bottom: -30px;
}

.disabled-class-tt {
  display: none !important;
}

/* <=============== Reusable componets css ============> */

/* ==== Sorting Component ==== */

.reusable-sorting-component {
  display: flex;
  flex-wrap: nowrap;
}

.reusable-sorting-component .reusable-sorting-view {
  flex: 1;
}

.reusable-sorting-component .reusable-sorting-view i {
  color: #047c7c;
}

.reusable-sorting-component .reusable-sorting-view span {
  color: #2e2e2e;
  opacity: 0.75;
  font-family: "Roboto", sans-serif;
}

.reusable-sorting-component .reusable-sorting-show {
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  text-align: left;
}

.reusable-sorting-component .reusable-sorting-sort-by {
  flex: 1;
  text-align: right;
}

.reusable-sorting-show span {
  color: #2e2e2e;
  opacity: 0.75;
  font-family: "Roboto", sans-serif;
  padding-left: 2px;
}

.reusable-sorting-show .dropdown-toggle {
  font-size: 14px;
  line-height: 25px;
  color: #047c7c;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.reusable-sorting-sort-by span {
  color: #2e2e2e;
  opacity: 0.75;
  font-family: "Roboto", sans-serif;
  padding-left: 2px;
}

.reusable-sorting-sort-by .dropdown-toggle {
  font-size: 14px;
  line-height: 25px;
  color: #047c7c;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.reusable-pagination nav .page-item {
  font-size: 15px;
  padding-left: 4px;
}

.reusable-pagination nav .page-link {
  border-radius: 3px;
  font-family: "Roboto", sans-serif;
  color: #000;
}

/* === Top10 Design === */

.category-top-10 span {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 18px;
  font-family: "Roboto", sans-serif;
  color: #309897;
  padding: 2px;
}

.category-top-10 .header-name {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  font-family: "Roboto", sans-serif;
  color: #000000;
}

.add-new-button {
  background: #FF9B00 !important;
  box-shadow: 0px 4px 14px -5px rgba(0, 0, 0, 0.31);
  border-radius: 6px;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF !important;
  font-family: "Roboto", sans-serif !important;
  text-transform: capitalize !important;
}

.list-cards {
  margin-top: 20px;
}

.list-cards .card-header {
  background: #2A2E43 !important;
  box-shadow: 0px 4px 11px 3px rgba(0, 0, 0, 0.05) !important;
  border-radius: 8px 8px 0px 0px !important;
  color: #ffffff;
  min-height: 85px;
  text-align: center;
}

.tt-list-title-banner {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-top: 18px;
}

.divison-color {
  background: rgb(102, 94, 255);
  background: linear-gradient(90deg, rgba(102, 94, 255, 1) 0%, rgba(102, 94, 255, 1) 35%, rgba(58, 204, 225, 1) 100%);
  width: 100%;
  height: 6px;
  margin-bottom: 15px;
}

.btn-view-tt {
  display: flex;
  flex-wrap: nowrap;
}

.tt-explore-btn {
  flex: 1;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  font-family: "Roboto", sans-serif !important;
  color: #047C7C !important;
  border: 1px solid #047C7C !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  box-shadow: unset !important;
  text-transform: capitalize !important;
}

.tt-entry-bb-btn {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 28px;
  color: #626262 !important;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif !important;
  word-spacing: 5px;
}

.tt-display-card-upvotes {
  display: flex;
  flex-wrap: nowrap;
}

.tt-display-card-upvotes .mb-0 {
  flex: 1;
  padding-left: 15px;
}

.toggle-upvote-button {
  display: flex;
}

.upvote-statement {
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #309897;
  padding: 16px;
  margin-right: 20px;
  margin-left: 10px;
}

.toggle-upvote-button i {
  background: #000000;
  color: #EDEDED;
  border: none;
  font-size: 28px;
  border-radius: 100%;
}

.likes-display-count {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  font-family: "Roboto", sans-serif;
  color: #309897;
  padding-left: 10px;
  position: relative;
  top: 4px;
}

.show-display-tt {
  margin-top: 150px !important;
}

.show-display-tt .suggest-idea span {
  padding-left: 0px !important;
}


.top-ten-banner {
  height: 100px;
  height: 400px;
  background: #eee;
}

.back-button-show {
  position: absolute;
  top: 90px;
  left: 16px;
  z-index: 1;
  color: #fff !important;
  font-size: 12px !important;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.show-display-tt {
  margin-bottom: 20px;
}

.timeline-title {
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  color: #2E2E2E;
  text-transform: uppercase;
}

.timeline-time {
  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  color: #2E2E2E !important;
}

/* ===== Media queries ==== */
@media (max-width: 700px) {
  .top10NameDisplay {
    display: flex;
    position: relative;
    font-size: 20px;
    text-align: center !important;
    justify-content: center;
  }

  #btn-added-tt {
    width: 100% !important;
    margin-bottom: 50px;
    margin-top: 20px;

  }
}

@media only screen and (max-width: 800px) {
  .top-nav-icon-cont {
    display: none !important;
  }
}