.menu_top {
    width: 100%;
    height: 150px;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close_menu_button {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    font-weight: 800;
    cursor: pointer;
}

.menu_top h5 {
    font-weight: 800;
    text-transform: capitalize;
}

.menu_item {
    font-family: Roboto, sans-serif !important;
    font-size: 18px;
    font-weight: 300;
    color: #000;
}

.active_tab {
    background-color: purple;
    color: #fff;
}