.upvote_favorite_container {
    display: flex;
    flex-wrap: wrap;
}

.bread_navigation {
    margin-top: 6px;
}

.upvote_container {
    display: flex;
    flex-wrap: nowrap;
    justify-items: flex-start;
}

.upvote_container span {
    margin-left: 10px;
    text-transform: lowercase;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}

.top_bar_container {
    width: 100% !important;
}

.upvote_items {
    margin-top: 0px;
}

.upvoted_by_container {
    margin-left: 10px;
    cursor: pointer;
}

.favorite_container {
    display: flex;
    flex-wrap: nowrap;
}

.favorite_icon {
    margin-top: -1px;
    cursor: pointer;
}

.favorite_container_items {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 5px;
}

.favorite_container_items span {
    margin-left: 10px;
    text-transform: capitalize;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    margin-top: 5px;
}

.jumbotron_container {
    margin-top: 10px;
    width: 100%;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -moz-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
}

.actual_jumbotron_area {
    border-radius: 3px;

}

.tab_selection_display {
    position: relative;
    bottom: -60px;
}

.jumbotron_container .jumbotron {
    text-align: unset !important;
}

.bread_project_name_one {
    background-color: #eee !important;
    color: #000 !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}


.bread_project_name {
    background-color: green !important;
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}

.bread_project_name_active {
    background-color: purple !important;
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}

.project_image_container {
    position: relative;
    top: -62px;
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    height: 38px;
    padding: 8px;
}

.project_image_container .project_image {
    box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.75);
}

.project_name_display {
    margin-left: 12px;
    font-size: 20px;
    font-weight: 700;
    color: purple;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    margin-left: 5px;
    position: relative;
}

.tab_selection_display {
    position: relative;
}

.timeline_container {
    width: 100%;
    border: 1px solid #E5EAF2;
    border-radius: 3px;
    min-height: 342px;
    background: #eee;
    overflow-y: visible;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -moz-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    max-width: 400px;
    position: relative;

}

.timeline_top_bar {
    background-color: #fff;
    height: 45px;
    align-items: center;
    display: flex;
    padding-left: 20px;
}

.timeline_top_bar h6 {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    margin: 5px;
    color: purple;
}

.time_line_button_bar {
    background-color: #fff;
    height: 45px;
    align-items: center;
    display: flex;
    padding-left: 20px;
    bottom: 33px;
    justify-content: center;
}

.selection_display {
    position: relative;
    bottom: -50px;
}

/* ==== MOBILE RESPONSIVENESS ==== */
@media (max-width: 801px) {
    .bread_navigation ol {
        display: block !important;
    }

    .collaborators_container {
        margin-top: 8px !important;
        margin-bottom: 15px !important;
        border-top: 1px solid #eee !important;
        padding-top: 10px !important;
    }


    .project_image_container {
        top: -32px !important;
    }

    .jumbotron_container {
        margin-top: 0px !important;
        margin-bottom: -30px !important;
    }

}