.update_notice_main_container {
    width: 100%;
}

.mobile_view {
    display: none;
}

.content_container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.desk_top_side_bar {
    flex: 1;
}

.desk_top_info_container {
    flex: 6;
}

.filter_and_add_button_container {
    display: flex;
    flex-wrap: nowrap;
}

.add_new_button {
    margin-top: 15px !important;
    margin-right: 10px !important;
}



/* ==== RESPONSIVENESS ====  */
@media only screen and (max-width: 800px) {
    .mobile_view {
        display: inline;
        width: 100%;
    }

    .desk_top_view {
        display: none;
    }
}