@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

.card {
  cursor: pointer;
}

.recent-search-desktop {
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
}

.fa-forward {
  color: black !important;
}

.fa-backward {
  color: orange !important;
}

.jumbo-suggest {
  margin: 0 auto;
}

.suggest-btn-group {
  text-align: center;
}

.tablet-view {
  display: none;
}

.computer-view {
  display: none;
}

.show-dektop-nav {
  display: none;
}

.tt-filter-bar-desktop {
  display: none;
}

.suggetion-plus-button-container {
  display: inline;
  position: fixed;
  bottom: 50px;
  right: 30px;
}

.suggest-btn {
  background: #ec9528;
  box-shadow: 0px 4px 14px -5px rgba(0, 0, 0, 0.31);
  border-radius: 6px;
  padding: 5px;
  border: unset;
  color: #ffff;
  position: relative;
  top: 8px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 8px;
}

.suggestion-creator-avatar {
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.suggestion-creator-avatar h6 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #2E2E2E;
  text-transform: capitalize;
}

.share-display-container-sub {
  display: flex;
  margin-top: 15px;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.share-display-container-sub span {
  color: #2E2E2E;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
}

.sb-shared-icons-avatars {
  box-shadow: -1px -1px 36px -1px rgba(74, 70, 70, 1);
  -webkit-box-shadow: -1px -1px 36px -1px rgba(74, 70, 70, 1);
  -moz-box-shadow: -1px -1px 36px -1px rgba(74, 70, 70, 1);
}

.sb-reusable-card-img {
  border: 1px solid #dee2e6;
}

.date-of-suggestion-creation {
  margin-left: 60px;
  margin-top: -18px;
}

.suggestion-idea-title {
  display: flex;
  background-color: #dedad3;
  border-radius: 4px;
  min-height: 40px;
  max-height: 40px;
  align-items: center;
  margin-top: 20px;
  box-shadow: -1px -1px 11px -1px rgba(130, 123, 123, 0.88);
  -webkit-box-shadow: -1px -1px 11px -1px rgba(130, 123, 123, 0.88);
  -moz-box-shadow: -1px -1px 11px -1px rgba(130, 123, 123, 0.88);
}

.reusable-card .suggestion-idea-title {
  display: flex;
  background-color: #dedad3;
  border-radius: 4px;
  min-height: 40px;
  max-height: 40px;
  align-items: center;
  margin-top: 0px;
}

.suggestions-shared-profiles {
  display: flex;
  flex-wrap: nowrap;
}

.suggestions-shared-profiles .share-button-image-sug {
  margin-top: 8px;
  padding-right: 22px;
}

.wrapper {
  display: flex;
  width: 100%;
}

#sidebar {
  width: 250px;
  position: fixed;
  padding-top: 50px;
  top: 0;
  left: 0;
  height: 100vh;
  background: #7386d5;
  color: #fff;
  transition: all 0.3s;
}

.reply-btn-sb {
  border: 2px solid #000;
  border-radius: 10px;
  width: 100px;
  margin-top: -4px;
}

.main-sb-comment {
  color: #2e2e2e;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.7px;
  width: 100%;
  background: #eee;
  min-height: 100px;
  padding: 10px;
  border-radius: 10px;
}

.main-sb-comment-profile-names {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #2E2E2E;
}

/* ==== New design CSS ==== */

.suggestion-header h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  margin-top: 33px;
  text-transform: capitalize;
  font-size: 26px;
}

.tt-filter-bar {
  margin-top: 50px;
}

.drop-title {
  color: #2e2e2e;
  font-size: 18px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.drop-title-category {
  font-weight: 600;
  font-size: 18px;
  color: #309897;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
}

.suggestion-design-change i {
  margin-top: 2px;
  font-size: 25px;
  color: #a4a4a4;
  cursor: pointer;
}

.tt-suggestion-card {
  border: none;
  box-shadow: 0px 5px 14px 6px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  background: #ffffff;
  margin-top: 10px;
  margin-bottom: -40px;
  min-width: 450px;
  max-width: 450px;
  min-height: 730px;
  margin-bottom: 30px;
}

.tt-suggestion-card-title {
  display: flex;
  flex-wrap: nowrap;
}

.suggest-idea span {
  color: #1d1d1d;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 18px;
  padding-left: 8px;
}

.suggestion-purpose {
  margin-top: 15px;
  margin-left: 25px;
  color: #2e2e2e;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.7px;
}

.tt-tags-display {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 16px !important;
}

.tt-tags-display .badge {
  border: 1px solid #eee;
  padding: 8px;
  border-radius: 20px;
  margin-left: 5px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-size: 14px;
}

.tt-suggestion-details {
  font-family: "Roboto", sans-serif;
  color: #000000;
  opacity: 0.6;
  font-size: 16px;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 42px;
}

.suggestion_images {
  display: flex;
  flex-wrap: nowrap;
}

.suggestion_images img {
  width: 100px !important;
  height: 100px !important;
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
}

.sb-like-comment-btn {
  display: flex;
  flex-wrap: nowrap;
}

.show-like-share-container {
  margin-top: 35px;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
}

.show-like-share-container .show-like-share-container {
  flex: 1;
}

.show-like-share-container .liked-by-container {
  flex: 1;
}

.shared-with-chip {
  margin-top: 8px;
  margin-right: 8px;
}

.sb-like-comment-btn {
  margin-top: 15px;
  margin-bottom: 10px;
}

.sb-like-comment-btn img {
  height: 30px;
}

.sb-like-comment-btn button {
  border: unset;
  background: unset;
  flex: 1;
}

.sb-like-comment-btn span {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.tt-suggestion-card cite {
  padding-top: 12px;
  padding-right: 10px;
  font-style: italic;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  padding-bottom: 12px;
}

.tt-suggestion-card-title {
  margin-top: -18px;
}

.suggestion-comments-container {
  margin-top: 52px;
  display: inherit;
}

.suggestion-comments-container .card {
  border: none;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 5px 14px 6px rgb(0 0 0 / 11%);
}

.suggestion-comments-counter {
  display: flex;
  flex-wrap: nowrap;
}

.suggestion-comments-counter h3 {
  flex: 1;
  text-align: left;
  font-weight: 500;
  font-size: 19px;
  line-height: 25px;
  color: #000000;
  opacity: 0.8;
  font-family: "Roboto", sans-serif;
}

.suggestion-comments-counter .suggetion-comments-sort {
  flex: 1;
  text-align: right;
  margin-top: -5px;
}

.suggetion-comments-sort .dropdown-toggle {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #309897;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}

.suggestion-comment-field {
  margin-top: 30px;
}

.suggestions-comments-body {
  margin-top: 40px;
}

.comment-container-tt-v {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}

.comment-container-tt-v img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.comment-itself {
  flex: 1;
  padding-left: 20px;
  font-weight: normal;
  font-size: 15px;
  line-height: 29px;
  color: #000000;
  opacity: 0.6;
  font-family: "Roboto", sans-serif;
  flex-wrap: wrap;
}

.suggest-comment-createdat {
  font-weight: 400;
  font-size: 11px;
  line-height: 25px;
  text-align: right;
  color: #000000;
  opacity: 0.6;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  letter-spacing: 0px;
  margin-top: -20px;
}

.suggestion-comments-icons-v {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 25px;
  margin-top: 15px;
}

.suggestion-comments-icons-v .sugget-like {
  flex: 1;
  text-align: center;
}

.suggestion-comments-icons-v .sugget-like span {
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #309897;
  font-family: "Roboto", sans-serif;
}

.suggestion-comments-icons-v .comments-edit {
  flex: 1;
  text-align: center;
}

.suggestion-comments-icons-v .comments-edit span {
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  opacity: 0.6;
  font-family: "Roboto", sans-serif;
}

.suggestion-comments-icons-v .comments-reply {
  flex: 1;
  text-align: right;
}

.search-linking-projects-cont {
  padding: 2px;
}

.suggestion-comments-icons-v .comments-reply span {
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  opacity: 0.6;
  font-family: "Roboto", sans-serif;
}

.linked-project-close {
  right: -40px;
  position: absolute;
}

.project-linked-list {
  cursor: pointer;
}

.vl {
  margin-bottom: 20px;
  opacity: 0.15;
  border: 1px solid #000000;
  height: 95px;
  position: absolute;
  left: 0;
  margin-left: 45px;
  top: 402px;
}

.suggestion-purpose span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #FF9B00;
  padding-right: 10px;
}

.category-sb-display {
  display: contents;
}

.category-sb-display img {
  height: 15px;
  margin-left: -5px;
  margin-right: 8px;
  margin-top: 5px;
}

.media-reply-container {
  border-radius: 4px;
}

.creator-of-comment {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300 !important;
}

.vertical-bar-comment-box-sub {
  height: 98%;
  background: #047c7c;
  width: 2px;
  position: relative;
  top: 10px;
  left: 26px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

}

.text {
  display: inline !important;
  width: 100% !important;
}

.read-or-hide {
  color: #047c7c !important;
  cursor: pointer !important;
  position: relative;
}

/* === Media queries === */

@media (min-width: 768px) {

  /* === showpage === */
  .showpage-computer-view {
    display: inline-block !important;
    margin-top: 20px;
  }

  .suggestion-box-banner img {
    filter: brightness(0.49);
    min-height: 300px;
  }

  .suggestion-box-banner .card {
    border: none;
  }

  .suggestion-box-banner .card-title {
    font-weight: bold;
    font-size: 30px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    line-height: 35px;
    margin-top: 25px;
  }

  .suggestion-box-banner .card-text {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
  }

  .suggestion-box-banner hr {
    background-color: #fff;
  }

  .suggestion-trending-container {
    margin-top: 40px;
  }

  .suggestion-trending-container .card {
    background: #FFFFFF;
    box-shadow: 0px 5px 14px -3px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    border: none;
  }

  .suggestion-trending-container .card-header {
    background: unset !important;
    border: unset !important;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #2E2E2E;
    font-weight: 700;
  }

  .suggestion-trending-details span {
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    color: #2E2E2E;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    margin-left: 8px;
  }

  .suggestion-trending-details cite {
    font-family: "Roboto", sans-serif;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    text-align: right;
    color: #000000;
    opacity: 0.6;
    margin-left: 5px;
  }

  .suggestion-trending-comments {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 30px;
  }

  .suggestion-trending-comments .tt-comments {
    flex: 1;
  }

  .suggestion-trending-comments .tt-like {
    flex: 1;
  }

  .suggestion-trending-comments img {
    height: 30px;
  }

  .suggestion-trending-comments span {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    color: #2E2E2E;
    opacity: 0.6;
  }

  /* === showPage=== */
  .tablet-view {
    display: inline-block;
  }

  .sticky-sec {
    position: sticky;
    top: 0;
    z-index: 0;
    margin-bottom: 40px;
  }

  .suggestion_images img {
    margin-left: 0px;
  }

  .sb-like-comment-btn img {
    height: 30px;
  }

  .sb-like-comment-btn span {
    font-size: 14px;
  }

  .category-sb-display img {
    margin-left: -18px;
  }

  .category-sb-display span {
    font-size: 15px;
  }

  .suggestion-navigation-container {
    background: #FFFFFF;
    box-shadow: 0px 5px 14px -3px rgba(0, 0, 0, 0.11);
  }

  .suggestion-navigation-container .card {
    border: none;
  }

  .suggestion-navigation-container .card-header {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: #2E2E2E;
    font-weight: 700;
    padding: 20px;
  }

  .suggestion-navigation-container .list-group-item {
    padding: 18px;
  }

  .suggestion-navigation-container .list-group-item span {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #2E2E2E;
    margin-left: 10px;
  }

  .suggestion-navigation-container .active {
    background: #d9af7a;
    border-left: 4px solid #ec9528;
    border-bottom: none;
  }

  .suggestion-navigation-container .list-group-item img {
    height: 28px;
  }

  .suggestion-navigation-container .list-group-item:hover {
    background: #d9af7a;
    border-left: 4px solid #ec9528;
    border-bottom: none;
    cursor: pointer;
  }

  .suggestion-categories-container {
    margin-top: 40px;
    background: #FFFFFF;
    box-shadow: 0px 5px 14px -3px rgba(0, 0, 0, 0.11);
  }

  .suggestion-categories-container .card {
    border: none;
    border-radius: 8px;
  }

  .suggestion-categories-container .list-group-item img {
    height: 40px;
    width: 40px;
  }

  .suggestion-categories-container .list-group-item span {
    font-size: 16px;
    color: #2E2E2E;
    font-family: "Roboto", sans-serif;
    text-transform: capitalize;
    margin-left: 5px;
  }

  .suggestion-categories-container .list-group-item .icon-image {
    height: 15px;
    width: 15px;
  }

  .suggestion-categories-container .cat-value-number {
    position: absolute;
    right: 5px;
    margin-top: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #2E2E2E;
  }

  .suggestion-categories-container .card-header {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: #2E2E2E;
    font-weight: 700;
    padding: 20px;
  }

  .suggestion-searches-container {
    margin-top: 40px;
    background: #FFFFFF;
    box-shadow: 0px 5px 14px -3px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    margin-bottom: 40px;
  }

  .suggestion-searches-container .card {
    border: none;
    border-radius: 8px;
  }

  .suggestion-searches-container .card-header {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: #2E2E2E;
    font-weight: 700;
    padding: 20px;
  }

  .suggestion-searches-container .card-body {
    display: contents;
    flex-wrap: wrap;
  }

  .suggestion-searches-container .card-body li {
    border: 1px solid #eee;
    min-width: 10px;
    padding: 10px;
    border-radius: 20px;
    margin-left: 5px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: capitalize;
    font-size: 14px;
  }

  .edit-reply-mobile-container {
    display: flex;
    flex-wrap: nowrap;
  }

}

.sugget-like img {
  height: 28px;
}

.sugget-like button {
  border: unset;
  background-color: unset;
}

.new-suggestion-form-tt h2 {
  color: #2E2E2E;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
}

.new-suggestion-form-tt .form-title {
  display: flex;
  flex-wrap: nowrap;
}

.new-suggestion-form-tt .form-title h2 {
  flex: 1;
  font-size: 24px;
}

.new-suggestion-form-tt .form-title button {
  flex: 1;
  text-align: right;
  background: none;
  border: none;
}

.new-suggestion-form-tt .form-title button i {
  font-size: 28px;
}

.new-suggestion-form-tt {
  border: none;
}

.new-suggestion-form-tt label {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 25px;
  color: #2E2E2E;
}

.new-suggestion-form-tt .form-group {
  margin-top: 10px;
}

.new-suggestion-form-tt .submit-btn {
  box-shadow: 0px 5px 12px rgb(0 0 0 / 25%);
  border-radius: 6px;
  padding-left: 20px;
  padding-right: 20px;
}

.vertical-bar-comment-box {
  height: 98%;
  background: #eabc83;
  width: 2px;
  position: relative;
  top: 10px;
  left: 26px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.main-comment-box-divider {
  margin: 30px 0px;
  width: 95%;
  position: relative;
  left: 30px;
}

.comment-date-posted {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
}

.showpage-mobile-navigation {
  display: none;
}


@media (min-width: 1200px) {
  .show-dektop-nav {
    display: inline-block;
    margin-top: 40px;
  }

  .computer-view {
    display: inline-block;
    margin-top: 40px;
  }

  .suggestion-box-banner .card {
    border: none;
  }

  .suggestion-box-banner img {
    filter: brightness(0.49);
    min-height: 300px;
  }

  .suggestion-box-banner .card-title {
    font-weight: bold;
    font-size: 30px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    line-height: 35px;
    margin-top: 25px;
  }

  .suggestion-box-banner .card-text {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
  }

  .suggestion-box-banner hr {
    background-color: #fff;
  }

  .suggestion-trending-container {
    margin-top: 40px;
  }

  .suggestion-trending-container .card {
    background: #FFFFFF;
    box-shadow: 0px 5px 14px -3px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    border: none;
  }

  .suggestion-trending-container .card-header {
    background: unset !important;
    border: unset !important;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #2E2E2E;
    font-weight: 700;
  }

  .suggestion-trending-details span {
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    color: #2E2E2E;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    margin-left: 8px;
  }

  .suggestion-trending-details cite {
    font-family: "Roboto", sans-serif;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    text-align: right;
    color: #000000;
    opacity: 0.6;
    margin-left: 5px;
  }

  .suggestion-trending-comments {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 30px;
  }

  .suggestion-trending-comments .tt-comments {
    flex: 1;
  }

  .suggestion-trending-comments .tt-like {
    flex: 1;
  }

  .suggestion-trending-comments img {
    height: 30px;
  }

  .suggestion-trending-comments span {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    color: #2E2E2E;
    opacity: 0.6;
  }

  .tt-filter-bar-mobile {
    display: none;
  }

  .tt-filter-bar-desktop {
    display: inline-block;
  }

  .filter-icons {
    position: relative;
    left: 100px;
    margin-left: -10 px;
  }

  .tt-filter-bar-desktop .nav-item {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #000;
    text-transform: capitalize;
  }

  .tt-filter-bar-desktop hr {
    position: relative;
    top: -30px;
  }

  .tt-filter-bar-desktop .active {
    border-bottom: 2px solid #F19F39;
  }

  .filter-icons i {
    font-size: 25px;
    font-weight: bolder;
  }

  .filter-icons span {
    font-size: 20px;
    padding-right: 15px;
    font-size: 18px;
    line-height: 21px;
    color: #2E2E2E;
    font-family: "Roboto", sans-serif;
  }

  .suggestion_images img {
    height: unset !important;
  }
}

.sb-paginate-bar {
  padding-top: 20px;
  padding-bottom: 20px;
}

.media-container {
  border: 1px solid #f1e6e6;
  border-radius: 4px;
  padding: 8px;
  margin-top: 10px;
  margin-bottom: 18px;
}

.suggestion-mobile-menu-container {
  display: none;
}

.suggestion-box-trending-mobile {
  display: none;
}

.profiles-likes-dis {
  cursor: pointer;
}

.profiles-likes-dis :hover {
  background: green;
  color: white;
  border-radius: 5px;
}

/* ==== Responsiveness issues fix ===== */

@media (max-width: 768px) {

  .tt-suggestion-card {
    margin-top: unset !important;
    margin-bottom: 50px !important;
  }

  .recent-search-mobile {
    position: relative;
    margin-bottom: 30px;
  }

  .suggestion-mobile-menu-container {
    display: inline;
  }

  .suggetion-plus-button-container {
    display: none;
  }

  .suggest-btn {
    display: none;
  }

  .suggestion-main-title {
    display: none;
  }

  .suggestion-search-container {
    display: none;
  }

  .suggestion-box-trending-mobile {
    display: inline;
    position: relative;
    top: -24px;
  }

  .sug-container {
    padding-left: unset !important;
    padding-right: unset !important;

  }

  .suggestion_images {
    flex-wrap: wrap;
  }

  .suggestion_images img {
    object-fit: contain !important;
    height: 90px !important;
  }

  .sb-like-comment-btn {
    display: grid;
    align-items: flex-start;

  }

  .sb-like-comment-btn button {

    padding-bottom: 35px;
  }

  .sb-like-comment-btn .suggestions-shared-profiles {
    align-items: center;
    justify-content: center;
  }

  .project-link-div {
    height: auto !important;
  }

  .MuiTab-iconWrapper {
    padding-left: 20px;
  }

  .sug-container {
    overflow-x: hidden !important;
  }

  .new-suggestion-form-tt {
    position: relative;
    top: -48px;
    width: 100%;
    height: 100vh;
    box-shadow: unset !important;
    border: unset !important;
  }

  .new-suggestion-form-tt .form-title h2 {
    display: none;
  }

  .new-suggestion-form-tt .card-body {
    box-shadow: unset !important;
  }

  .new-suggestion-form-tt .form-title button {
    display: none;
  }

  .new-suggestion-form-tt hr {
    display: none;
  }

  .new-suggestion-form-tt form {
    position: relative;
    top: -24px;
  }

  .new-suggestion-form-tt .submit-btn {
    width: 100%;
    text-align: center !important;
    margin-left: unset;
    padding-left: unset;
    padding-right: unset;
  }

  .suggestion-mobile-search-results {
    position: relative;
    padding-bottom: 40px;
    top: -40px;
  }

  .suggestion-comments-container {
    display: none;
    margin-top: unset !important;
  }

  .showpage-mobile-navigation {
    display: inline;
  }

  .number-of-comments-drawer {
    color: rgb(129, 124, 124);
    font-weight: 700;
  }

  .mobile-comments-editor {
    position: fixed;
    bottom: 15px;
    display: block;
    right: 8px;
  }

  .comments-mobile-version-container {
    overflow-x: hidden !important;
    margin-top: 15px;
  }

  .mobile-comment-button-container {
    margin-top: 8px;
    margin-bottom: 0px;
  }

  .main-sb-comment-mobile-version {
    color: #2e2e2e;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.7px;
    width: 100%;
    background: #eee;
    min-height: 100px;
    padding: 10px;
    border-radius: 10px;
    -webkit-box-shadow: -2px 3px 12px 4px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: -2px 3px 12px 4px rgba(0, 0, 0, 0.14);
    box-shadow: -2px 3px 12px 4px rgba(0, 0, 0, 0.14);
    margin-bottom: 15px;

  }

  .vertical-bar-comment-box-mobile-version {
    height: 98%;
    background: #cabcbc;
    width: 1.5px;
    position: relative;
    top: 3px;
    left: 18px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .vertical-bar-comment-box-sub-mobile {
    height: 98%;
    background: purple;
    width: 2px;
    position: relative;
    top: 3px;
    left: 18px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }


  .upvote-button-mobile {
    position: relative;
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    top: -50px;
    right: 10px;
    margin-bottom: -25px;
  }

  .edit-reply-mobile-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin-top: 40px;
  }

  .No-comments-yet {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .close-reply-comment {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  .reply-of-reply-like-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    margin-top: 30px;
  }

  .cancel-btn-cont-display {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  .suggestions-shared-profiles {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .card-container-display {
    margin-bottom: -55px;
  }

  /* .shared-with-container {
    margin-bottom: 10px;
    margin-top: 30px;
  } */

}


@media (max-width: 580px) {
  .suggestion-header h3 {
    font-size: 22px;
    position: absolute;
    left: 0px;
  }

  .suggest-btn {
    margin-right: -35px;
  }

  .suggestion-design-change i {
    position: absolute;
    font-size: 22px;
  }

  .drop-title {
    font-size: 16px;
  }

  .suggestion_images img {
    margin-left: 0px;
    margin-right: 3px;
    border: unset;
    position: relative;
    margin-left: -5px;
    object-fit: cover;
  }

  .tt-suggestion-card cite {
    margin-bottom: 15px;
  }

  .category-sb-display {
    display: none;
  }

  .tt-suggestion-details {
    margin-left: 10px;
  }

  .suggestion-purpose {
    margin-left: 0px;
    font-size: 15px;
  }

  .suggestion-purpose span {
    font-size: 16px;
    font-weight: 700;
  }

  .tt-suggestion-details {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .suggest-idea span {
    padding-left: 0px;
  }

  .drop-title-category {
    font-size: 16px;
  }

  .sb-like-comment-btn img {
    height: 28px;
  }

  .sb-like-comment-btn span {
    font-size: 14px;
  }

  .new-suggestion-form-tt .form-title h2 {
    font-size: 20px;
  }

  .new-suggestion-form-tt .form-title button i {
    font-size: 25px;
  }

  .new-suggestion-form-tt label {
    font-size: 18px;
  }

  .new-suggestion-form-tt .form-group {
    margin-bottom: 10px;
    margin-top: 12px;
  }
}

@media (max-width: 801px) {
  .tt-suggestion-card {
    width: 100% !important;
  }

  .project-sug-body {
    margin-left: 5px !important;
    margin-right: 10px !important;
  }

}