@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

.admin-header {
    margin: 40px 0 0 30px;
    width: 95%;
    height: 80px;
    background-color: #efeeee;
    border: 1px solid #c8c5c5;
    border-radius: 16px;
    color: rgb(124, 121, 121);
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    padding: 16px;
}

.admin-feature-badge-container {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    margin-top: 30px;
}

.admin-feature-badge {
    display: flex;
    justify-content: center;
    margin: 8px;
    align-items: center;
    width: 350px;
    height: 100px;
    border: 1px solid rgb(180, 177, 177);
    font-size: 30px;
    color: #efeeee;
    border-radius: 8px;
    cursor: pointer;
}

.admin-feature-badge a {
    color: #efeeee;
}

.admin-user-search {
    width: 95%;
    height: 60px;
    padding-left: 50px;
    border: 1px solid #a5a1a1;
    border-radius: 16px;
    margin: 8px 0 0 5px;
    font-size: 20px;
}

.search-icon {
    position: relative;
    left: 50px;
    font-size: 24px;
    color: #aaa9a9;
}

.user-card-container {
    width: 97%;
    display: flex;
    flex-direction: column;
    padding: 24px;
}

.user-card {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-top: 8px;
    margin-left: 16px;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #047C7C
}

.engagement-stats-container {
    margin-top: 50px;
    width: 100%;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.engagement-stats-card {
    width: 350px;
    height: 250px;
    background: linear-gradient(90deg, rgba(128, 228, 227, 0.6839329481792717) 0%, rgba(203, 230, 230, 0.72875087535014) 35%, rgba(194, 210, 210, 1) 100%);
    padding: 8px;
    display: flex;
    margin: 8px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
}

.engagement-stats-card img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.engagement-search-bar {
    width: 100%;
    display: flex;
}

.classic-purple {
    background-color: purple;
}



.engagement-user-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    width: 80%;
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #047C7C;
}