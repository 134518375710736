.fa-forward {
  color: black !important;
}

.box_form_shadow {
  -webkit-box-shadow: 0px 2px 48px 1px rgba(168, 165, 168, 1);
  -moz-box-shadow: 0px 2px 48px 1px rgba(168, 165, 168, 1);
  box-shadow: 0px 2px 48px 1px rgba(168, 165, 168, 1);
}

.fa-backward {
  color: orange !important;
}

.jumbo-suggest {
  margin: 0 auto;
}

.suggest-btn-group {
  text-align: center;
}

.suggest-btn {
  border: none;
  padding: 10px;
  background-color: orange;
  margin: 0 auto;
}

.legend-table-container {
  width: 100%;
  padding: 16px;
  margin-top: 16px;
}

.legend-table-container table {
  width: 100%;
  border-radius: 8px;
}

.legend-table-container table th {
  padding: 8px;
  background-color: #047C7C;
}

.legend-association {
  background-color: orange;
  padding: 12px;
  font-size: 18px;
  font-weight: 600;
}

.legend-table-container table tr td {
  padding: 8px;
}

.table-hr {
  height: 3px;
  background-color: rgb(237, 233, 229);
}