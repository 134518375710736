.list_container {
    height: 100px;
    width: 100%;
    background-color: #eee;
    top: -8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    font-family: "Roboto", sans-serif !important;
}

.active_tab {
    background: "purple" !important;
    color: "white";
    width: 100%;
    height: 50px;
}