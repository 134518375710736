@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

.financial-body {
  margin-top: 20px;
}

.financial-form form {
  margin-bottom: 40px;
}

.financial-form select {
  width: 50% !important;
  z-index: 100;
}

.financ-container-all .card {
  margin-bottom: 15px;
}

.financ-container-all .card .card-header {
  color: #1d1d1d;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding-left: 8px;
}

.department {
  position: absolute;
  right: 10px;
  border: 1px solid #eee;
  padding: 6px;
  border-radius: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-transform: capitalize;
  top: 6px;
  font-size: 14px;
}

.info-bg {
  border: 1px solid #eee;
  padding: 6px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-transform: capitalize;
  padding-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #2e2e2e;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.7px;
}

.financ-container-all .card-title {
  color: #1d1d1d;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 30px;
}

.financ-container-all .card-text {
  font-family: "Roboto", sans-serif;
  color: #1d1d1d;
}

.date-display {
  position: relative;
  right: 2px;
  bottom: 2px;
  margin-top: 60px;
}

.financials-amount-used {
  font-size: 18px;
  text-transform: unset;
  color: #2e2e2e;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.task-all {
  min-height: 500px !important;
}

.create-new-item {
  position: absolute;
  right: 10px;
  margin-top: 10px;
  background: #ec9528;
  box-shadow: 0px 4px 14px -5px rgb(0 0 0 / 31%);
  border-radius: 6px;
  padding: 5px;
  border: unset;
  color: #ffff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.form-tasks-class {
  position: absolute;
}
.list-collaborators {
  display: flex;
  flex-wrap: nowrap;
}

.list-collaborators small {
  flex: 1;
}

.collaborators-label {
  font-size: 15px !important;
}

.edit-open-item {
  background: #ec9528;
  box-shadow: 0px 4px 14px -5px rgb(0 0 0 / 31%);
  padding: 5px;
  border: unset;
  color: #ffff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.create-new-financial-record {
  position: relative;
  margin-top: -30px;
  margin-bottom: 10px;
}

.create-new-financial-record button{
    background: #ec9528;
    box-shadow: 0px 4px 14px -5px rgb(0 0 0 / 31%);
    padding: 5px;
    border: unset;
    color: #ffff;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100%;
}

.check-new-employee {
  display: flex;
}

.check-new-employee small {
  flex: 1;
}

.profile-image-financials {
  height: 120px;
}

.amount-recieved {
  display: flex;
  flex-wrap: nowrap;
}

.amount-recieved .card-title {
  flex: 1;
  position: absolute;
  right: 10px;
}

.remaining-amount {
  margin-top: 20px;
}

.financial-notes{
    position: relative;
    top: 20px;
}
.sort-financials{
  width: 100%;
}
