.main_container_open_items {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}

.main_side_bar_container {
    flex: 1;
}

.main_area_container {
    flex: 7;
}

.side_bar_container {
    min-height: 100vh !important;
    position: relative;
}

.sidebar_top_bar {
    width: 100%;
    background: linear-gradient(to right bottom, #fff 48%, purple 49%);
    background-color: #fff;
    height: 110px;
    border: #272F3A solid 1px;
}

.active_tab {
    background-color: purple;
    color: #fff !important;
}

.over_holder_todo {
    border-radius: 9px 9px 0px 0px;
    -moz-border-radius: 0 0 9px 0px;
    -webkit-border-radius: 0px 0px 0px 0px;
    border: 0px solid #000000;
    width: 274px;
    margin-top: 20px;
    background-color: #ffc107;
}

.over_holder_todo_2 {
    border-radius: 9px 9px 0px 0px;
    -moz-border-radius: 0 0 9px 0px;
    -webkit-border-radius: 0px 0px 0px 0px;
    border: 0px solid #007bffba;
    width: 274px;
    margin-top: 20px;
    background-color: #007bffba;
    ;
}

.over_holder_todo_3 {
    border-radius: 9px 9px 0px 0px;
    -moz-border-radius: 0 0 9px 0px;
    -webkit-border-radius: 0px 0px 0px 0px;
    border: 0px solid #dc3545;
    width: 274px;
    margin-top: 20px;
    background-color: #dc091db5;
}

.over_holder_todo_4 {
    border-radius: 9px 9px 0px 0px;
    -moz-border-radius: 0 0 9px 0px;
    -webkit-border-radius: 0px 0px 0px 0px;
    border: 0px solid #000000;
    width: 274px;
    margin-top: 20px;
    background-color: #30de36c9;
}

.to_do_board {
    border-top: 0px solid #7f20dc;
}

.board_title {
    display: flex;
    position: relative;
    top: 6px;
    margin-left: 5px;
}

.h6_board_title {
    display: inline-flex;
    font-family: 'Ubuntu', sans-serif;
    padding-top: 10px;
    padding-left: 10px;
    color: #050525;
    width: 86%;
}

.switch_name {
    font-weight: 800 !important;
    font-family: 'Ubuntu', sans-serif;
}

.drop_down_menu_show {
    font-family: 'Baloo Tammudu 2', cursive;
    border-radius: 8%;
}

.assigned_to {
    display: flex;
    justify-content: space-between;
}

.assigned_to>span {
    margin-top: 16px;
    font-family: 'Baloo Tammudu 2', cursive;
}

.drop_item_menu {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 700 !important;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    font-family: 'Ubuntu', sans-serif !important;
    margin-top: 30px;
    margin-bottom: 30px;
}


.card_sections {
    position: relative;
    margin-top: 28px;
    -webkit-box-shadow: 13px 13px 15px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 13px 13px 15px -8px rgba(0, 0, 0, 0.75);
    box-shadow: 13px 13px 15px -8px rgba(0, 0, 0, 0.75);
    width: 264px;
    left: 4px;
}

#add_items {
    border-style: dotted;
    background-color: #fafafb;
    border-color: #d9d9dc54;
    height: 52px;
    padding-bottom: 0;
    padding-top: 12px;
    padding-right: 0px;
    padding-left: 31px;
}

.align_items_center {
    align-items: center !important;
    display: inline;
}

.add_plus {
    margin-left: 91px;
    color: #454343 !important;
    font-weight: 900 !important;
}

.card_text {
    font-size: 15px;
    font-family: 'Baloo Tammudu 2', cursive;
}

.ms {
    font-size: 14px;
    font-family: 'Baloo Tammudu 2', cursive;
    margin-top: 17px;
}

.ntf {
    float: right;
}

.drawer_header {
    width: 100%;
    height: 65px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;

}

.drawer_header>h3 {
    text-transform: capitalize;
    font-family: 'Ubuntu', sans-serif !important;
}

.close_button_drawer {
    position: absolute !important;
    right: 20px !important;
}

.drawer_body {
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;
}


.bread_crumbs_cont {
    margin-bottom: 15px;
    display: flex;
}

.task_creator_name {
    position: relative;
    top: 22px;
    text-transform: uppercase;
    font-family: 'Anek Telugu', sans-serif !important;
    font-size: 20px;
}

.loading_text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
}

.open_item_title_con {
    font-family: 'Anek Telugu', sans-serif !important;
    font-weight: 700;
    color: #1c0202;
    font-size: 28px;
    margin-bottom: 30px;
    text-decoration: underline;
    margin-top: 25px;
}

.open_item_description_container {
    width: 100%;
    min-height: 100px;
    background-color: rgb(199, 227, 227);
    border-radius: 10px;
    color: purple;
    font-family: "Roboto", sans-serif !important;
    padding: 10px;
    font-size: 17.5px;
}

.date_picker_display {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
}

/* width */
.tabselection_container ::-webkit-scrollbar {
    width: 10px !important;
    visibility: visible !important;
}

/* Track */
.tabselection_container ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
    visibility: visible !important;
}

/* Handle */
.tabselection_container ::-webkit-scrollbar-thumb {
    background: #888 !important;
    visibility: visible !important;
}

/* Handle on hover */
.tabselection_container ::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
    visibility: visible !important;
}

.add_comment_btn {
    position: relative;
    top: -14px;
    cursor: pointer;
    left: 50%;
}

.loading_text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
}

.loading_text>h6 {
    color: purple;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px !important;
}

.creator_name {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 800 !important;
    font-size: 20px !important;
}

.tabselection_container {
    margin-top: 30px;
    width: 100%;
    min-height: 400px;
    max-height: 400px;
    overflow-y: scroll;
}


.list_test_name_il {
    color: #000;
    font-weight: 900 !important;
    font-family: "Roboto", sans-serif !important;
}

.list_test_name_date {
    color: #918d8d;
    font-family: 'Quicksand', sans-serif !important;
    font-weight: 400;
    font-size: 17px !important;
}

.task_name_field {
    width: 100% !important;
}

.no_event_container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 100px;
    color: black;
    font-size: 30px;
    font-family: 'Anek Telugu', sans-serif !important;
    font-weight: 900;
}

.icon_no_item {
    font-size: 35px;
    font-weight: 900;
    margin-right: 10px;
    color: purple;
}

.event_title {
    font-family: 'Quicksand', sans-serif;
    font-size: 18px !important;
    font-weight: 400 !important;
    text-wrap: wrap;
    text-overflow: ellipsis;
}

.event_container_item {
    padding: 15px;
}

.event_container_item:hover {
    background-color: purple;
    cursor: pointer;
    color: #fff;
    width: 100%;
    border-radius: 20px;
    padding-left: 10px;
    margin-left: 10px;
    margin-top: 5px;
}

.display_profile_image_list_cont {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
}

.select_user_button {
    position: relative;
    right: -90px;
}

.creator_name {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 800 !important;
    font-size: 20px !important;
}

.project_display_container {
    border: #eee solid 1px;
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
}

.project_display_container>span {
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 18px;
}