.top_bar_container {
    width: 100% !important;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bread_project_name_one {
    background-color: #eee !important;
    color: #000 !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}


.bread_project_name {
    background-color: green !important;
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}

.bread_project_name_active {
    background-color: purple !important;
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}

@media only screen and (max-width: 530px) {
    .bread_navigation ol {
        display: block !important;
    }

    .top_bar_container {
        width: 100%;
        min-height: 150px;
        align-items: center !important;
        justify-content: center !important;
    }

}