@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

.mobile_view {
    display: none;
}

.mobile_logo_container {
    display: none;
}


.main_dashboard_container {
    width: 100%;
    overflow-x: hidden;
}

.desk_top_view {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.improved_project_name :hover {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-weight: "800";
}

/* .desk_top_side_bar {
    flex: 1;
} */

.desk_top_info_container {
    flex: 7;
}

/* === TOP BAR ==== */
.upvote_favorite_container {
    display: flex;
    flex-wrap: wrap;
}

.bread_navigation {
    margin-top: 12px;
}

.upvote_container {
    display: flex;
    flex-wrap: nowrap;
    justify-items: flex-start;
}

.upvote_container span {
    margin-left: 10px;
    text-transform: lowercase;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}

.top_bar_container {
    width: 100% !important;
}

.upvote_items {
    margin-top: 0px;
}

.upvoted_by_container {
    margin-left: 10px;
    cursor: pointer;
}

.favorite_container {
    display: flex;
    flex-wrap: nowrap;
}

.favorite_icon {
    margin-top: -1px;
    cursor: pointer;
}

.favorite_container_items {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 5px;
}

.favorite_container_items span {
    margin-left: 10px;
    text-transform: capitalize;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    margin-top: 5px;
}

.jumbotron_container {
    margin-top: 10px;
    width: 100%;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -moz-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
}

.actual_jumbotron_area {
    border-radius: 3px;

}

.tab_selection_display {
    position: relative;
    bottom: -60px;
}

.jumbotron_container .jumbotron {
    text-align: unset !important;
}

.bread_project_name_one {
    background-color: #eee !important;
    color: #000 !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}


.bread_project_name {
    background-color: green !important;
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}

.bread_project_name_active {
    background-color: purple !important;
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 800 !important;
    cursor: pointer !important;
}

.project_image_container {
    position: relative;
    top: -62px;
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    height: 38px;
    padding: 8px;
}

.project_image_container .project_image {
    box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 30px -9px rgba(0, 0, 0, 0.75);
}

.project_name_display {
    margin-left: 12px;
    font-size: 20px;
    font-weight: 700;
    color: purple;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    margin-left: 5px;
    position: relative;
}

.tab_selection_display {
    position: relative;
}

.timeline_container {
    width: 100%;
    border: 1px solid #E5EAF2;
    border-radius: 3px;
    min-height: 342px;
    background: #eee;
    overflow-y: visible;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -moz-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    max-width: 400px;
    position: relative;

}

.timeline_top_bar {
    background-color: #fff;
    height: 45px;
    align-items: center;
    display: flex;
    padding-left: 20px;
}

.timeline_top_bar h6 {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    margin: 5px;
    color: purple;
}

.time_line_button_bar {
    background-color: #fff;
    height: 45px;
    align-items: center;
    display: flex;
    padding-left: 20px;
    bottom: 33px;
    justify-content: center;
}

.selection_display {
    position: relative;
    bottom: -50px;
}

/* ===== SIDE BAR ===== */
.side_bar_container {
    overflow-y: scroll;
    height: 100vh;
    min-height: 100%;
}

.sidebar_top_bar {
    width: 100%;
    height: 150px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close_icon {
    font-weight: 800;
    position: relative;
    top: 10px;
    text-align: end;
    margin-bottom: 20px;
    right: 15px;
    cursor: pointer;
}

.active_tab {
    background-color: purple;
    color: #fff;
}

.side_bar_divider {
    background-color: white;
    color: white;
}

.project_creator h5 {
    line-height: 40px;
    text-align: center;
    margin-top: 8px;
    color: #000 !;
    font-family: "Roboto", sans-serif;
    font-weight: 200;
    font-weight: 800;
    text-transform: capitalize;
}

.creator_avator {
    display: flex;
    justify-content: center;
}

.tuc_logo {
    height: 90px;
}

.footer_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 30px;
}

.footer_name {
    text-align: center;
    margin-top: 20px
}

.footer_name>h5 {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}

/* ==== MAIN INFO AREA ====== */
.main_area_info_container {
    margin-top: 25px;

}

.info_card_container {
    min-height: 250px;
    max-height: 250px;
    background-color: #eee;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    border-radius: 3px;
    max-width: 500px;
    width: 100%;
    margin-bottom: 30px;
}

.info_card_release_container {
    margin-top: 15px;
    background-color: #eee;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    border-radius: 3px;
    width: 100%;
    min-height: 500px;
    max-height: 500px;
    overflow-y: hidden;
    overflow-x: hidden;
    margin-bottom: 30px;
}

.info_card_header {
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #cac7c7;
}

.info_card_header_title {
    color: purple;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    margin-left: 5px;
    position: relative;
    top: 3px;
    text-transform: uppercase;

}

.info_card_release_container .info_card_body {
    padding: 10px;
    overflow-y: scroll;
    min-height: 400px;
    max-height: 400px;

}

.card_info_header {
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    flex-wrap: nowrap;
}

.number_value {
    color: #000 !important;
}

.name_value {
    font-weight: 300 !important;
}

.card_info_body {
    margin-top: 0px;
}

.number_value_container {
    flex: 1;
}

.progress_circular {
    justify-self: flex-end;
}

.most_recent_activity_container {
    min-height: 100%;
    height: 90vh;
    border: #eee solid 1px;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    border-radius: 3px;
    width: 100%;
    overflow-y: scroll;
    max-height: 90vh;
    overflow-x: hidden;
}

.info_card_header_activites {
    height: 50px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card_info_body_container {
    width: 100%;
    cursor: pointer;
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    color: black;

    background: #e5c9e5;
    min-height: 84px;
    border-radius: 5px;
}

.card_info_body_container:hover {
    cursor: pointer;
    text-decoration: underline;
    color: purple;
}

.project_name_body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 6px;
    color: #000;

}

.recent_activity_mobile_container_head {
    background-color: #eee;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.recent_activity_mobile_container_head h6 {
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    margin-top: 5px;
    font-weight: 800;
}

/* ==== MOBILE DESIGN ==== */
.app_logo_mobile {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tuc_logo_mobile {
    height: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.mobile_menu_top_container {
    background: #eee;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile_menu_text {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 300;
}

.mobile_menu_text :hover {
    background-color: green;
}

.pinned_projects_container {
    margin-top: 40px;
}

.pinned_container {
    margin-top: 10px;
    width: 100%;
    border: 2px solid purple;
    min-height: 80px;
    border-radius: 5px;
    margin-bottom: 10px;
    background: #fff;
}




/* ==== MOBILE RESPONSIVENESS ==== */
@media (max-width: 801px) {
    .bread_navigation ol {
        display: flex !important;
    }

    .collaborators_container {
        margin-top: 8px !important;
        margin-bottom: 15px !important;
        border-top: 1px solid #eee !important;
        padding-top: 10px !important;
    }


    .project_image_container {
        top: -32px !important;
    }

    .jumbotron_container {
        margin-top: 0px !important;
        margin-bottom: -30px !important;
    }

    .info_card_container {
        max-width: unset !important;
        margin-bottom: 60px;
    }

    .info_card_release_container {
        margin-bottom: 100px !important;
    }

    .mobile_logo_container {
        display: inline;
        flex: 1;
    }

    .tuc_logo_mobile {
        height: 50px;
        padding-top: unset !important;
        padding-bottom: unset !important;
    }

}


/* ==== RESPONSIVENESS ====  */
@media only screen and (max-width: 800px) {
    .mobile_view {
        display: inline;
        width: 100%;
    }

    .desk_top_view {
        display: none;
    }
}