.list_item_container {
    margin-top: 25px;
}

.list_top_bar {
    display: flex;
}

.date_container {
    flex: 1;
    text-transform: capitalize;
    font-weight: 500;
    color: #9e9999;
    font-family: "Roboto", sans-serif;
    align-self: flex-end;
    text-align: end;
    position: relative;
    top: -8px;
    font-size: 15px;
}

.update_name {
    padding-top: 10px;
    font-family: "Roboto", sans-serif;
}

.buttons_container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}