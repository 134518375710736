.classic-green {
    background-color: rgb(73, 137, 8);
}

.classic-orange {
    background-color: rgb(241, 109, 61);
}

.classic-red {
    background-color: rgb(222, 31, 69);
}

.classic-grey {
    background-color: rgb(138, 143, 143);
}
