@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

.landing-container,
.landing-features-container,
.abt-container,
.prospect-container,
.pricing-container,
.footer-content,
.subscribe-content,
.comments-content,
.landing-navigation {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.landing-nav {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.landing-header {
  margin-top: 40px;
}

/* .navigation-item{
  width: 100px;
} */

.navigation-bar li a {
  text-align: center;
}

.nav-button {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #FF9B00;
  border-radius: 8px;
  border: none;
}

.welcome-msg {
  margin-top: auto;
  margin-bottom: auto;
}

.welcome-msg h1 {
  font-family: "Montserrat", sans-serif;
  color: #2e2e2e;
  font-weight: bold;
}

.welcome-msg p {
  color: #2e2e2e;
  margin-top: 10px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
}

.welcome-video {
  margin-top: 20px;
}

.landing-cta-btn {
  border: unset;
  background: #ec9528;
  box-shadow: 0px 4px 14px -5px rgba(0, 0, 0, 0.31);
  border-radius: 6px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-top: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 35px;
  padding-left: 35px;
}

.together-bold {
  font-weight: 800;
}

.landing-cta-btn-sm {
  border: unset;
  background: #ec9528;
  box-shadow: 0px 4px 14px -5px rgba(0, 0, 0, 0.31);
  border-radius: 6px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-top: 20px;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-right: 100px;
  padding-left: 100px;
}

.centre-button-landing {
  display: flex;
  justify-content: center;
}

.embed-responsive {
  max-height: 423px;
  border-radius: 6px;
}

.landing-features-content {
  margin-bottom: 80px;
  margin-top: 100px;
}

.landing-features {
  text-align: center;
}

.landing-features p,
.landing-features h5 {
  font-family: "Montserrat", sans-serif;
}

.abt-container,
.prospect-container,
.pricing-container {
  margin-bottom: 100px;
  margin-top: 50px;
}

.abt-img {
  width: 755px;
  height: 573px;
  border-radius: 8px;
}

.abt-text {
  margin-top: 15px;
}

.abt-card-text p,
.abt-card-text h5 .prospect-text p,
.prospect-text h5,
.pricing8,
.contact-content {
  font-family: "Montserrat", sans-serif;
}

.abt-card-header {
  border: none;
  background-color: white;
}

.prospect-text {
  margin-top: auto;
  margin-bottom: auto;
}

.prospect-img {
  width: 739px;
  max-height: 573px;
}

.prospect-card-text {
  max-width: 755px;
}

/* ul {
  list-style-type: none;
} */

.pricing8 h1,
.pricing8 h2,
.pricing8 h3,
.pricing8 h4,
.pricing8 h5,
.pricing8 h6 {
  color: #3e4555;
}

.prospect-12 {
  color: #000 !important;
  font-size: 20px !important;
}

.marketplace-anchor a {
  color: #000 !important;
  cursor: pointer;
}

.marketplace-anchor a:hover {
  text-decoration: unset;
}

.pricing8 h5 {
  line-height: 22px;
  font-size: 18px;
}

.pricing8 .subtitle {
  line-height: 24px;
}

.pricing8 .display-5 {
  font-size: 3rem;
}

.pricing8 .font-14 {
  font-size: 14px;
}

.pricing-box {
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
}

.pricing8 .pricing-box sup {
  top: -20px;
  font-size: 16px;
}

.slide {
  transform: scale(0.6);
  transition: transform 300ms;
}

.activeSlide {
  transform: scale(1);
}

.comments-content {
  padding-bottom: 60px;
}

.comments-container {
  background-color: #f4f5f7;
}

.comment-header {
  padding-top: 50px;
  padding-bottom: 40px;
}

.comment-header hr {
  width: 70px;
  border: 2px solid #FF9B00
}

.comment-card {
  width: 300px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

.comment-img {
  border-radius: 50%;
  width: 118px;
  height: 114px;
  margin-left: auto;
  margin-right: auto;
}

.contact-container {
  background-color: #f4f5f7;
  padding-bottom: 80px;
}

.contact-content {
  width: 55%;
  margin-left: auto;
  margin-right: auto;
}

.subscribe-img {
  width: 300px;
}

.subscribe-title {
  width: 650px;
  margin-top: auto;
  margin-bottom: auto;
}

.subscribe-input {
  border-radius: 8px 0px 0px 8px;
  height: 60px;
}

.subscribe-btn {
  background: #ff9b00;
  border-radius: 8px 0px 0px 8px;
  padding-right: 50px;
  padding-left: 50px;
}

.subscribe-main {
  padding-top: 50px;
  padding-bottom: 50px;
}

.Footer-container {
  background-color: #252b42;
}

.footer-content {
  color: whitesmoke;
}

.footer-logo {
  width: 33px;
}

.footer-group {
  padding-top: 100px;
}

.footer-group-one p {
  width: 300px;
}

.footer-group-two ul,
.footer-group-three ul,
.footer-group-four ul {
  list-style-type: none;
  padding: 0;
}

.footer-content hr {
  border: 1px solid #303e74;
}

.landing-features img {
  height: 100px;
}

@media (max-device-width: 991px) {
  .landing-nav-img {
    margin-left: 10px;
  }

  .welcome-msg-content h1 {
    margin-left: auto;
    margin-right: auto;
    line-height: 43px;
    text-align: center;
  }

  .welcome-msg-content p {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

@media (min-device-width: 1200px) {
  .welcome-msg h1 {
    font-size: 50px;
  }
}

@media (max-device-width: 576px) {
  .landing-cta-btn-sm {
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 45px;
    padding-left: 45px;
  }
}

@media (min-device-width: 992px) and (max-device-width: 1200px) {
  .welcome-msg h1 {
    font-family: "Roboto", sans-serif;
    font-size: 40px;
  }
}

@media (min-device-width: 768px) and (max-device-width: 991px) {}