   .ribbon {
    font-size: 20px!important;
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    width: 75%;
    position: relative;
    background: #ff9b00;
    color: #fff;
    text-align: center;
    padding: 1em 1.2em;
    border-radius: 10px;
    margin: 0 auto 1.5em;
    border-bottom-left-radius:20%;
    border-bottom-right-radius:20%;
}
   .ribbon:before, .ribbon:after {
    content: "";
    position: absolute;
    margin-bottom: 10px;
    display: block;
    bottom: -1em;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 1.5em solid #e68b04;
    z-index: -1;
   }
   .ribbon:before {
    left: -2em;
    border-right-width: 1.5em;
    border-left-color: transparent;
   }
   .ribbon:after {
    right: -2em;
    border-left-width: 1.5em;
    border-right-color: transparent;
   }
   .ribbon .ribbon-content:before, .ribbon .ribbon-content:after {
    content: "";
    position: absolute;
    display: block;
    border-style: solid;
    border-color: #804f7c transparent transparent transparent;
    bottom: -1em;
   }
   .ribbon .ribbon-content:before {
    left: 0;
    border-width: 1em 0 0 1em;
   }
   .ribbon .ribbon-content:after {
    right: 0;
    border-width: 1em 1em 0 0;
   }

   .non-semantic-protector { position: relative; z-index: 1; }