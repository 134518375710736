@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

.main_title_area {
    width: 100%;
    background-color: #eee;
    text-align: center;
    color: black;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    font-weight: 400;
}

.close_icon {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
}

.main_content_area{
    min-height: 300px;
}

.main_navigation_container{
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.main_content_display_area{
    margin-top: 20px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.main_content_display_area .invitation-card{
    max-width: 400px !important;
    width: 400px !important;
    min-width: 400px !important
}

.profile_display_container_card{
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
}

.profile_display_container_card_content_area{
    overflow-y: scroll;
}

.list_item_list{
    position: relative;
     top: 13px;
     font-family: 'Roboto Condensed', sans-serif !important;
     font-weight: 700;
}

.check_box{
    position: relative;
    top: 18px;
}

.send_action_container{
    position: absolute;
    bottom: 2px;

    margin-top: 10px;
}