@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.main_container {
    width: 100%;
    display: flex;
}

.main_display_area {
    width: 100%;
}

.header_image_area {
    width: 100%;
    background-image: url("../../assets/backdropProfile.jpg");
    background-size: cover;
    background-position: center;
    height: 150px;

}

.profile_image_area {
    position: relative;
    top: -40px;
    margin-left: 50px;
}

.profile_name_container {
    position: relative;
    top: -80px;
    margin-left: 160px;
}

.actual_profile_name {
    color: black;
    font-weight: 800;
    text-transform: capitalize;
}

.profile_role {
    color: #515050;
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
}

.profile_edit_button {
    position: absolute;
    right: 20px;
    margin-top: 50px;
}

.social_media_icons {
    position: relative;
    display: flex;
    top: -80px;
    margin-left: 160px;

}

.profile_nav {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.profile_summary_container {
    width: 100%;
    margin-top: 20px;
}

.profile_reusable_card_container {
    height: 250px;
    max-height: 250px;
    min-width: 400px;
    min-height: 250px;
    width: 100%;
    background-color: #eee;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    border-radius: 3px;
    overflow-y: scroll;
    margin-bottom: 25px;
}

.profile_reusable_card_header {
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    background: #fff;
    width: 100%;
    min-height: 50px;
    padding: 8px;
    margin-bottom: -12px;

}

.profile_reusable_card_header>span {
    color: purple;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    margin-left: 5px;
    position: relative;
    top: 3px;
    text-transform: uppercase;
}

.profile_reusable_card_body {
    margin: 15px;
}

.actual_details {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 300;
    padding: 8px;
}

.badges_container {
    border: 1px solid #eee;
    min-height: 250px;
    border-radius: 10px;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, .51);
}