@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,700;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

.scroll_area {
    overflow-y: scroll !important;
    margin-bottom: 200px;
}

.comment_box_area_cont {
    display: flex;
}

.comment_content_area {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}


.profile_container_images {
    flex: 1;
}


.main_comment_container {
    flex: 12;
}


.comment_box_area_box {
    padding: 2px;
    width: 100%;

}

.tool_bar_container_box {
    min-height: 0px !important;
}

.each_comment_container {
    display: flex;
    flex-wrap: nowrap;
    background-color: #E8E8E8;
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #000;
}

/* ==== Comments top bar ==== */
.comments_number_over_view {
    padding: 20px;
    color: rgb(85, 82, 82);
    font-weight: 500;
    text-align: center;
}

.comments_number_over_view>span {
    text-transform: capitalize;
    font-size: 18px;
    margin-left: 10px;
}

.module_overview_container {
    min-height: 300px;

}

.heading_title {
    padding: 10px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    color: purple;
    width: 100%;
    min-width: 100%;
    text-align: center;
    box-shadow: -2px 1px 26px -1px rgba(150, 150, 150, 0.8);
    -webkit-box-shadow: -2px 1px 26px -1px rgba(150, 150, 150, 0.8);
    -moz-box-shadow: -2px 1px 26px -1px rgba(150, 150, 150, 0.8);
}



.heading_title>h4 {
    font-weight: 800;
    margin-bottom: -15px;
    font-size: 1.3rem !important;
}

.label_title {
    position: relative;
    font-size: 20px !important;
    margin-top: 10px;
    left: 10px;
    color: green;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 800;
}

.details_container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px;
}

.details_label_container {
    display: flex;
    flex-wrap: nowrap;
}

.actual_details {
    position: relative;
    top: 8px;
    left: 20px;
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
}

/* === comments top bar ==== */

.comment_content_container {
    margin-left: 10px;
    position: relative;
    width: 100%;

}

.date_and_action_items {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 3px;
}

.comment_details {
    padding: 0px;
    border-radius: 10px;
    width: 100%;
}

.creator_name {
    font-weight: 900;
    font-family: "Roboto", sans-serif;
}

.creator_date {
    font-weight: 900;
    font-family: "Roboto", sans-serif;
}

.comment_details {
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-weight: 400;
}

.action_items_container {
    border: 1px solid #E8E8E8;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    position: relative;
    top: -32px;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.action_items_buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.comment_box_title_bar {
    display: grid;
    width: 100%;
    min-height: 200px;
    margin-bottom: 10px;
    border: 1.5px solid #E8E8E8;
    margin-top: 10px;
    border-radius: 6px;
    overflow-y: scroll;
    box-shadow: -3px 1px 23px 0px rgba(0, 0, 0, 0.26) !important;
    -webkit-box-shadow: -3px 1px 23px 0px rgba(0, 0, 0, 0.26) !important;
    -moz-box-shadow: -3px 1px 23px 0px rgba(0, 0, 0, 0.26) !important;
    max-height: 300px;
}

.comment_box_title_label {
    width: 200px;
    margin-left: 10px;
    margin-top: 10px;
    background: green;
    height: 35px;
    color: #fff;
    text-align: center;
    padding: 6px;
    border-radius: 5px;
    font-weight: 800;
    text-transform: uppercase;

}

.comment_box_title_label_details {
    margin-left: 10px;
    margin-top: 10px;
    color: #000;
}

.actions_buttons_responses {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    -webkit-box-shadow: -2px 1px 61px -15px rgb(112 106 106 / 75%);
    -moz-box-shadow: -2px 1px 61px -15px rgba(0, 0, 0, 0.75);
    box-shadow: -2px 1px 61px -15px rgb(150 141 141 / 75%);
    margin-bottom: 25px;

}

.creator_date_reponses {
    padding-left: 20px;
    font-weight: 900;
    font-family: "Roboto", sans-serif;
}

.responses_container {
    border: 1px solid #a29999;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
    -webkit-box-shadow: -2px 1px 61px -15px rgb(112 106 106 / 75%);
    -moz-box-shadow: -2px 1px 61px -15px rgba(0, 0, 0, 0.75);
    box-shadow: -2px 1px 61px -15px rgb(150 141 141 / 75%);
    margin-top: 25px;
    background: #fff;
}

.display_of_upvoted_profiles {
    display: flex;
    flex-wrap: nowrap;
}

.avators {
    padding-top: 7px;
}

.each_comment_container_reponses {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 30px;
    background-color: #fff;
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.response_action_edit_items {
    display: flex;
    flex-wrap: nowrap;
}

.alert_displayed_profiles {
    min-height: 50px;
}

.upvoted_alert_header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
}

.close_icon_button {
    position: absolute;
    right: 35px;
    margin-top: -45px;
    cursor: pointer;
}

.new_comment_reply_label {

    color: green;
    text-transform: uppercase;
    font-weight: 800;
    text-decoration: underline;
}

/* ==== MOBILE RESPONSIVENESS ==== */
@media (max-width: 801px) {

    .responses_container {
        margin-left: -32px;
    }

    .actions_buttons_responses {
        margin-left: -32px;
        width: 100%;
    }

}