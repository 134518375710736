.dashboard-card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.dashboard-card {
  display: flex;
  width: 300px;
  height: 250px;
  margin: 8px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  background-repeat: no-repeat;
}

.dashboard-card:hover {
   width: 320px;
  height: 280px;
}