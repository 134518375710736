@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

.custom-modal {
  position: fixed; 
  opacity: 0.1;
  z-index: 2; 
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto;
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
  font-family: "Roboto", sans-serif;
  animation-name: modal-opacity;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

@keyframes modal-opacity {
  100% {
    opacity: 1;
  }
}

.custom-modal-header {
  width: 100%;
  height: 80px;
  background-color: #047C7C;
  color: #fff;
  display: flex;
  flex: row;
  justify-content: space-between;
  padding-left: 16px;
  padding-top: 24px;
}

.custom-modal-header button {
  position: relative;
  right: 8px;
  bottom: 10px;
  border: unset;
  background: unset;
  color: #fff;
}

.custom-modal-body {
  background-color: #fefefe;
}

.custom-modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.custom-modal-close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.custom-modal-close:hover,
.custom-modal-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}