.notifications-container {
  position: absolute;
  background: #f8fafc;
  top: 100px;
  right: 10px;
  width: 380px;
  height: 600px;
  border-radius: 10px;
  overflow-y: scroll;
  z-index: 2;
}

.notifications-container-header {
  padding: 8px;
  border-bottom: 1px solid #ccced2;
}

.notifications-container-header button {
  margin: 6px;
  border: unset;
  border-radius: 6px;
  background: #ccced2;
}

.notifications-list {
  width: 100%;
  padding: 5px;
}

.notifications-list-item {
  display: flex;
  list-style-type: none;
  border-bottom: 1px solid #ccced2;
}

.notifications-list-item-left {
  display: flex;
  width: 18%;
  justify-items: center;
  align-items: center;
}

.notifications-list-item-center {
  width: 73%;
  color: #595b5e
}

.notifications-list-item-center:hover {
  text-decoration: none;
  color: #f3ac08;
}

.notifications-list-item-right {
  display: flex;
  width: 10%;
  justify-items: center;
  align-items: center;
}

.notifications-list-item-right button {
  border: unset;
  background: unset;
}
