@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

.profile-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  background: linear-gradient(90deg,
      rgba(48, 49, 50, 0.497) 0%,
      rgba(35, 38, 38, 0.709) 20%),
    url("../assets/profileheadBg.png") -1230px 10px;
  background-size: cover;
  background-position: center;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #fff;
  justify-content: left;
  align-items: left;
}

.profile-header-cont {
  width: 100%;
}


.user-details {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.user-details-info {
  height: 100%;
  text-align: left;
  padding: 16px;
}

.user-details-info h3 {
  margin-top: 16px;
  font-size: 28px;
}

.profile-avatar {
  width: 200px;
  border-radius: 8px;
  border: 2px solid #fff;
}

.edit-area {
  text-align: right;
  padding: 20px;
}

.prof-edit-btn {
  width: 170px;
  height: 50px;
  font-size: 16px;
  color: #fff;
  background: unset;
  border-radius: 6px;
  border: 1px solid #fff;
  margin: 20px;
}

.dots-temp-btn {
  position: relative;
  top: 5px;
  width: 70px;
  height: 50px;
  background: unset;
  border-radius: 6px;
  border: 1px solid #fff;
  color: #fff;
}

.plus-icon {
  display: none;
}

.mobile-edit {
  display: none;
}

.profile-main {
  width: 100%;
}

.profile-nav {
  padding-top: 16px;
  margin: 0;
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
}

.profile-nav-ul {
  position: relative;
  right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.profile-nav ul li {
  font-size: 20px;
  list-style-type: none;
  cursor: pointer;
}

.profile-nav ul li:hover {
  border-bottom: 1px solid #f5913e;
}

.profile-summary {
  width: 100%;
  display: flex;
  flex-direction: row;

}

.profile-summary-details {
  width: 70%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
}

.profile-summary-feed {
  width: 30%;
}

.profile-mv-card {
  display: flex;
  flex-direction: row;
  height: 200px;
  border-bottom: 1px solid #e2e2e2;
}

.summary-contact-card {
  display: flex;
  flex-direction: row;
  height: 300px;
}

.profile-mv-card-icon {
  width: 5%;
  padding: 8px;
}

.profile-mv-card-details {
  width: 95%;
  padding: 24px;
}

.profile-mv-card-details h3 {
  color: #309897;
  font-size: 20px;
  font-weight: 500;
}

.profile-mv-card-details h4 {
  margin-top: 20px;
}

.profile-mv-card-details p,
.profile-mv-card-details h4 {
  color: #2e2e2e;
  font-size: 18px;
  font-weight: 300;
}

.profile-recents {
  width: 90%;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  margin: 16px;
}

.profile-recents-header {
  background: #047c7c;
  height: 56px;
  padding: 16px;
}

.profile-recents-header h3 {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.profile-idea-card h5 {
  font-size: 16px;
  font-weight: 500;
}

.profile-idea-card h6 {
  font-size: 15px;
  font-weight: 400;
}

.profile-idea-card h5,
.profile-idea-card h6 {
  color: #2e2e2e;
}

/******CORE VALUES *******/

.profile-core-values {
  margin: 0;
  padding: 6px;
  width: 100%;
}

.profile-core-values h1,
.profile-core-values h2 {
  text-align: center;
  color: #309897;
  font-weight: 600;
}

.core-value-card {
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 50px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #f5913e;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
}

.core-value-card i {
  position: relative;
  bottom: 8px;
  color: #309897;
}

.core-value-card span {
  position: relative;
  bottom: 8px;
  font-size: 24px;
  color: #2e2e2e;
}

.core-value-form-container {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.core-value-form-container form input {
  width: 80%;
  margin: 8px;
}

.core-value-form-container form button {
  position: relative;
  bottom: 5px;
  width: 20%;
  margin: 8px;
}

.no-cores-thumbnail {
  width: 300px;
}

/**********NETWORK*******/

.profile-net-form {
  width: 90%;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
}

.network-contact-card {
  width: 95%;
  display: flex;
  height: 200px;
  flex-direction: row;
  border-radius: 8px;
  margin-top: 6px;
  margin-left: auto;
  margin-right: auto;
}

.network-contact-card-avatar {
  width: 150px;
  height: 150px;
}

.network-contact-card-details {
  display: flex;
  flex-direction: column;
}

.profile-project-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.project-card {
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 390px;
  margin: 10px;
  border-radius: 8px;
  padding: 8px;
}

.project-card-image {
  background-color: rgb(247, 235, 235);
  width: 100%;
  height: 167px;
  border-radius: 4px;
}

.project-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-card-title {
  white-space: nowrap;
  overflow-x: scroll;
  padding: 6px;
}

.project-card-details {
  width: 100%;
  padding: 6px;
  height: 165px;
  overflow-y: scroll;
}

.project-card-btn {
  border: unset;
  background: #309897;
  color: #ffffff;
  width: 280px;
  height: 46px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  text-align: center;
  text-decoration: none !important;
  font-weight: 500;
  padding: 8px;
}

.project-card-btn:hover {
  background: #ffffff;
  color: #309897;
  border: 1px solid #309897;
}

/******************************/

/*=====MEDIA QUERIES=====*/

@media only screen and (max-width: 1026px) {
  .profile-mv-card-icon img {
    width: 45px;
  }

  .profile-mv-card-details-title {
    margin-left: 16px;
  }

  .profile-summary-details {
    width: 60%;
  }

  .profile-summary-feed {
    width: 40%;
  }
}

@media only screen and (max-width: 770px) {
  .profile-header {
    height: 250px;
  }

  .user-details-info h3 {
    margin-top: 16px;
    font-size: 18px;
  }

  .profile-avatar {
    width: 120px;
  }

  .user-details-info {
    width: 70%;
    height: 100%;
  }

  .head-social-links {
    display: none;
  }
}

.toggle-bar-container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.display-value {
  width: 250px;
  min-height: 100vh;
  max-height: 100vh;
  height: 100vh;
  min-width: 250px;
  max-width: 250px;
  border-right: 0.5px solid rgb(192, 184, 184);
}

.display-my-suggetions-on-prfl {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.suggestion-card-share-cont {
  padding: 6px;
  margin: 10px;
  border-radius: 8px;
}

@media only screen and (max-width: 680px) {
  .profile-header {
    flex-direction: column;
  }

  .profile-header-cont {
    text-align: center;
    width: 100%;
  }

  .profile-avatar {
    width: 90px;
  }

  .user-details {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
  }

  .user-details-info {
    position: relative;
    bottom: 8px;
    width: 100% !important;
    text-align: center !important;
    padding: 0 !important;
  }

  .user-details-info h3 {
    margin-top: 8px;
    font-size: 14px;
  }

  .profile-header-cont-2 {
    display: none !important;
  }

  .mobile-edit {
    display: unset;
    font-size: 12px;
    color: #fff;
    background: unset;
    border-radius: 6px;
    border: 1px solid #fff;
    padding: 8px;
  }

  .profile-nav ul {
    margin-left: 0 !important;
    overflow-x: scroll;
    white-space: nowrap;
  }

  .profile-nav ul li {
    font-size: 14px !important;
    list-style-type: none;
    cursor: pointer;
    display: inline;
    margin-right: 5px;
    white-space: nowrap;
  }

  .profile-main {
    width: 100%;
    padding-top: 8px;
  }

  .profile-summary {
    flex-direction: column;
    margin: 8px;
  }

  .profile-summary-details {
    width: 100%;
  }

  .profile-summary-feed {
    width: 100%;
  }

  .profile-mv-card-details p,
  .summary-contact-card h4,
  .profile-recents p,
  .profile-recents h5 {
    font-size: 13px !important;
  }

  .profile-core-values h1 {
    font-size: 24px;
  }

  .plus-icon {
    display: unset;
  }

  .add-word {
    display: none;
  }

  /**********NETWORK*******/

  .network-contact-card {
    height: 130px;
  }

  .network-contact-card-avatar {
    width: 60px;
    height: 60px;
  }

  .network-contact-card h4 {
    font-size: 12px;
  }
}