@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

.marker {
  width: 15px;
  height: 15px;
}

.user-badge-container {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
}

.user-badge {
  margin: 10px;
  border: 1px solid #047C7C;
  border-radius: 50%;
}

.user-badge-top {
  height: 119px;
  border-radius: 50%;
}

.user-badge-top img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}


.profile-avatar-thumb {
  height: 64px;
  width: 64px;
  border-radius: 50%;
}

.profile-avatar-thumb-sm {
  height: 44px;
  width: 44px;
  border-radius: 50%;
}

.profile-avatar-thumb-xs {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.profile-avatar-badge {
  height: 30px;
  width: 30px;
  background-color: #047C7C;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 50%;
  padding: 5px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}

.mv {
  position: relative !important;
  bottom: 100px;
}

.mv-sg {
  position: relative !important;
  bottom: 7px;
}

.app-add-btn {
  background-color: #fff;
  color: #047C7C;
  border: 1px solid #047C7C;
  width: 150px;
  height: 40px;
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  cursor: pointer;
}

.app-add-btn-2 {
  background-color: #fff;
  color: #047C7C;
  border: 1px solid #047C7C;
  width: 150px;
  height: 30px;
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.app-add-btn:hover {
  background-color: #047C7C;
  color: #E0E0E0;
}

.no-dec-link {
  text-decoration: none !important;
}

.no-btn {
  border: unset;
  background-color: unset;
  color: #ffffff;
}

.app-head-title {
  color: #047C7C;
  font-family: "Roboto", sans-serif;
}

@media only screen and (max-width: 825px) {
  .profile-avatar-thumb {
    height: 44px;
    width: 44px;
  }

  .profile-avatar-thumb-sm {
    height: 34px;
    width: 34px;
  }
}

@media only screen and (max-width: 500px) {

  .tag-badge {
    height: 20px;
    width: 100px;
    padding: 2px;
  }

  .tag-badge span {
    font-size: 12px;
    bottom: 6px;
  }

}