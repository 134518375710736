.container {
    width: 100%;
    border: 1px solid #E5EAF2;
    border-radius: 3px;
    min-height: 180px;
    background: #eee;
    overflow-y: visible;
    box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    -moz-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
    max-width: 400px;
    position: relative;
    min-width: 400px;
    margin-bottom: 50px;
}

.container_header {
    background-color: #fff;
    height: 45px;
    align-items: center;
    display: flex;
    padding-left: 20px;
}

.container_header>h6 {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    margin: 5px;
    color: purple;
}

.container_footer {
    background-color: #fff;
    height: 45px;
    align-items: center;
    display: flex;
    padding-left: 20px;
    bottom: 33px;
    justify-content: center;
    position: absolute;
    bottom: 0px;
    width: 100%;
}