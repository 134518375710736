@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");

* {
  scroll-behavior: smooth;
}



.main-body-area-single-project {
  width: 100% !important;
  display: flex;
  flex-wrap: nowrap;
}



.project-static-area-space {
  width: 96%;
  overflow-x: hidden;
}

.project_side_bar_space {
  width: 4%;
}

.project-link-container {
  display: none;
  position: relative;
  top: 100px;
  padding-left: 16px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
}

.project-link-container i {
  color: #19739a;
}


.project-head-container {
  padding: 6px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
}


.project-head button:hover {
  background-color: #047C7C;
  color: #fff;
}

.project-header {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #2E2E2E;
  margin-left: 6px;
  position: absolute;
  top: -20px;
  text-transform: capitalize;
}

.project-header-btn-bk {
  border: unset;
  background: unset;
  font-weight: 500;
  color: #309897;
  font-family: "Roboto", sans-serif;
}

.project-header a {
  color: #309897;
  text-decoration: none;
}

.project-header a:hover {
  color: #309897;
  text-decoration: underline;
}

.project-carousel-controls {
  position: relative;
  top: 64%;
  display: flex;
  justify-content: space-between;
  width: 71.5%;
  height: 30px;
  z-index: 1;
}

.project-carousel-controls button {
  color: #fff;
  border: unset;
  background: unset;
}

.project-modify-button {
  margin-top: 25px;
  width: 170px;
  height: 48px;
  background: #c9c3c3;
  border: none;
  margin-left: 6px;
  border-radius: 4px;
}

.statistics-container {
  padding: 0px;
  font-family: "Roboto", sans-serif;
}

.statistic-icon-container {
  height: 260px;

}

inline .project-invitation-button {
  background: #c9c3c3;
  top: -88px;
  border-radius: 4px;
  border: none;
  color: #2C2C2C;
  left: 25px;
  opacity: 1;
  position: absolute;
  text-transform: uppercase;
  width: 170px;
  height: 48px;
  font-weight: 400;
}

.timeline-title {

  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 17px !important;
  line-height: 2px;
  text-align: center;
  color: #2E2E2E;
  text-transform: uppercase;
  margin-bottom: 45px;
}


.project-upvote-button {
  box-shadow: 0px 4px 14px -5px rgb(0 0 0 / 31%);
  border-radius: 4px;
  border: none;
  color: #000;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  position: absolute;
  top: -80px;
  left: 62%;
  width: 149px;
  height: 48px;
  font-weight: 400;
}

.single-unfollow {
  background-color: green;
  color: white;
}

.statistics-card {
  display: inline-block;
  padding: 6px;
  color: #ffffff;
  width: 305px;
  height: 150px;
  font-weight: 200;
  border-radius: 8px;
  margin: 3px;
  position: relative;
}

.statistics-card h5 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
}

.statistics-card img {
  width: 290px;
  height: 20px;
}

.stc-planning {
  background: url('../assets/planning-thb.png'), linear-gradient(90deg, #091F3E 0%, #3F6BB6 100%);
  background-repeat: no-repeat;
}

.stc-design {
  background: url('../assets/design-thb.png'), linear-gradient(90deg, #084337 0%, #2DC56E 100%);
  background-repeat: no-repeat;
}

.stc-dev {
  background: url('../assets/dev-thb.png'), linear-gradient(90deg, #AF680E 0%, #EFA947 100%);
  background-repeat: no-repeat;
}

.stc-testing {
  background: url('../assets/test-thb.png'), linear-gradient(90deg, #A6374C 55.16%, #FD868C 136.26%);
  background-repeat: no-repeat;
}

.sdg-container {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}

.sdg-container h3 {
  flex: 1;
}

.sdg-container button {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  margin-left: 30px;
}

.sdg-container button i {
  padding-right: 10px;
  padding-left: 10px;
}

.statistic-duration-container {
  position: relative;
  top: 30px;
  width: 20%;
  height: 470px;
  display: flex;
  flex-direction: column;
  margin: 8px;
  padding: 16px;
  text-align: center;
  color: #595757;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 14px 20px rgb(58 76 95 / 25%) !important;
  border-radius: 14px !important;
  opacity: 0.9;
}

.search-Project-title-cont {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
}

.s-project-main {
  width: 100%;
}

.sdg-images-edit {
  display: flex;
  flex-wrap: wrap;
}

.s-project-nav {
  width: 100%;
  border-bottom: 1px solid #aba9a9;
  background-color: #ffffff;
  padding: 8px;
}

.remove-sdg-button {
  position: absolute;
  left: 100px;
  top: -14px;
  font-size: 30px;
}

.remove-sdg-button i {
  font-weight: 900;
  cursor: pointer;
  color: #000;
}

.s-project-nav ul {
  position: relative;
  top: 10px;
  text-align: center;
  overflow-x: scroll;
  white-space: nowrap;
  border: 1.5px solid green;
  padding: 4px;
  border-radius: 6px;
}

.s-project-nav ul li {
  display: inline-block;
  font-size: 20px;
  list-style-type: none;
  cursor: pointer;
  margin-right: 40px;
}

.s-project-nav ul li:hover {
  border-bottom: 1px solid #f5913e;
}

.s-project-summary {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 16px;
}

.s-project-summary-details {
  width: 70%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
}

.s-project-summary-feed {
  width: 30%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  flex-direction: column;
}

.project-quick-links-container {
  border: 1px solid #E5EAF2;
  border-radius: 3px;
  background: #eee;
  overflow-y: visible;
  box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  -moz-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  max-width: 1000px;
  width: 100%;
}

.s-project-ipm-card {
  height: 300px;
  overflow-y: scroll;
  border-bottom: 1px solid #e2e2e2;
  background-color: #eee;
  box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  margin-bottom: 8px;
  width: 100%;
}

.project-dec-title-bar-ss {
  box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
}

.project-dec-title-bar-ss {
  background: #fff;
  width: 100%;
  min-height: 50px;
  padding: 8px;
  margin-bottom: -12px;
  margin-top: 10px;
}

.project-description-project-title-tt {
  color: #000 !important;
  font-weight: 900 !important;
  text-decoration: underline;
  font-family: 'Anek Telugu', sans-serif !important;
  font-size: 1.4rem !important;
}

.project-dec-title-bar-ss span {
  color: purple;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  margin-left: 5px;
  position: relative;
  top: 3px;
  text-transform: uppercase;

}

.actual-description {
  color: #2e2e2e;
  font-size: 18px;
  font-weight: 300;
  padding: 8px;
}

.s-project-ipm-card-icon-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  background-color: #fff !important;
  font-weight: bold;
  text-transform: uppercase;
  color: purple;
  padding: 8px;
}

.project-description-container-ss {
  min-height: 310px !important;
  height: 310px !important;
  background: #eee;
  margin-top: 10px;
  box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  -webkit-box-shadow: -2px 2px 5px 1px rgba(166, 158, 158, 0.51);
  border-radius: 3px;
  overflow-y: scroll;
}

.s-project-ipm-card-icon-container h5 {
  margin: 5px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.s-project-ipm-card-icon-container img {
  height: 35px;
}

.s-project-ipm-card-details {
  width: 95%;
  padding: 24px;
}

.s-project-ipm-card-details h3 {
  color: #309897;
  font-size: 20px;
  font-weight: 500;
}

.s-project-ipm-card-details h4 {
  margin-top: 20px;
}

.s-project-ipm-card-details p, .s-project-ipm-card-details h4 {
  color: #2e2e2e;
  font-size: 18px;
  font-weight: 300;
}

.s-project-collaborations {
  position: relative;
  padding: 8px;
  width: 100%;
  height: 300px;
  border-bottom: 1px solid #c3c0c0;
  overflow-y: scroll;
  display: flex;
  margin-left: 16px;
  margin-right: 16px;
  flex-direction: row;
  flex-wrap: wrap;
}

.center-c {
  text-align: center !important;
}

.s-project-collaborations-header {
  padding: 16px;
  background-color: #fff;
  height: 45px;
  align-items: center;
  display: flex;
  padding-left: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.s-project-collaborations-header h3 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin: 5px;
  color: purple;
  font-size: 1rem !important;

}

.s-project-collaborations-header button {
  border: unset;
  background: unset;
  color: #000;
  cursor: pointer;
  position: relative;
  margin-left: 28px;
  font-size: 24px;
  font-weight: 800;
}

.s-project-collaborators {
  position: absolute;
  bottom: 0;
  left: 20px;
  color: #309897;
}

.s-project-extras {
  padding: 24px;
  color: #a6a1a1;
  border-bottom: 1px solid #c3c0c0;
}

.s-project-extras h6 {
  line-height: 40px;
}

.goal-coording-operator {
  display: flex;
  flex-direction: row;
}

.collab-badge {
  height: 40px;
  width: 40px;
  background-color: #047C7C;
  color: white;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  border-radius: 50%;
  margin: 3px;
  padding: 5px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}

.assumption-container {
  margin-bottom: 88px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
}

.assumption-card-cont {
  margin-right: 20px;

}

.assumptions-pagination-container {
  margin-top: -10px;
  margin-bottom: 50px;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}

.s-project-collaborations img {
  margin: 3px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.s-project-quicklinks-list {
  color: #047C7C;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.s-project-quicklinks-list ul li {
  list-style-type: square;
}

.s-project-description {
  font-size: 21px;
  padding: 16px;
  border: 1px solid #ded9d9;
  border-radius: 8px;
}

.project-goal {
  font-size: 20px;
}

.project-comment-form {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
}

.project-comment-form input, .project-comment-form textarea {
  width: 100%;
  margin: 8px;
  border: 1px solid #C4C4C4;
  border-radius: 8px;
  font-family: Roboto;
  font-size: 18px;
  font-style: italic;
  font-weight: 200;
}

.project-comment-card {
  width: 97%;
  display: flex;
  flex-direction: row;
  margin: 16px;
}

.project-comment-edge {
  width: 10%;
  height: 100%;
  color: #2e2e2e;
  padding: 8px;
}

.project-comment-details {
  width: 80%;
  height: 100%;
  color: #2e2e2e;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.edge-time {
  font-family: Roboto;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: right;
}

.project-comment-btns {
  width: 85%;
  padding: 8px;
}

.project-comment-btns ul {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.project-comment-btns-2 ul {
  display: flex;
  flex-direction: row;
}

.project-comment-btns ul li {
  color: #595757;
  list-style-type: none;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}

.project-comment-btns-2 ul li {
  color: #595757;
  list-style-type: none;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}


.reply-form {
  display: flex;
  flex-direction: row;
}

.reply-form-btn {
  position: relative;
  top: 9px;
  color: #C4C4C4;
  background-color: #047C7C;
  border-radius: 8px;
  width: 100px;
  height: 30px;
}

.comment-reply-card {
  display: flex;
  flex-direction: row;
}

.comment-reply-edge {
  width: 10%;
  height: 100%;
  color: #2e2e2e;
  padding: 8px;
}

.support-btn {
  margin-left: 10px;
}

.comment-reply-details {
  width: 90%;
  height: 100%;
  color: #2e2e2e;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.top-bar-sp {
  display: flex;
  flex-wrap: nowrap;
  padding-top: 10px;
}

.top-bar-sp .header-title {
  flex: 1;
}

.custom-select {
  font-size: 18px !important;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
}

.optional-field {
  color: #2DC56E !important;
}

.structure-well {
  margin-bottom: 22px;
  position: relative;
  top: -50px;
  margin-top: 8px;
}

.container-m-well {
  margin-top: 100px !important;
}

.s-project-networks {
  margin-top: 50px;
}

/* ===== Invitation card ===== */
@-webkit-keyframes float {
  0% {
    transform: translatey(0px);
    transform: translatex(0px);
  }

  50% {
    transform: translatey(-30px);
    transform: translatex(20px);
  }

  100% {
    transform: translatey(0px);
    transform: translatex(0px);
  }
}

@-moz-keyframes float {
  0% {
    transform: translatey(0px);
    transform: translatex(0px);
  }

  50% {
    transform: translatey(-30px);
    transform: translatex(20px);
  }

  100% {
    transform: translatey(0px);
    transform: translatex(0px);
  }
}

@-o-keyframes float {
  0% {
    transform: translatey(0px);
    transform: translatex(0px);
  }

  50% {
    transform: translatey(-30px);
    transform: translatex(20px);
  }

  100% {
    transform: translatey(0px);
    transform: translatex(0px);
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
    transform: translatex(0px);
  }

  50% {
    transform: translatey(-30px);
    transform: translatex(20px);
  }

  100% {
    transform: translatey(0px);
    transform: translatex(0px);
  }
}

/* RESET
========================================== */
*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


/* BUTTON
========================================== */
.button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.button button {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: inherit;
  background-color: #ffffff;
  border: 0;
  padding: 15px 25px;
  color: #000000;
  text-transform: uppercase;
  font-size: 21px;
  letter-spacing: 1px;
  width: 200px;
  overflow: hidden;
  outline: 0;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  visibility: visible;
  opacity: 1;
  font-weight: bold;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.6);
}

.button button:hover {
  cursor: pointer;
  background-color: #8e6ac1;
  color: #ffffff;
}

.button button span {
  opacity: 1;
}

.button.clicked button {
  visibility: hidden;
  opacity: 0;
}

/* POP-UP
========================================== */
.pop-up {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.4);
  z-index: 10;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .pop-up {
    height: auto;
    max-width: 900px;
  }
}

.pop-up .content {
  width: 100%;
  max-width: 900px;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
}

@media (min-width: 768px) {
  .pop-up .content {
    min-height: inherit;
  }
}

.pop-up .content .container {
  padding: 100px 20px 140px;
}

@media (min-width: 568px) {
  .pop-up .content .container {
    padding: 50px 20px 80px;
  }
}

@media (min-width: 768px) {
  .pop-up .content .container {
    padding: 70px 0px 90px;
    max-width: 520px;
    margin: 0 auto;
  }
}

.pop-up .content .close {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
  color: #3e4146;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.pop-up .content .close:hover {
  cursor: pointer;
  color: #f66867;
}

.pop-up .content .dots .dot {
  position: absolute;
  border-radius: 100%;
  z-index: 11;
}

.pop-up .content .dots .dot:nth-of-type(1) {
  top: -80px;
  right: -80px;
  width: 160px;
  height: 160px;
  background-color: #689bf6;
  -webkit-animation: float 6s ease-in-out infinite;
  -moz-animation: float 6s ease-in-out infinite;
  -o-animation: float 6s ease-in-out infinite;
  animation: float 6s ease-in-out infinite;
}

@media (min-width: 768px) {
  .pop-up .content .dots .dot:nth-of-type(1) {
    top: -190px;
    right: -190px;
    width: 330px;
    height: 380px;
  }
}

.pop-up .content .dots .dot:nth-of-type(2) {
  bottom: -120px;
  left: -120px;
  width: 240px;
  height: 240px;
  background-color: #f66867;
  -webkit-animation: float 8s ease-in-out infinite;
  -moz-animation: float 8s ease-in-out infinite;
  -o-animation: float 8s ease-in-out infinite;
  animation: float 8s ease-in-out infinite;
}

.pop-up .content .dots .dot:nth-of-type(3) {
  bottom: -50px;
  right: -50px;
  width: 100px;
  height: 100px;
  background-color: #ffd84c;
  -webkit-animation: float 4s ease-in-out infinite;
  -moz-animation: float 4s ease-in-out infinite;
  -o-animation: float 4s ease-in-out infinite;
  animation: float 4s ease-in-out infinite;
}

.pop-up .content .title h1 {
  text-align: center;
  color: #f66867;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 2.8rem;
  letter-spacing: 0.05rem;
}

.pop-up .content img {
  width: 100%;
  display: inline-block;
}

@media (min-width: 768px) {
  .pop-up .content img {
    max-width: 300px;
  }
}

.pop-up .content .subscribe h1 {
  font-size: 1.5rem;
  color: #3e4146;
  line-height: 130%;
  letter-spacing: 0.07rem;
  margin-bottom: 30px;
}

.pop-up .content .subscribe h1 span {
  color: #f66867;
}

.pop-up .content .subscribe form {
  overflow: hidden;
}

.pop-up .content .subscribe form input {
  width: 100%;
  float: left;
  padding: 15px 20px;
  text-align: center;
  font-family: inherit;
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
  outline: 0;
}

.pop-up .content .subscribe form input[type=email] {
  margin-bottom: 15px;
  border: 1px solid #bec1c5;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.subscribe label {
  font-size: 22px;
  font-weight: 800;
  color: #212529;
  font-family: "Open Sans Condensed", sans-serif !important;
}

.open-coording-button-desk {
  display: inline;
}

.open-coording-button-mobile {
  display: none !important;
}

/* ===== Invitation card end ===== */

/******************************/

/*=====MEDIA QUERIES=====*/

@media only screen and (max-width: 770px) {
  .statistic-duration-container h4, .statistic-duration-container h3 {
    font-size: 24px !important;
  }

  .statistic-icon-container h3 {
    font-size: 28px !important;
  }

  .s-project-summary-details {
    width: 60%;
  }

  .s-project-summary-feed {
    width: 40%;
  }

  .s-project-ipm-card-details-title {
    margin-left: 3px !important;
  }

  .s-project-ipm-card-icon img {
    position: relative;
    top: 13px;
    width: 30px !important;
  }
}


/* ==== Responsiveness ==== */
@media (min-width: 600px) {
  .container-m-well {
    margin-top: unset !important;
  }

  .description-name-container h1 {
    color: #000 !important;
    font-family: "Roboto", sans-serif !important;
    position: relative !important;
    top: 0px !important;
    font-weight: 300 !important;
    text-transform: uppercase !important;
    margin-bottom: 20px !important;
    text-align: left !important;
    font-size: 28px;
  }

  .description-name-container .underline {
    position: relative;
    top: -13px;
  }

  .swot-main-container {
    margin-bottom: 100px !important;
  }

  .statistic-duration-container {
    width: 25%;
  }

  .project-upvote-button {
    left: 55%;
  }

  .assumption-create-btn {
    position: absolute;
    right: 20px;
    margin-top: -60px;
  }
}

@media (max-width: 600px) {
  .s-project-nav {
    display: none;
  }

  .statistic-duration-container {
    position: relative;
    top: 550px;
  }


  .project-invitation-button {
    position: absolute;
    margin-top: -135px;
    height: 38px !important;
    width: 200px !important;
    left: 8px;
  }

  .project-upvote-button {
    position: absolute;
    margin-top: -90px;
    height: 38px !important;
    width: 160px !important;
    left: 8px;
  }

  .project-header {
    position: absolute;
    top: -118px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    left: 8px;
    font-size: 24px !important;
  }

  .statistics-card {
    top: 0px;
  }

  .project-modify-button {
    height: 38px !important;
    width: 160px !important;
    right: 4px;
    top: -195px;
    position: absolute;
  }

  .s-project-summary {
    margin-bottom: 100px;
  }

  .statistic-duration-container {
    width: 98%;
    margin-bottom: 200px;
    padding-bottom: 200px;
  }

  .s-project-description {
    margin-top: 30px;
    font-family: "Roboto", sans-serif;
  }

  .s-project-description p {
    font-size: 17px;
  }

  .profile-core-values {
    position: absolute;
  }

  .profile-core-values .s-project-description {
    font-family: "Roboto", sans-serif;
  }

  .description-name-container h1 {
    color: #000 !important;
    font-family: "Roboto", sans-serif !important;
    position: relative !important;
    top: 0px !important;
    font-weight: 300 !important;
    text-transform: uppercase !important;
    margin-bottom: 20px !important;
    text-align: left !important;
  }

  .description-name-container .underline {
    position: relative;
    top: -13px;
  }


  .profile-core-values .add-btn {
    top: 26px;
    position: relative;
    right: 0%;
  }

  .assumption-create-btn {
    margin-top: -39px !important;
  }

  .profile-core-values .add-button-need {
    position: absolute;
    right: 10px !important;
  }

  .goal-title {
    margin-top: -20px;
    padding-left: unset !important;
  }

  .add-discussion-btn-j {
    position: relative !important;
    top: unset !important;
  }

  .top-bar-sp {
    display: unset !important;
  }

  .project-in-project-btn {
    text-align: center;
  }

  .sbp-header {
    margin-top: 20px !important;
  }
}

.project-edit-button {
  display: inline !important;
}


/* ========= MOBILE DESIGN ===== */
@media (max-width: 801px) {
  .main-body-area-single-project {
    display: none !important;
  }

  .main-mobile-body-single-project-area {
    display: inline;
    width: 100% !important;
  }

  .project-static-area-space {
    width: 100% !important;
  }

  .project-edit-button {
    display: none !important;
  }

  .project-description-container-ss {
    margin-bottom: 20px;
  }

  .container-m-well {
    margin-top: -8px !important;
  }

  .s-project-summary-feed {
    width: 100% !important;
  }

  .s-project-summary-details {
    width: 100% !important;
  }

  .s-project-summary {
    display: block !important;
  }

  .s-project-summary-details {
    margin: 0px !important;
  }

  .add_item_button {
    margin-top: 10px !important;
  }

  .project-quick-links-container {
    margin-bottom: 70px !important;
    margin-top: 15px !important;
  }

  .assumption-card-cont {
    margin-right: unset !important;
    width: 100% !important;
  }

  .assumption-container {
    margin-left: unset !important;
  }

  .goal-coording-operator {
    display: unset !important;
  }

  .project-goal-display {
    display: contents !important;
  }

  .open-coording-button-desk {
    display: none !important;
  }

  .open-coording-button-mobile {
    display: inline !important;
  }

  .button_group_goal {
    display: flex !important;
    justify-content: space-around;
    margin-left: -50px;
  }

  .down-btn-goal {
    margin-top: 8px !important;
  }

  #goalAccordian .card p {
    line-height: 40px !important;
  }

  .button_group {
    position: relative !important;
    display: flex !important;
    justify-content: space-around;
    margin-top: 10px !important;
  }

  .timeline-kpi-goal-project {
    margin-left: -50px !important;
  }

  .timeline-content-kpi-goal {
    display: flex !important;
    flex-flow: wrap-reverse !important;
  }

  .edit-kpi {
    margin: -5px !important;
    display: contents !important;

  }

  .no-kpi-added-yet {
    margin-left: 30px;
  }

  /* <===== SWOT AND BMC ====> */
  .swot-info-container {
    margin-top: 10px !important;
    margin-bottom: 40px !important;
  }

  .list-card {
    display: flow !important;
  }
}

.main-mobile-slider-area {
  display: inline;
  position: relative;
  top: -15px;
}

.main-body-area-single-project-caurosel {
  display: none !important;
}

@media (min-width: 801px) {
  .main-mobile-body-single-project-area {
    display: none !important;
  }

  .main-mobile-slider-area {
    display: none !important;
  }

  .main-body-area-single-project-caurosel {
    display: inline !important;
  }

  .mobile-collapse-button {
    display: none !important;
  }

}