.side_bar_container {
    overflow-y: scroll;
    min-height: 100vh;
    height: 100vh;

}

.sidebar_top_bar {
    width: 100%;
    min-height: 150px;
    background-color: #eee;
}

.close_icon {
    font-weight: 800;
    position: relative;
    top: 32px;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
}

.active_tab {
    background-color: purple;
    color: #fff;
}

.side_bar_divider {
    background-color: white;
    color: white;
}

.project_creator {
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.avatar_project_creator {
    align-items: center;
    left: 40%;
}

.project_creator h6 {
    text-transform: uppercase;
    font-weight: 800;
}

.project_creator span {
    text-transform: uppercase;
    font-weight: 800;
    color: purple;
}

.creator_avator {
    display: flex;
    justify-content: center;
}